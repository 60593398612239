import { ref } from 'vue';
import { getDistanceHelper } from './get_distance_helper.js';
import { leafletCheckInGeofencies } from './leaflet_check_in_geofencies.js';

export const moreDetailViolationsObject = ref({
  objConf: {},
  queries: {},
  selectedObjId: '',
  openModalHandler: () => {},
  objectsDetail: {},
});

export let leafletMain = {
  map: '',
  geofencesGroup: {},
  pmCreateCb: null,
  pmEditCb: null,
  pmCutCb: null,
  pmRemoveCb: null,
  pmControlState: null,
  pmEditListeners: null,
  initializated: false,

  initialization: function (mapid = 'mapid') {
    L.PolylineDecorator.include(L.Mixin.Events);

    const shema = this.getTileObject('mapbox/streets-v11');
    const satellite = this.getTileObject('mapbox/satellite-streets-v9');
    const osm = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution: '',

        maxZoom: 25,
      },
    );

    const baseMaps = {
      '<span style="cursor:pointer">Схема Mapbox</span>': shema,
      '<span style="cursor:pointer">Схема OpenStreet</span>': osm,
      '<span style="cursor:pointer">Спутник Mapbox</span>': satellite,
    };

    const map = L.map(mapid, {
      layers: [shema],
      attributionControl: false,
    }).setView([61.262, 73.377], 13);

    this.map = map;

    L.control.layers(baseMaps).addTo(map);

    L.control
      .scale({
        maxWidth: 240,
        metric: true,
        imperial: false,
        position: 'bottomleft',
      })
      .addTo(map);
    map.addControl(
      new L.Control.LinearMeasurement({
        unitSystem: 'metric',
        color: '#FF0080',
        type: 'line',
      }),
    );

    map.pm.setLang('ru');

    this.addCoordinatePopup();
    this.addMoreDetailViolationsButtonListener();
    this.globalRgb = this.globalRgbDefault();
    this.globalRgbBegin = this.globalRgbDefault();

    this.geofencesGroup = L.featureGroup().addTo(map);

    this.pmControlState = {
      drawMarker: false,
      drawPolyline: false,
      drawCircleMarker: false,
      drawRectangle: false,
      drawPolygon: false,
      drawCircle: false,
      editMode: false,
      dragMode: false,
      cutPolygon: false,
      removalMode: false,
      rotateMode: false,
    };
    this.map.pm.disableDraw();
    this.map.pm.disableGlobalEditMode();
    this.pmControlStateApply();
    this.map.pm.removeControls();
    this.pmEditListeners = new Map();

    this.tracksLayerGroup = L.layerGroup({ pmIgnore: true });
    this.violationsLayerGroup = L.layerGroup();
    this.tracksLayerGroup.addTo(this.map);
    this.violationsLayerGroup.addTo(this.map);

    leafletCheckInGeofencies.initialization({
      map,
      featureGroup: this.geofencesGroup,
    });

    window.leafletMain = this;
    window.dispatchEvent(new Event('leaflet:initializated'));
    this.initializated = true;
    this.map.pm.disableGlobalEditMode();
  },

  checkLayerInGeofence({ layer, lat, lng } = {}) {
    if (lat && lng && layer) {
      return leafletCheckInGeofencies.checkLayerInGeofence({ lat, lng }, layer);
    }
    return false;
  },
  checkLayerInGeofenceByLayerData({ layerData, lat, lng } = {}) {
    if (lat && lng && layerData) {
      return leafletCheckInGeofencies.checkLayerDataInGeofence(
        { lat, lng },
        layerData,
      );
    }
    return false;
  },

  checkPointInCircle({
    circleLat,
    cercleLng,
    cercleRadius,
    pointLat,
    pointLng,
  } = {}) {
    if (circleLat && cercleLng && cercleRadius && pointLat && pointLng) {
      return leafletCheckInGeofencies.checkCircleByCoords({
        circleLat,
        cercleLng,
        cercleRadius,
        pointLat,
        pointLng,
      });
    }
    return false;
  },

  getPointDistanceToGeo(point, layerData) {
    const { lat, lng } = point;
    const { _mRadius: radius = null, coords } =
      this.getLatLngsByLayerData(layerData);
    if (radius === null) {
      let distMin = null;
      const [latLngs] = coords; // только внешние границы
      if (!latLngs) {
        return null;
      }
      latLngs.forEach(({ lat: lat2, lng: lng2 }) => {
        // const {lat:lat2, lng:lng2} = latLng;
        const distToVertex = leafletCheckInGeofencies.getDistance({
          lat,
          lng,
          lat2,
          lng2,
        });
        if (distMin === null || distMin > distToVertex) {
          distMin = distToVertex;
        }
      });
      return distMin;
    } else {
      const { lat: lat2, lng: lng2 } = coords;
      const distToCircle =
        leafletCheckInGeofencies.getDistance({ lat, lng, lat2, lng2 }) + radius;
      return distToCircle;
    }
  },

  addPmEventListeners({ createCb, editCb, removeCb, cutCb }) {
    // вызывается из vue
    this.pmCreateCb = createCb;
    this.pmEditCb = editCb;
    this.pmRemoveCb = removeCb;
    this.pmCutCb = cutCb;

    this.map.on('pm:create', this.pmCreateListenEvent);
    this.geofencesGroup.on('pm:edit', this.pmEditListenEvent);
    this.geofencesGroup.on('pm:cut', this.pmCutListenEvent);
    this.geofencesGroup.on('pm:remove', this.pmRemoveListenEvent);
  },

  removePmEventListeners() {
    this.map.off('pm:create', this.pmCreateListenEvent);
    this.geofencesGroup.off('pm:edit', this.pmEditListenEvent);
    this.geofencesGroup.off('pm:remove', this.pmRemoveListenEvent);
    this.geofencesGroup.off('pm:cut', this.pmCutListenEvent);
    this.pmCreateCb = null;
    this.pmEditCb = null;
    this.pmRemoveCb = null;
  },

  addPmEditStyle(layer, flag) {
    if (!layer) {
      return;
    }
    const isIgnore = !flag;

    if (isIgnore) {
      layer.pm.disable();
      layer.pm.disableLayerDrag();
    }

    layer.setStyle({ pmIgnore: isIgnore });
  },

  pmEditListenEvent(e) {
    leafletMain.pmEditListenThisEvent(e);
  },

  pmCutListenEvent(e) {
    leafletMain.pmCutListenThisEvent(e);
  },

  pmRemoveListenEvent(e) {
    // leafletMain.pmRemoveListenThisEvent(e);

    const { _leaflet_id } = e.layer;
    leafletMain.pmRemoveCb(_leaflet_id);
  },

  pmEditListenThisEvent(e) {
    const { layer, shape } = e;
    this.roundCircleRadius(layer);
    const { _leaflet_id } = layer;
    // const {_mRadius:newRadius = 0} = layer;
    const newRadius = layer.getRadius ? layer.getRadius() : null;
    this.pmEditCb({ _leaflet_id, layer, shape, newRadius });

    // if (geofence) {
    // if (shape === 'Circle') {
    //   geoJson = this.getGeoJson(geofence.layer, shape, newRadius);
    // }

    // geofence.layer = layer;
    // geofence.geoJson = this.getGeoJson(layer);

    // }
    // layer.removeEventListener('pm:edit', {handleEvent: this.pmEditListenEvent});
    // , geofence
  },

  pmCutListenThisEvent(e) {
    const { originalLayer, layer } = e;
    const { _leaflet_id } = layer;
    const { _leaflet_id: _leaflet_id_original } = originalLayer;
    const shape = layer.feature.geometry.type;
    if (shape.toLowerCase() === 'multipolygon') {
      // отменим изменение т.к. мультиполигоны запрещены
      layer.setLatLngs(originalLayer.getLatLngs());
    }
    this.geofencesGroup.removeLayer(originalLayer);
    originalLayer.remove();
    this.geofencesGroup.addLayer(layer);

    this.pmCutCb({
      _leaflet_id,
      _leaflet_id_original,
      layer,
      shape: layer.feature.geometry.type,
    });
  },

  exchangeLatLngsFromGeoJson(layer, geoJson) {
    const tempLayer = this.geometryToLayer(geoJson);
    layer.setLatLngs(tempLayer.getLatLngs());
    layer.remove();
    layer.addTo(this.map);
  },

  removeGeofence(layer) {
    if (!layer) {
      this.map.off('pm:create', this.pmCreateListenEvent);
      this.pmControlDrawSet(false);
    } else {
      layer.remove();
      this.geofencesGroup.removeLayer(layer);
    }
  },

  showLayer(layer, isVisible) {
    if (!layer) {
      return;
    }
    if (isVisible) {
      this.geofencesGroup.addLayer(layer);
    } else {
      this.geofencesGroup.removeLayer(layer);
    }
  },

  reInitLayer(layer) {
    layer.remove();
    layer.addTo(this.map);
  },

  pmCreateListenThisEvent(e) {
    const pmLayer = e.layer;

    this.roundCircleRadius(pmLayer);

    const geoJson = this.getGeoJson(pmLayer);
    // this.pmControlDrawSet(false);

    const layer = this.geometryToLayer(geoJson);
    layer.setStyle({ pmIgnore: true });
    this.geofencesGroup.addLayer(layer);

    pmLayer.remove();

    if (this.checkSelfIntersection(layer)) {
      layer.remove();
      return;
    }

    this.pmCreateCb(geoJson, layer);
  },

  roundCircleRadius(layer) {
    if (layer.getRadius) {
      layer.setRadius(Math.round(layer.getRadius()));
    }
  },

  checkSelfIntersection(layer) {
    // самопересечения недопустимы
    return layer.pm.hasSelfIntersection && layer.pm.hasSelfIntersection();
  },

  pmControlDrawSet(flag) {
    this.pmControlState['drawPolygon'] = flag;
    this.pmControlState['drawCircle'] = flag;

    if (flag) {
      this.pmControlState.editMode = false;
    }

    this.pmControlStateApply();
  },

  pmControlEditSet(flag) {
    this.pmControlState.editMode = flag;
    this.pmControlState.dragMode = flag;
    this.pmControlState.cutPolygon = flag;
    this.pmControlState.removalMode = flag;
    this.pmControlState.rotateMode = flag;
    this.pmControlStateApply();
  },

  pmCreateListenEvent(e) {
    leafletMain.pmCreateListenThisEvent(e);
  },

  createLayerFromGeoJson(geoJson) {
    return L.geoJson(geoJson, {
      pointToLayer: (feature, latlng) => {
        if (feature.geometry.type === 'Point') {
          const radius = this.getCircleRadius(feature);
          if (radius) {
            return new L.Circle(latlng, radius);
          }
          return new L.Marker(latlng);
        }
      },
      style: function (feature) {
        return {
          // color: feature.properties.color,
          pmIgnore: true,
        };
      },
      // onEachFeature: (feature, layer) => {
      //   const { type } = feature.geometry;
      //   if (type === "Polygon" || type === "MultiPolygon") {
      //     poligons.push({ feature, layer });
      //     return;
      //   }
      //   if (type === "Point" && getCircleRadius(feature)) {
      //     circles.push({ feature, layer });
      //   }
      // },
    });
  },

  geometryToLayer(geoJson) {
    return L.GeoJSON.geometryToLayer(geoJson, {
      pointToLayer: (feature, latlng) => {
        if (feature.geometry.type === 'Point') {
          const radius = this.getCircleRadius(feature);
          if (radius) {
            return new L.Circle(latlng, radius);
          }
          return new L.Marker(latlng);
        }
      },
    });
  },

  getCircleRadius(feature) {
    if (feature.geometry.type === 'Point') {
      const { radius = 0 } = feature.properties ?? {};
      return radius ? parseFloat(radius) : radius;
    }
    return 0;
  },

  pmControlStateApply() {
    let drawModeOff = true;
    let editModeOff = true;

    for (const btnName in this.pmControlState) {
      const flag = this.pmControlState[btnName];
      leafletMain.map.pm.Toolbar.setButtonDisabled(btnName, !flag);
      if (flag && btnName.includes('draw')) {
        drawModeOff = false;
      }
      if (
        flag &&
        btnName.includes('edit', 'drag', 'cut', 'removal', 'rotate')
      ) {
        editModeOff = false;
      }
    }

    if (drawModeOff) {
      this.map.pm.disableDraw();
    } else {
      // запрет на стирание фигуры, если уже есть разреение на добавление новой фигуры для геозоны
      leafletMain.map.pm.Toolbar.setButtonDisabled('removalMode', true);
    }

    if (editModeOff) {
      this.map.pm.disableGlobalEditMode();
    }
  },

  layerMapFitBounds(layer) {
    if (!layer) {
      return;
    }
    this.map.fitBounds(layer.getBounds());
  },

  addGeofencesControls() {
    if (this.map.pm.controlsVisible()) {
      return;
    }

    this.map.pm.addControls({
      position: 'topleft',
      drawMarker: false,
      drawPolyline: false,
      drawCircleMarker: false,
      drawRectangle: false,
      drawPolygon: true,
      drawCircle: true,
      editMode: true,
      dragMode: true,
      cutPolygon: true,
      removalMode: true,
      rotateMode: true,
      oneBlock: true,
      drawControls: true,
      editControls: true,
      customControls: true,
    });
  },

  removeGeofencesControls() {
    this.map.pm.removeControls();
    this.map.pm.disableDraw();
    this.map.pm.disableGlobalEditMode();
  },

  // createdDrawEvent(e) {

  // },

  generateGeoJson() {
    // var fg = L.featureGroup();
    var layers = this.findLayers(this.map);

    var geo = {
      type: 'FeatureCollection',
      features: [],
    };
    layers.forEach(function (layer) {
      const geoJson = this.getGeoJson(layer);
      geo.features.push(geoJson);
    });

    alert(JSON.stringify(geo));
  },

  getLatLngs(layer) {
    if (!layer) {
      return {};
    }

    // getRadius
    const { _mRadius } = layer;

    if (_mRadius) {
      return {
        _mRadius,
        coords: layer.getLatLng(),
      };
    }

    return {
      coords: layer.getLatLngs(),
    };
  },
  getLatLngsByLayerData(layerData) {
    if (!layerData) {
      return {};
    }

    // getRadius
    const { _mRadius } = layerData;

    if (_mRadius) {
      return {
        _mRadius,
        coords: layerData.latLngs,
      };
    }

    return {
      coords: layerData.latLngs,
    };
  },

  // setLatLngs(layer, {coords, _mRadius = false} = {}) {
  //   if (!layer) {
  //     return;
  //   }

  //   if (_mRadius === false) {
  //     layer.setLatLngs(coords);
  //   } else {
  //     layer.setRadius(_mRadius);
  //     layer.setLatLng(coords);
  //   }
  // },

  replaceLayerFromGeojson(layer, geoJson) {
    if (layer) {
      this.geofencesGroup.removeLayer(layer);
      layer.remove();
    }

    if (!geoJson) {
      return null;
    }

    const groupLayers = this.createLayerFromGeoJson(geoJson);
    const [newLayer] = groupLayers.getLayers();

    this.geofencesGroup.addLayer(newLayer);
    return newLayer;
  },

  getGeoJson(layer, type) {
    if (!layer) {
      return false;
    }

    var geoJson = JSON.parse(JSON.stringify(layer.toGeoJSON()));
    if (!geoJson.properties) {
      geoJson.properties = {};
    }

    geoJson.properties = JSON.parse(JSON.stringify(layer.options));

    const radius = layer.getRadius ? layer.getRadius() : false; //layer.options.radius;
    if (radius !== false) {
      geoJson.properties.radius = parseFloat(radius);
    }

    if (type) {
      geoJson.properties.type = type.toLowerCase();
    } else if (layer instanceof L.Rectangle) {
      geoJson.properties.type = 'rectangle';
    } else if (layer instanceof L.Circle) {
      geoJson.properties.type = 'circle';
    } else if (layer instanceof L.CircleMarker) {
      geoJson.properties.type = 'circlemarker';
    } else if (layer instanceof L.Polygon) {
      geoJson.properties.type = 'polygon';
    } else if (layer instanceof L.Polyline) {
      geoJson.properties.type = 'polyline';
    } else if (layer instanceof L.Marker) {
      geoJson.properties.type = 'marker';
    }

    return geoJson;
  },

  findLayers(map) {
    var layers = [];
    map.eachLayer((layer) => {
      if (
        layer instanceof L.Polyline ||
        layer instanceof L.Marker ||
        layer instanceof L.Circle ||
        layer instanceof L.CircleMarker
      ) {
        layers.push(layer);
      }
    });

    // filter out layers that don't have the leaflet-geoman instance
    layers = layers.filter((layer) => !!layer.pm);

    // filter out everything that's leaflet-geoman specific temporary stuff
    layers = layers.filter((layer) => !layer._pmTempLayer);

    return layers;
  },

  importGeo() {
    var prom = prompt();
    if (prom) {
      this.importGeoJSON(JSON.parse(prom));
    }
  },

  importGeoJSON(feature) {
    var geoLayer = L.geoJSON(feature, {
      style: function (feature) {
        return feature.properties.options;
      },
      pointToLayer: function (feature, latlng) {
        switch (feature.properties.type) {
          case 'marker':
            return new L.Marker(latlng);
          case 'circle':
            return new L.Circle(latlng, feature.properties.options);
          case 'circlemarker':
            return new L.CircleMarker(latlng, feature.properties.options);
        }
      },
    });

    geoLayer.getLayers().forEach((layer) => {
      if (layer._latlng) {
        var latlng = layer.getLatLng();
      } else {
        var latlng = layer.getLatLngs();
      }
      switch (layer.feature.properties.type) {
        case 'rectangle':
          new L.Rectangle(latlng, layer.options).addTo(map);
          break;
        case 'circle':
          new L.Circle(latlng, layer.options).addTo(map);
          break;
        case 'polygon':
          new L.Polygon(latlng, layer.options).addTo(map);
          break;
        case 'polyline':
          new L.Polyline(latlng, layer.options).addTo(map);
          break;
        case 'marker':
          new L.Marker(latlng, layer.options).addTo(map);
          break;
        case 'circlemarker':
          new L.CircleMarker(latlng, layer.options).addTo(map);
          break;
      }
    });
  },

  getTileObject: function (id) {
    return L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution: '',
        // 'Map data &copy; <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/" target="_blank">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/" target="_blank">Mapbox</a>',
        tileSize: 512,
        maxZoom: 18,
        zoomOffset: -1,
        id,
        accessToken:
          'pk.eyJ1IjoiaXVyaWktNTA1IiwiYSI6ImNqemlldHRqbzA4NjYzbW84bTQ0OXMzNXkifQ.tPUaHbgVZ6vCSpuxk3IUJA',
      },
    );
  },

  addCoordinatePopup() {
    const popup = L.popup();

    this.map.on('dblclick', (e) => {
      const { lat, lng } = e.latlng;
      const { latDMS, lonDMS } = convertdeg(lat, lng);

      popup
        .setLatLng(e.latlng)
        .setContent(
          `
          Широта: ${latDMS}<br>
          Долгота: ${lonDMS}
        `,
        )
        .openOn(this.map);
    });
  },

  addMoreDetailViolationsButtonListener() {
    this.map.on('popupopen', function (e) {
      const buttonNode = e.popup?._source?._popup?._contentNode.querySelector(
        '.js-more-detail-violations-button',
      );

      if (buttonNode) {
        buttonNode.addEventListener(
          'click',
          moreDetailViolationsObject.value.openModalHandler,
        );
      }
    });
  },

  globalPositions: {},
  globalPolylines: {},
  globalMarkers: {},
  decorator: {},
  globalViolations: {},

  objectsLastPositions: {},
  globalRgb: {},
  globalRgbBegin: {},

  globalRgbDefault: function () {
    return {
      r: 65,
      g: 105,
      b: 255,
    };
  },

  colorHexShift: function (rgbColor) {
    rgbColor.b += 120;
    if (rgbColor.b > 255) {
      let overflow = this.globalRgb.b - 255;
      rgbColor.b -= 255;
      rgbColor.g += overflow;
    }
    if (rgbColor.g > 255) {
      let overflow = rgbColor.g - 255;
      rgbColor.g -= 255;
      rgbColor.r += overflow;
    }
    if (rgbColor.r > 255) {
      rgbColor.r -= 255;
    }
    return rgbColor;
  },

  leafletClearPositions: function (objectId, objectName, globalObjects) {
    if (!globalObjects.globalPositions[objectId]) {
      return true;
    }
    // if (!confirm("Удалить трек по объекту " + objectName + "?")) {
    //   return false;
    // }

    this.deleteMarkersDrainOrRefueling(objectId);

    this.clearLayers(objectId, globalObjects);
    //     // clear on map
    // if(objectId in this.globalMarkers) this.globalMarkers[objectId].clearLayers();
    // if(objectId in this.globalPolylines) this.map.removeLayer(this.globalPolylines[objectId]);
    // if(objectId in this.decorator) this.map.removeLayer(this.decorator[objectId]);
    // if(objectId in this.globalViolations) this.globalViolations[objectId].clearLayers(this.globalViolations[objectId]);

    //     // clear in global array
    // if(this.globalPositions[objectId]) {
    //     delete this.globalPositions[objectId];
    // }
    return true;
  },

  clearLayers: function (objectId, globalObjects) {
    // clear on map
    if (objectId in this.globalMarkers)
      this.globalMarkers[objectId].clearLayers();
    if (objectId in this.globalPolylines)
      this.map.removeLayer(this.globalPolylines[objectId]);
    if (objectId in this.decorator)
      this.map.removeLayer(this.decorator[objectId]);
    if (objectId in this.globalViolations)
      this.globalViolations[objectId].clearLayers(
        this.globalViolations[objectId],
      );

    // clear in global array
    if (objectId in globalObjects.globalPositions) {
      delete globalObjects.globalPositions[objectId];
    }
  },

  mapFitBounds: function (objId) {
    if (!(objId in this.globalPolylines)) return;
    this.map.fitBounds(this.globalPolylines[objId].getBounds());
  },

  leafletAddPolyline: function (points, lineText, objectId, globalObjects, funcUpdateElemState = false) {
    this.clearLayers(objectId, globalObjects);

    let polylineColor =
      'rgb(' +
      this.globalRgb.r +
      ',' +
      this.globalRgb.g +
      ',' +
      this.globalRgb.b +
      ')';

    if (!('latlngs' in points) || points['latlngs'].length == 0) return;

    let latlngs = [];

    const latLngsInterval = 20;

    let prevLatLngs = [];

    for (let i = 0; i < points.params.length; i++) {
      if (i === 0 || i === points.params.length - 1) {
        latlngs.push(points['latlngs'][i]);
        prevLatLngs = points['latlngs'][i];
        continue;
      }

      if (points.params[i].violation_id) {
        latlngs.push(points['latlngs'][i]);
        prevLatLngs = points['latlngs'][i];
        continue;
      }

      if (i in points.consumptions_stat) {
        latlngs.push(points['latlngs'][i]);
        prevLatLngs = points['latlngs'][i];
        continue;
      }

      const positionDifferenceInKM = getDistanceHelper(
        points['latlngs'][i][0],
        points['latlngs'][i][1],
        prevLatLngs[0],
        prevLatLngs[1],
      );

      const positionDifference = positionDifferenceInKM * 1000;

      if (
        positionDifference > latLngsInterval ||
        positionDifference < -latLngsInterval
      ) {
        latlngs.push(points['latlngs'][i]);
        prevLatLngs = points['latlngs'][i];
      }
    }
    this.globalPolylines[objectId] = this.addPolyline({
      latlngs,
      options: {
        color: polylineColor,
      },
      text: lineText,
    });
    if (funcUpdateElemState) {
      funcUpdateElemState(objectId, {borderBottom: '3px solid ' + polylineColor})
    } else {
      document.getElementById('get-pos-' + objectId).style.borderBottom =
      '3px solid ' + polylineColor;
    }
    this.globalRgb = this.colorHexShift(this.globalRgb);

    // this.map.fitBounds(this.globalPolylines[objectId].getBounds());

    // this.map.invalidateSize();

    this.decorator[objectId] = L.polylineDecorator(
      this.globalPolylines[objectId],
      {
        pmIgnore: true,
        patterns: [
          {
            offset: '30px',
            repeat: 250,
            symbol: L.Symbol.arrowHead({
              pixelSize: 9,
              polygon: false,
              pathOptions: { stroke: true, color: polylineColor, opacity: 0.7 },
            }),
          },
        ],
      },
    );

    this.decorator[objectId].onAdd = function (map) {
      this._map = map;
      this._draw();
      // original line:
      // this._map.on('moveend', this.redraw, this)
      // new line:
      this._map.on(
        'moveend',
        (event) => {
          this.redraw();
          this.bringToBack();
        },
        this,
      );
    };

    this.decorator[objectId].addTo(this.map);

    this.tracksLayerGroup.addLayer(this.globalPolylines[objectId]);

    this.mapFitBounds(objectId);
  },

  addViolationsOnMap: function (
    points,
    objectId,
    objectName,
    gearboxName,
    violationsSetting,
    isPrevDelete,
    queries,
    objConf,
  ) {
    if (isPrevDelete && objectId in this.globalViolations) {
      // this.globalViolations[objectId].clearLayers(
      //   this.globalViolations[objectId],
      // );
      this.globalViolations[objectId].clearLayers();
      // this.map.removeLayer(this.globalViolations[objectId]);
      this.violationsLayerGroup.removeLayer(this.globalViolations[objectId]);
    }

    let events;
    let latlngs;
    if (!('params' in points) || !('latlngs' in points)) {
      events = [];
      latlngs = [];
    } else {
      events = points['params'];
      latlngs = points['latlngs'];

      if (queries) {
        // for query to get detail violations
        moreDetailViolationsObject.value.queries[objectId] = queries;
        moreDetailViolationsObject.value.objConf[objectId] = objConf;
      }

      moreDetailViolationsObject.value.selectedObjId = objectId;
    }

    const isMarkers = false;
    let iobjAccelLimit = 50;

    // let violationsArr = [];
    let marker;
    // const timeStart = new Date();
    const mCluster = L.markerClusterGroup({
      pmIgnore: true,
      maxClusterRadius: 25,
      disableClusteringAtZoom: 18,
      iconCreateFunction: this.iconCreateFunction,
    });

    for (let i = 0; i < events.length; i++) {
      if (!events[i]['violation_id']) {
        continue;
      }

      const violationIds = events[i]['violation_id'].split(';');
      let founded = false;
      violationIds.forEach((violId) => {
        if (violId in violationsSetting) {
          founded = true;
          return;
        }
      });

      if (!founded) {
        continue; // отключен вывод на карту этих нарушений в настройках пользователем
      }

      if (isMarkers) {
        marker = L.circle(latlngs[i], { pmIgnore: true });
      } else {
        marker = L.circle(latlngs[i], {
          pmIgnore: true,
          radius: 3.5,
          color: '#f00',
          rotationAngle: 45,
        });
      }

      let textShow = `<b>
        ${objectName}
      </b>
        ${gearboxName}
      <br>
      время: ${formatDateHelper(
        new Date(events[i]['viewTime'] * 1000),
        'hh:nn:ss dd.mm.yy',
      )}
      <br>
      скорость(км/ч): ${events[i]['speed'] / 10}
      <br>
      id нарушения: ${events[i]['violation_id']}
      <br>
      описание нарушения: <i>${this.getViolationText(events[i])}</i>
      </br>
      <div  
        class="link-to-popup js-more-detail-violations-button"
        data-params-index="${i}"
        data-obj-id="${objectId}"
      >
        подробнее
      </div>`;
      
      textShow = String(textShow)
        .replace(
          'clutch_time_unine',
          events[i]['violation_values']['clutch_time_unine'],
        )
        .replace(
          'pto_cnt_violation',
          events[i]['violation_values']['pto_cnt_violation'],
        )
        .replace('spd_accel', events[i]['violation_values']['spd_accel'] / 10)
        .replace('iobj_accelLimit', iobjAccelLimit)
        .replace('back_raised_cnt_violation', events[i]['violation_values']['back_raised_cnt_violation']);

      marker.bindPopup(textShow);

      // violationsArr.push(marker);
      mCluster.addLayer(marker);
    }

    // this.globalViolations[objectId] = L.layerGroup(violationsArr).addTo(
    //   this.map,
    // );
    this.globalViolations[objectId] = mCluster;
    // this.map.addLayer(mCluster);
    this.violationsLayerGroup.addLayer(mCluster);
  },

  getViolationText: function (event) {
    const text = event['violation_text'];
    text
    .replace('clutch_time_unine', event['violation_values']['clutch_time_unine'])
    .replace('pto_cnt_violation', event['violation_values']['pto_cnt_violation'])
    .replace('spd_accel', event['violation_values']['spd_accel'])
    .replace('spd', event['violation_values']['spd'])
    .replace('back_raised_cnt_violation', event['violation_values']['back_raised_cnt_violation']);

    return text
  },

  markersDrainOrRefueling: {},
  addDrainsAndRefuelingOnMap: function (points, isPrevDelete) {
    const getDataByStatus = (status) => {
      // Статус уровнемера:
      // 0 - 4 - первые два байта - важность уровнемера
      // 8 - если установлено то это суммарный уровнемер
      // 16 - событие заправка
      // 32 - идет заправка
      // 64 - событие слив
      // 128 - идет слив

      if (status & 16) {
        const icon = this.createIcon({
          iconUrl: '/images/markers/marker-icon-refueling.png',
          shadowUrl: '/images/markers/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12.5, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        });

        const options = {
          icon,
        };

        return { event: 'заправка', options };
      }

      if (status & 64) {
        const icon = this.createIcon({
          iconUrl: '/images/markers/marker-icon-drain.png',
          shadowUrl: '/images/markers/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12.5, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        });

        const options = {
          icon,
        };

        return { event: 'слив', options };
      }
    };

    const { stateNumber, objId, consumptions_stat } = points;

    if (!this.markersDrainOrRefueling[objId]) {
      this.markersDrainOrRefueling[objId] = [];
    }

    this.deleteMarkersDrainOrRefueling(objId);

    if (!isPrevDelete) return;

    for (let key in consumptions_stat) {
      if (key === 'summ') continue;

      const timeStr = formatDateHelper(
        new Date(consumptions_stat[key].time),
        'hh:nn:ss dd.mm.yy',
      );

      const { event, options } = getDataByStatus(consumptions_stat[key].status);

      const text = `
        <span style="font-weight: bold">
          ${stateNumber}
        </span><br>
        Событие: ${event} (${consumptions_stat[key].value}л.),<br>
        Дата: ${timeStr}
      `;

      this.markersDrainOrRefueling[objId].push(
        this.addMaker({ latLon: consumptions_stat[key].latlon, text, options }),
      );
    }
  },

  deleteMarkersDrainOrRefueling(objId) {
    if (!this.markersDrainOrRefueling[objId]) return;

    this.markersDrainOrRefueling[objId].map((marker) => {
      this.dropMarker(marker);
    });

    this.markersDrainOrRefueling[objId].length = 0;
  },

  iconCreateFunction: function (cluster) {
    var childCount = cluster.getChildCount();
    var c = ' marker-cluster-';
    if (childCount < 10) {
      c += 'small';
    } else if (childCount < 100) {
      c += 'medium';
    } else if (childCount < 1000) {
      c += 'large';
    } else {
      c += 'extra-large';
    }

    return new L.DivIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'marker-cluster' + c,
      iconSize: new L.Point(40, 40),
    });
  },

  leafletAddObjPositions: function (objData, isRefresh) {
    //objectsPositions
    const noPosArr = ['отсутствует', 'не загружено']
    if (!objData.lat || !objData.lon || noPosArr.includes(objData.lat) || noPosArr.includes(objData.lon)) {
      return {status: false, reason: 1}
    }
    let latLon = [objData.lat, objData.lon];
    if (objData.dataId in this.objectsLastPositions) {
      // удалить слой на карте
      this.objectsLastPositions[objData.dataId].clearLayers(objData.dataId);
      delete this.objectsLastPositions[objData.dataId];
      if (!isRefresh) {
        return {status: false, reason: 2};
      }
    }

    let rotationAngle = objData['speed'] > 0 ? objData.head : 0;
    let style = 'transform:rotate(-' + rotationAngle + 'deg);';
    if (!(rotationAngle > 0)) {
      style = '';
    }

    let markerSrc =
      objData['speed'] > 0
        ? '/images/arrow-move.png'
        : '/images/arrow-parking.png';
    let marker = new L.Marker(latLon, {
      icon: new L.DivIcon({
        className: 'arrow-map-icon',
        html:
          '<img class="arrow-map-image" src="' +
          markerSrc +
          '"/>' +
          '<span class="arrow-map-span" style="' +
          style +
          '">' +
          objData.name +
          '</span>',
        //   '<style>#' + objData.dataId + '-arrow-map-span' + ':{transform: rotate(' + objData.head + 'deg);} </style>' +
        //   '<style>#' + objData.dataId + '-arrow-map-span' + ':{color: white;} </style>'
      }),
      rotationAngle: rotationAngle,
    });

    // marker = L.circle( latLon, { radius : 2, color : "#f00", rotationAngle: objData.head } );
    let textShow =
      '<b>' +
      objData.name +
      '</b>' +
      ' (г/н ' +
      objData.stateNumber +
      ')<br>' +
      'время: ' +
      formatDateHelper(new Date(objData.lastPosTime), 'hh:nn:ss dd.mm.yy') +
      '<br>скорость(км/ч): ' +
      objData['speed'];
    marker.bindPopup(textShow);
    // marker.bindTooltip("textShow", {
    //     permanent: true,
    //     direction: 'right'
    // });

    this.objectsLastPositions[objData.dataId] = L.layerGroup([marker]).addTo(
      this.map,
    );

    if (!isRefresh) {
      this.map.fitBounds([latLon]);
    }

    return {status: true};
  },

  createIcon(options) {
    return L.icon(options);
  },

  addMaker: function ({ latLon, text, options } = {}) {
    return L.marker(latLon, options)
      .bindPopup(text)
      .addTo(this.map)
      .openPopup();
  },

  moveMarker: function ({ marker, latLon, text } = {}) {
    marker.setLatLng(latLon).setPopupContent(text);
  },

  dropMarker: function (marker) {
    marker.remove();
  },

  addPolyline: function ({ latlngs, options, text } = {}) {
    options.pmIgnore = true;
    return new L.polyline(latlngs, options).addTo(this.map).bindPopup(text);
  },

  setLatLngsPolylyne: function ({ polyline, latlngs, text } = {}) {
    polyline.setLatLngs(latlngs).setPopupContent(text);
  },

  dropPolline: function (polyline) {
    polyline.remove();
  },

  destroy() {
    this.removePmEventListeners();
  },
};

//создание карты
// var map = L.map('mapid').setView([61.262, 73.377], 13);
//получение карты
// L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiaXVyaWktNTA1IiwiYSI6ImNqemlldHRqbzA4NjYzbW84bTQ0OXMzNXkifQ.tPUaHbgVZ6vCSpuxk3IUJA', {
// 	attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/" target="_blank">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/" target="_blank">Mapbox</a>',
// 	maxZoom: 18,
// 	id: 'mapbox.streets',
// 	accessToken: 'your.mapbox.access.token'
// }).addTo(map);

//маркер на карте
//var marker = L.marker([61.262, 73.377]).addTo(mymap);
//marker.bindPopup("<b>Сургут</b><br>ЕНДС-ХМАО").openPopup();

/*function sendPost() {
    if (loc2 != null && loc1 != null) {
        var p1 = loc1.getLatLng(),
        p2 = loc2.getLatLng();
        $.post(
                //куда шлем запрос,
                {l1: p1.lat + ',' + p1.lng, l2: p2.lat + ',' + p2.lng},
        function(data) {
            if (data) {
                if (this.globalPolylines) {
                    map.removeLayer(this.globalPolylines);
                }
                var points = data;
                this.globalPolylines = new L.polyline(points, {color: 'red'});
                map.addLayer(this.globalPolylines);
                map.fitBounds(this.globalPolylines.getBounds());
            }
        },
                "json"
                );
    }
}*/
