import fetchJson from '../../src/dataRequest/fetch-json';
import {
  globalObjects,
  mobileEvents,
  violationsDescriptions,
} from '../../src/dataRequest/mainScript';
import { ViolationsDescriptions } from '../../src/dataRequest/violationsDescriptions';
import { violationsDescriptionListHtml } from '../Monitoring/Map/templates/violationsDescriptionListHtml';
import CalculateRequestDataworker from '@/workers/CalculateRequestData.worker.js';

export function callVendorPositions(
  objId,
  clickElem,
  targetElement,
  timeInterval,
) {
  //const url_get_positions = "http://jsproject.local/post_test.php/";
  const url_get_positions = `${
    process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
  }Api/getPositionsVendorPost`;
  //$("#timeOffsetInput").html( 5 );
  // const send_data   = $('#objectsListForm').serialize();
  let preparing_data = preparingDataFromGetPos(
    objId,
    targetElement,
    true,
    timeInterval,
  );
  if (!preparing_data) return;

  const request = $.ajax({
    url: url_get_positions,
    type: 'POST',
    data: preparing_data,
    dataType: 'html',
    beforeSend: function (jqXHR, settings) {
      // infoShowText_helper('отправляю запрос...');
      infoShowText_helper(targetElement, 'отправляю запрос вендору...');
    },
  });
  request.done(function (response) {
    treatmentResponseVendor(response, targetElement, objId);
    //$("#message").html( response );
  });
  request.fail(function (jqXHR, textStatus) {
    infoShowText_helper(targetElement, 'Request failed: ' + textStatus);
  });
}

const isGo = true;

export function callOurPositionsWithGo(
  objId,
  clickElem,
  targetElement,
  timeInterval,
  isOnMap = false,
  isDistFilter = true,
  skillsManDetailTemplate = {},
  funcUpdateElemState = false
) {
  const host = document.location.host;
  const url_get_positions = `${
    process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
  }Api/getPositionsOurPost`;
  const url_get_positions_go = `${process.env.VUE_APP_BACKEND_GO_URL}api/getPositionsOur`;
  const url_get_positions_other = `${
    process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
  }Api/getPositionsOtherPost`;
  const url_get_positions_py = `//${host}:8082/getpositions`;
  // запрос позиций с нашего сервера

  let preparing_data = preparingDataFromGetPos(
    objId,
    targetElement,
    isOnMap,
    timeInterval,
  );

  if (!preparing_data) return;

  // подготовка для асинохронного запроса
  getCallOurPosotionsPrepareDataSends(preparing_data, isOnMap);
  const jqXHR_res = [];

  for (let i = 0; i < preparing_data['sends'].length; i++) {
    jqXHR_res.push('');
  }

  let timeCheck = {};
  timeCheck.begin = new Date();

  let resCount = 0;
  let piecesPositions = [];
  let piecesPositionsPy = [];

  let sectionReportsDetail = isOnMap ? false : 'section-reports-detail';
  infoShowText_helper(
    targetElement,
    'Отправляю запрос...',
    sectionReportsDetail,
  );

  if (isOnMap) {
    if (funcUpdateElemState) {
      funcUpdateElemState(objId, {background: 'yellow'})
    } else {
      clickElem.style.background = 'yellow';
    }
  }

  // закомментировать эту строку ниже вместе с return, чтобы делать отправку через Питон
  getPicesPositions();
  return;

  let preparing_data_py = [];
  let preparingDataSendsLength = preparing_data.sends.length;
  let PRIMISE_COUNT = 5;

  if (isDistFilter && preparingDataSendsLength > 5) {
    let oneDataLength = Math.ceil(preparingDataSendsLength / PRIMISE_COUNT);

    for (let i = 0; i < PRIMISE_COUNT; i++) {
      preparing_data_py[i] = {
        sends: preparing_data.sends.splice(0, oneDataLength),
      };

      if (!preparing_data.sends.length) {
        break;
      }
    }
  } else {
    preparing_data_py = [preparing_data];
  }

  // ПРОМИСЫ ДЛЯ ОТДЕЛЬНОГО СЕРВИСА
  let resCountPy = 0;
  const promisesPy = !isDistFilter
    ? false
    : preparePromises(url_get_positions_py, preparing_data_py, false);
  Promise.all(promisesPy)
    .then((responses) => {
      piecesPositionsPy = responses;
      ajaxPositionsDoneAll_action_py();
    })
    .catch((error) => {
      console.error("python service doesn't work");
      // console.error(error);
      getPicesPositions();
    });
  // ПРОМИСЫ ДЛЯ ОТДЕЛЬНОГО СЕРВИСА (ВЫШЕ)

  function getPicesPositions() {
    // простой запрос позиций без python сервиса
    if (!preparing_data.sends.length && preparing_data_py.length) {
      // нужно восстановить preparing_data.sends
      let sendIndex = 0;

      for (let ii = 0; ii < preparing_data_py.length; ii++) {
        for (let jj = 0; jj < preparing_data_py[ii].sends.length; jj++) {
          preparing_data.sends[sendIndex] = preparing_data_py[ii].sends[jj];
          sendIndex++;
        }
      }
    }

    const isOther = isGo ? false : isOnMap;
    let promises;
    if (isGo) {
      promises = preparePromises(
        url_get_positions_go,
        preparing_data.sends,
        false,
        isOther,
      );
    } else {
      promises = preparePromises(
        url_get_positions,
        preparing_data.sends,
        false,
        isOther,
      );
    }

    // асинхронный запрос
    Promise.all(promises)
      .then((responses) => {
        piecesPositions = responses;
        ajaxPositionsDoneAll_action();
      })
      .catch((error) => {
        console.error(error);
        ajaxPositionsFailAll();
      });

    function ajaxPositionsFailAll() {
      //fail
      console.error('error');
      jqXHR_res.forEach((res) => {
        console.error(res);
      });
      infoShowText_helper(
        targetElement,
        'Ошибка запроса. Выполнить новый запрос?',
        sectionReportsDetail,
      );
      if (funcUpdateElemState) {
        funcUpdateElemState(objId, {background: 'red'})
      } else {
        clickElem.style.background = 'red';
      }
    }
  }

  function ajaxPositionsDoneAll_action_py() {
    let index = 0;

    for (let i = 0; i < piecesPositionsPy.length; i++) {
      for (let j = 0; j < piecesPositionsPy[i].length; j++) {
        piecesPositions[index] = piecesPositionsPy[i][j];
        index++;
      }
    }

    ajaxPositionsDoneAll_action(true);
  }

  function ajaxPositionsDoneAll_action(isPython = false) {
    let response = ajaxPositionsDoneAll(
      piecesPositions,
      targetElement,
      isOnMap,
      isPython,
    );

    if (isPython && !response) {
      piecesPositions = [];
      getPicesPositions();
      return;
    }

    infoShowText_helper(
      targetElement,
      'Все данные получены, обрабатываю результат... ',
      sectionReportsDetail,
    );

    if (isOnMap) {
      treatmentResponseOurAndSave(response, timeCheck, targetElement);
      if (funcUpdateElemState) {
        funcUpdateElemState(objId, {background: 'green'})
      } else {
        clickElem.style.background = 'green';
      }
      // mobileEvents.setDefaultSize();
    } else {
      response.randomNum = Math.random();
      globalObjects['positionsFromTemplateDetail'] = response;
      skillsManDetailTemplate.rows = {};
      if (response) {
        if (!violationsDescriptions.desc) {
          violationsDescriptions.laod();
        }
        const worker = new CalculateRequestDataworker();

        const detailViolationsSetting =
          skillsManDetailTemplate.getDetailViolationsId(
            skillsManDetailTemplate.detailViolationsTable,
          );
        worker.postMessage({
          templateName: 'skillsManDetail',
          data: {
            REPORT: response,
            violationsDescriptionsDesc: violationsDescriptions.desc,
            violationsDescriptionsSpnErrors: violationsDescriptions.spnErrors,
            detailViolationsSetting,
            morePositionsCnt: skillsManDetailTemplate.morePositionsCnt,
            detailPosCntElementValue:
              skillsManDetailTemplate.detailPosCntElement.value,
            allPosesInDetailElementChecked:
              skillsManDetailTemplate.allPosesInDetailElement.checked,
          },
        });

        worker.addEventListener('message', (event) => {
          const data = event.data;
          if (data.message) {
            infoShowText_helper(
              targetElement,
              data.message,
              sectionReportsDetail,
            );
            return;
          }
          data.format = formatToDisplay_helper;
          data.getVolationDesc_helper = getVolationDesc_helper;
          skillsManDetailTemplate.renderHtml(data);
          worker.terminate();
        });

        worker.addEventListener('error', ({ message }) => {
          console.error(message);
          worker.terminate();
        });
      }
      infoShowText_helper(
        targetElement,
        'Отчет завершен, получено ' +
          response.positions.length +
          ' позиций за ' +
          roundNumber_helper((new Date() - timeCheck.begin) / 1000, 1) +
          ' секунд. Выполнить новый?',
      );
    }
  }

  function ajaxPositionsDoneAll(
    piecesPositions,
    targetElement,
    isOnMap,
    isPython = false,
  ) {
    if (!piecesPositions.length) {
      infoShowText_helper(
        targetElement,
        'Ошибка: данные отсутствуют, попробуйте обновить страницу. ',
      );
      return;
    }

    function ajaxPositionsDoneAllGetParam(result, jsonRes, paramName) {
      if (!result[paramName] && jsonRes[paramName]) {
        result[paramName] = String(jsonRes[paramName]).trim();
      }
    }

    let result = {
      objName: '',
      gearboxName: '',
      objId: '',
      getBegin: 0,
      getEnd: 0,
      memory_get_peak_usage_summ: 0,
      positions: [],
    };

    if (!isOnMap) {
      result['error'] = '';
      result['objConf'] = '';
    }

    let positionsArr = [];
    for (let i = 0; i < piecesPositions.length; i++) {
      let piecePos = piecesPositions[i];

      let jsonRes;
      if (piecePos !== null && typeof piecePos === 'object') {
        jsonRes = piecePos;
      } else {
        jsonRes = JSON.parse(piecePos);
        piecesPositions[i] = ''; // free memory
      }

      if (!jsonRes) {
        console.error('Ошибка: получен НЕ JSON в номере ответа ' + i);
        continue;
      }

      if (
        !('objId' in jsonRes) ||
        !('objName' in jsonRes) ||
        !('positions' in jsonRes)
      ) {
        console.error(
          'Ошибка: ответ получен, но он невалидный !!! в номере ответа ' + i,
        );
        continue;
      }

      if (!(0 in jsonRes['positions'])) {
        console.error(
          'Ошибка: ответ получен, но в нем нет информации о позициях !!! в номере ответа ' +
            i,
        );
        continue;
      }

      let piecePosIsEmpty = true;
      for (let key in jsonRes) {
        piecePosIsEmpty = false;
        break;
      }

      if (piecePosIsEmpty) {
        if (isPython) {
          console.error(
            'Ошибка: пустое тело при запросе позиций с сервиса с фильтром в ответе номер ' +
              i,
          );
          return false;
        }
        console.error(
          'Ошибка: пустое тело при запросе позиций обычным методом в ответе номер ' +
            i,
        );
        continue;
      }

      if ('objConf' in jsonRes && jsonRes['objConf']) {
        result['objConf'] = jsonRes['objConf'];
      }

      if ('error' in jsonRes && jsonRes['error']) {
        result['error'] += ' / ' + jsonRes['error'];
      }

      if (
        jsonRes['getBegin'] &&
        (!result['getBegin'] || result['getBegin'] > jsonRes['getBegin'])
      ) {
        result['getBegin'] = jsonRes['getBegin'];
      }

      if (
        jsonRes['getEnd'] &&
        (!result['getEnd'] || result['getEnd'] < jsonRes['getEnd'])
      ) {
        result['getEnd'] = jsonRes['getEnd'];
      }

      ajaxPositionsDoneAllGetParam(result, jsonRes, 'objName');
      ajaxPositionsDoneAllGetParam(result, jsonRes, 'objId');
      ajaxPositionsDoneAllGetParam(result, jsonRes, 'gearboxName');

      if (jsonRes['memory_get_peak_usage']) {
        result['memory_get_peak_usage_summ'] +=
          jsonRes['memory_get_peak_usage'] / 1000000; // usage for php script in server, Mb
      }

      if (jsonRes['positions']) {
        const positions = convertJsonAgg(jsonRes['positions']);
        jsonRes['positions'] = ''; // free memory

        if (jsonRes['other'] && !jsonRes['other']['error']) {
          const positionsOther = convertJsonAgg(
            jsonRes['other']['positions'][0]['json_agg'],
          );
          jsonRes['other']['positions'][0]['json_agg'] = ''; // free memory
          concatOtherPositions({ positions, positionsOther });
        }

        positionsArr.push({
          positions,
          getBegin: jsonRes['getBegin'],
        });
      }
    }

    concatResultPositions(result, positionsArr);

    return result;
  }

  function convertJsonAgg(json_agg) {
    if (json_agg !== null && typeof json_agg === 'object') {
      return json_agg;
    } else {
      return JSON.parse(json_agg);
    }
  }

  function concatOtherPositions({ positions = [], positionsOther = [] } = {}) {
    if (!positionsOther || !positionsOther.length) {
      return;
    }

    positionsOther = positionsOther.reduce((accum, pos) => {
      accum[pos.time] = pos;
      delete pos.time;
      return accum;
    }, {});

    positions.forEach((poition, index) => {
      const { time } = poition;
      const posOther = positionsOther[time] || {};

      for (let key in posOther) {
        const value = posOther[key];
        if (!index || value !== null) {
          poition[key] = value;
        }
      }
    });
  }

  function concatResultPositions(result, positionsArr) {
    for (const key in result['objConf']) {
      result['objConf'][key] = String(result['objConf'][key]).trim();
    }

    positionsArr.sort(function (a, b) {
      return a['getBegin'] - b['getBegin'];
    });
    result.positions = positionsArr[0].positions;
  }

  function preparePromises(url, preparingDataArr, isPython, isOther = false) {
    const queryCount = preparingDataArr.length;
    const infoTextBegin = isPython ? 'Загрузка Py: ' : 'Загрузка: ';

    return preparingDataArr.map((preparingData) => {
      preparingData.isPython = isPython;
      const body = JSON.stringify(preparingData);
      const method = isGo ? 'GET' : 'POST';

      const optionsGetFuels = {
        method,
        body,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      };
      const options = {
        method,
      };

      // options.mode = 'no-cors';
      if (!isGo) {
        options.body = body;
      }

      if (isPython) {
        options.dataType = 'json';
      } else {
        options.headers = {
          'Content-Type': 'application/json;charset=utf-8',
        };
      }

      const queryUrl = new URL(url + '/' + preparingData.objects);
      if (isGo) {
        for (let key in preparingData) {
          if (key === 'timeOffset') {
            queryUrl.searchParams.set(key, +preparingData[key]);
            continue;
          }
          queryUrl.searchParams.set(key, preparingData[key]);
        }
      }

      return new Promise((resolve, reject) => {
        const queries = [{ url: isGo ? queryUrl : url, options }];

        if (isOther) {
          queries.push({
            url: url_get_positions_other,
            options: optionsGetFuels,
          });
        }

        const promises = queries.map((query) => {
          return fetchJson(query.url, query.options).then((res) => {
            if (isPython) {
              return res.json();
            }

            return JSON.stringify(res);
          });
        });

        Promise.all(promises)
          .then((responses) => {
            const [response, other] = responses.map((res) => {
              if (res !== null && typeof res === 'object') {
                return res;
              } else {
                try {
                  const json_res = JSON.parse(res);
                  return json_res;
                } catch (e) {
                  console.error('not JSON', res);
                }

                return '';
              }
            });

            responses = '';
            response.other = other;

            const resCountHere = isPython ? ++resCountPy : ++resCount;

            infoShowText_helper(
              targetElement,
              infoTextBegin +
                resCountHere +
                ' из ' +
                queryCount +
                ' (' +
                roundNumber_helper((100 * resCountHere) / queryCount, 1) +
                ' %)',
              sectionReportsDetail,
            );

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });

      return fetchJson(url, options).then((res) => {
        const resCountHere = isPython ? ++resCountPy : ++resCount;

        infoShowText_helper(
          targetElement,
          infoTextBegin +
            resCountHere +
            ' из ' +
            queryCount +
            ' (' +
            roundNumber_helper((100 * resCountHere) / queryCount, 1) +
            ' %)',
          sectionReportsDetail,
        );

        if (isPython) {
          return res.json();
        }

        return res.text();
      });
    });
  }

  function fetchCall(url, options) {
    return;
  }
}

function preparingDataFromGetPos(objId, targetElement, isOnMap, timeInterval) {
  let preparing_data = {};
  preparing_data.objects = objId;

  // let timeBeginArr = interval.timeBegin;
  // let timeBeginArr = getTimeFromString_helper(
  //   document.getElementById('beginTime').value,
  // );
  // let timeEndArr = interval.timeEnd;
  // let timeEndArr = getTimeFromString_helper(
  //   document.getElementById('endTime').value,
  // );
  if (!timeInterval.timeBegin) {
    infoShowText_helper(
      targetElement,
      'Неверно указаны дата или время начала запроса.',
    );
    return false;
  }
  if (!timeInterval.timeEnd) {
    infoShowText_helper(
      targetElement,
      'Неверно указаны дата или время окончания запроса.',
    );
    return false;
  }
  preparing_data.timeBegin = timeInterval.timeBegin / 1000;
  preparing_data.timeEnd = timeInterval.timeEnd / 1000;

  // alert(preparing_data.timeBegin + ' ' + timeEnd + ' ' + Number(timeEnd - preparing_data.timeBegin));
  if (preparing_data.timeBegin >= preparing_data.timeEnd) {
    infoShowText_helper(
      targetElement,
      'неверно выбран интервал запроса, исправьте ошибку.',
    );
    return false;
  }

  preparing_data.timeOffset = -new Date().getTimezoneOffset() / 60;

  preparing_data.interval = preparing_data.timeEnd - preparing_data.timeBegin; //86400 переводит секунды в часы
  preparing_data.allColumns = isOnMap ? '' : 1;

  return preparing_data;
}

function getCallOurPosotionsPrepareDataSends(preparing_data, isOnMap) {
  // const step = 43200000; // 86400 - сутки, 43200000 = 12 часов (в милисекундах)
  const step = 28800000; // 28800 - 8 часов (в миллисекундах)
  preparing_data.sends = [];
  let allColumns = isOnMap ? '' : true;

  const { timeEnd, timeBegin } = preparing_data;
  const getTimeBegin = timeBegin * 1000;
  const getTimeEnd = timeEnd * 1000;
  const getTimeInterval = getTimeEnd - getTimeBegin;

  if (isGo) {
    preparing_data.sends.push({
      interval: getTimeInterval / 1000,
      objects: preparing_data.objects,
      timeBegin: timeBegin,
      timeOffset: preparing_data.timeOffset,
      allColumns: allColumns,
      getConfig: true,
    });
    return;
  }

  for (let i = 0; i < getTimeInterval; i += step) {
    let resBegin = getTimeBegin + i;
    if (i) resBegin++;

    let resEnd = getTimeBegin + i + step;

    if (resEnd > getTimeEnd) {
      resEnd = getTimeEnd;
    }

    let resInterval = resEnd - resBegin;

    if (resInterval <= 0) {
      continue;
    }

    let getConfig = !i || '';

    preparing_data.sends.push({
      interval: resInterval,
      objects: preparing_data.objects,
      timeBegin: resBegin,
      timeOffset: preparing_data.timeOffset,
      allColumns: allColumns,
      getConfig: getConfig,
    });
  }
}

function treatmentResponseOurAndSave(response, timeCheck, targetElement) {
  // сохранение позиций и вставка трека на карту
  if (
    !('objId' in response) ||
    !('objName' in response) ||
    !('positions' in response)
  ) {
    infoShowText_helper(
      targetElement,
      'Ошибка: ответ получен, но он невалидный !!!',
    );
    return;
  }

  infoShowText_helper(targetElement, 'Данные получены, обрабатываю ...');
  let positions = response['positions'];

  let infoForShow = '';
  infoForShow =
    'По объекту ' + response['objName'] + ' получено ' + positions.length;
  infoForShow += ' позиций с сервера за период с ';
  let trackBegin = formatDateHelper(
    new Date(response['getBegin'] * 1000),
    'dd.mm.yyyy hh:nn:ss',
  );
  let trackEnd = formatDateHelper(
    new Date(response['getEnd'] * 1000),
    'dd.mm.yyyy hh:nn:ss',
  );
  infoForShow += trackBegin + ' по ' + trackEnd;
  let objName = response['objName'];
  let gearboxName = response['gearboxName'];
  let objId = response['objId'];

  const objectRow = document.getElementById(objId);
  const stateNumber = objectRow.querySelector('.search-stateNumber').innerText;

  let points = getPointsFromOur(positions, objName, gearboxName);

  let trackInfo =
    'Трек по объекту ' +
    objName +
    ' ' +
    stateNumber +
    '<br>с ' +
    trackBegin +
    ' по ' +
    trackEnd;

  var queryEnd = new Date();
  const queryInterval = (queryEnd - timeCheck.begin) / 1000;

  infoForShow += ` (запрос выполнен за ${roundNumber_helper(
    queryInterval,
    1,
  )} секунд)`;
  infoShowText_helper(targetElement, infoForShow);

  // add into positionsList
  let positionsList = document.getElementById('positionsList');
  let html =
    '<option selected value="' +
    objId +
    '">' +
    objName +
    '(' +
    trackBegin +
    ' - ' +
    trackEnd +
    ')' +
    '</option>';
  removeSelectOption(positionsList, '[value="' + objId + '"]'); // если ранее запрос уже был выполнен - удалим его
  positionsList.innerHTML += html;

  removeSelectOption(positionsList, '[value="none"]');

  // violations list setting - меню нарушений на карте заполнение
  if (!(objId in globalObjects.violationsSettingsList)) {
    // по умолчанию отключаем отображение нарушений на карте
    globalObjects.violationsSettingsList[objId] = {};
  }
  violationsSettingListInnerHtml(
    violationsDescriptions.desc,
    globalObjects.violationsSettingsList[objId],
    points['violations_stat'],
  );

  leafletMain.leafletAddPolyline(points, trackInfo, objId, globalObjects, funcUpdateElemState);
  leafletMain.addViolationsOnMap(
    points,
    objId,
    objName,
    gearboxName,
    globalObjects.violationsSettingsList[objId],
    false,
  );
  // addEventsViolationsList();

  globalObjects.globalPositions[objId] = {
    points,
    trackInfo,
    infoForShow,
    getBegin: response['getBegin'],
    getEnd: response['getEnd'],
    trackBegin,
    trackEnd,
    stateNumber,
    objName,
  };

  // delete points;
  // });

  // getPointsWorker.addEventListener('error', ({ message }) => {
  //   console.error(message);
  // });
}

function getPointsFromOur(
  positions,
  objName,
  gearboxName,
  violationsDescriptionsDesc,
) {
  const points = {
    objName,
    gearboxName,
    latlngs: [],
    params: [], // values: [], пишется в params
    allValues: positions, // для графиков
    violations_stat: {
      // статистика по количеству нарушений
      summ: 0, // сумма всех нарушений
    },
    consumptions_stat: {
      // статистика по количеству заправок
      summ: 0, // сумма всех заправок
    },
    violations_text: [],
    violations_values: [],
  };

  let column = 0;
  let violation_id = [];
  let violation_text = [];
  let violation_values = [];

  let posIndex = 0;
  let maxSpeed = 0;

  let distSumm = 0;
  let distPrev = -1;
  let distBegin = -1;
  let timeWithDist = 0;
  let curSpeed = 0;
  let old_analyzed_consumption_1 = positions[0]['analyzed_consumption_1'];
  for (let k = 0; k < positions.length; k++) {
    const {
      analyzed_consumption_1,
      time,
      lat = 0,
      lon = 0,
      head,
      speed,
      violation_1,
      violation_2,
      violation_3,
      clutch_time_uninterruptedly,
      pto_cnt_violation,
      spd_accel,
      gps = 0,
      dist,
      is_last_day_pos,
      back_raised,
      back_raised_cnt_violation
    } = positions[k];
    if (analyzed_consumption_1 > old_analyzed_consumption_1 + 25000) {
      const consumption = +(
        (analyzed_consumption_1 - old_analyzed_consumption_1) /
        1000
      ).toFixed(1);

      points['consumptions_stat'][k] = {
        time,
        consumption,
      };
      points['consumptions_stat']['summ'] += consumption;
    }
    old_analyzed_consumption_1 = analyzed_consumption_1;

    if (speed < 2500) {
      // 250 км/ч
      curSpeed = speed;
    }

    const canDistProtocolLimit = 21055406000; // в метрах
    const isDist = Boolean(dist && dist < canDistProtocolLimit);

    if (isDist && distBegin === -1) {
      distBegin = dist;
      distPrev = dist;
    }

    if (isDist) {
      let dSummTemp = dist > distPrev ? dist - distBegin : distPrev - distBegin;
      if (timeWithDist && dSummTemp && dSummTemp > distSumm) {
        const timeDiff = time - timeWithDist;
        if (!timeDiff) {
          timeDiff = 1000;
        }
        const avgSpdTemp = (dSummTemp - distSumm) / 1000 / (timeDiff / 3600000);
        if (avgSpdTemp > 250) {
          dSummTemp = distSumm;
        }
      }

      timeWithDist = time;

      // distSumm = (dist > distPrev) ? dist - distBegin : distPrev - distBegin;
      distSumm = dSummTemp;
      distPrev = dist;
    }

    positions[k].distSumm = distSumm;

    if (!(lat > 0 && lon > 0)) {
      continue;
    }

    column = 0;
    violation_id = [];
    violation_text = [];
    violation_values = '';

    while ('violation_' + Number(++column) in positions[k]) {
      const col_name = 'violation_' + column;
      if (!(positions[k][col_name] > 0)) {
        continue;
      }

      for (let i = 0; i < 31; i++) {
        if (positions[k][col_name] & (1 << i)) {
          let viol_id = i + 1 + 31 * (column - 1);
          violation_id.push(viol_id);
          let violation_desc = getVolationDesc_helper(
            viol_id,
            violationsDescriptionsDesc,
          );
          violation_text.push(violation_desc['description']);
          //clutch_time_uninterruptedly, pto_cnt_violation, spd_accel

          if (viol_id in points['violations_stat']) {
            points['violations_stat'][viol_id]++;
          } else {
            points['violations_stat'][viol_id] = 1;
          }

          points['violations_stat']['summ']++;
        }
      }

      violation_values = {
        clutch_time_unine: clutch_time_uninterruptedly,
        pto_cnt_violation,
        spd_accel,
        spd: curSpeed,
        back_raised_cnt_violation
      };
    }

    if (curSpeed > maxSpeed) {
      maxSpeed = curSpeed;
    }

    points['latlngs'][posIndex] = [
      lat / 1000000000000000,
      lon / 1000000000000000,
    ];

    points['params'][posIndex] = {
      viewTime: time / 1000,
      speed: curSpeed,
      violation_1,
      violation_2,
      violation_3,
      violation_id: violation_id.join(';'),
      violation_text: violation_text.join(';<br>'),
      violation_values: violation_values,
      head: head,
      gps,
      maxSpeed,
      violationsSumm: points['violations_stat']['summ'],
    };

    // points['values'][posIndex] = {time, gps, maxSpeed};

    posIndex++;
  }

  // for (var i = 0; i < points['params'].length; i++) {
  //   if (points['params'][i]['violation_id'].length > 0) {
  //   }
  // }

  return points;
}

function removeSelectOption(selectList, childSelector) {
  // remove option
  let childOption = selectList.querySelector(childSelector);
  if (childOption) {
    selectList.removeChild(childOption);
  }
  // add default option
  if (!selectList.options.length) {
    document.getElementById('violations-setting').innerHTML =
      'Меню нарушений на карте (нет запросов).';
    selectList.innerHTML += '<option value="none">Нет запросов</option>';
  } else {
    // проверить какой объект выводится - и по нему вывести настройку отображения
  }
}

function violationsSettingListInnerHtml(
  violationsDescriptionsDesc,
  violationsSettingsObject,
  violationsStat,
) {
  // add into html
  let violationsDescriptionList = getViolationsDescriptionList(
    violationsDescriptionsDesc,
    violationsSettingsObject,
    violationsStat,
  ); // html text
  document.getElementById('violations-setting').innerHTML =
    violationsDescriptionList;
}

function getViolationsDescriptionList(
  violationsDescriptionsDesc,
  violationsSetting,
  violationsStat,
) {
  // let violationsDescriptionListTemplate = document.getElementById(
  //   'violations-description-list-template',
  // ).innerHTML;
  // let compiled = _.template(violationsDescriptionListTemplate);
  let compiled = _.template(violationsDescriptionListHtml);
  let html = compiled({
    data: violationsDescriptionsDesc,
    violationsSetting: violationsSetting,
    violationsStat: violationsStat,
  });
  return html;
  // document.getElementById('section-reports').innerHTML = html;
}
