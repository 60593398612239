import { getUnixByDate } from '@/helpers/main_helper';

export class D3LinearChart {
  brushStart;
  dragPointer;
  forLegendState;
  isZoomed;
  clearAllButtonText = 'спрятать все:';
  expansionRangePercent = 1.5;
  elementId = '';
  lastChartsUpdateUnix = Date.now();
  chartsUpdateInterval = 1000 / 50; // ms / fps

  constructor({
    d3,
    elementId,
    config,
    eventsHandling = true,
    clearAllButton = true,
    defaultLocale = {
      dateTime: '%A, %e %B %Y г. %X',
      date: '%d.%m.%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: [
        'воскресенье',
        'понедельник',
        'вторник',
        'среда',
        'четверг',
        'пятница',
        'суббота',
      ],
      shortDays: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
      months: [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
      ],
      shortMonths: [
        'янв',
        'фев',
        'мар',
        'апр',
        'май',
        'июн',
        'июл',
        'авг',
        'сен',
        'окт',
        'ноя',
        'дек',
      ],
    },
  } = {}) {
    this.d3 = d3;
    if (defaultLocale) {
      this.d3.timeFormatDefaultLocale(defaultLocale);
    }
    this.elementId = elementId;
    this.config = config;
    this.tooltip = this.createTooltip();
    this.createChart(elementId, config, eventsHandling, clearAllButton);

    return this;
  }

  createTooltip() {
    const tooltip = this.d3
      .select('body')
      .append('div')
      .attr('class', 'banner rounded-borders  bg-info')
      .style('position', 'absolute')
      .style('z-index', '1000000')
      .style('background', '#fff')
      .html('');

    tooltip.setVisible = (isVisible) => {
      tooltip.style('display', isVisible ? 'block' : 'none');
    };

    tooltip.setVisible(false);

    return tooltip;
  }

  addTooltip(elem, defaultText) {
    elem
      .on('mouseover', (event, d) => {
        const html = !!d && d.tooltipHtml ? d.tooltipHtml : defaultText;
        if (html) {
          this.tooltip.html(html);
          this.tooltip.setVisible(true);
        }
      })
      .on('mousemove', (event) => {
        const offset = 10;

        const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } =
          this.tooltip.node();
        const { innerWidth: documentWidth, innerHeight: documentHeight } =
          event.view;

        const top = event.pageY - offset;
        const bottom = documentHeight - event.pageY - offset;
        const left = event.pageX + offset;
        const right = documentWidth - event.pageX + offset;

        if (top + tooltipHeight <= documentHeight - offset) {
          this.tooltip.style('top', top + 'px');
          this.tooltip.style('bottom', 'auto');
        } else {
          this.tooltip.style('top', 'auto');
          this.tooltip.style('bottom', bottom + 'px');
        }

        if (left + tooltipWidth <= documentWidth - offset) {
          this.tooltip.style('left', left + 'px');
          this.tooltip.style('right', 'auto');
        } else {
          this.tooltip.style('left', 'auto');
          this.tooltip.style('right', right + 'px');
        }
      })
      .on('mouseout', () => {
        this.tooltip.setVisible(false);
      });
  }

  createChart(elementId, config, eventsHandling, clearAllButton) {
    const chart = this.createChartObject(config);

    const {
      width = 150,
      height = 100,
      titleText = '',
      titleSize = 22,
    } = config;

    const chartAreaElement = this.d3.select('#' + elementId);
    // Нужен временный элемент чтобы работал просчёт размеров элементов
    const tempAreaElement = this.d3.select('#' + 'app');
    const tempDiv = tempAreaElement.append('div')

    // создаем объект svg
    const svg = tempDiv
      .append('svg')
      .attr('class', 'axis')
      .attr('width', width)
      .attr('height', height)
      .style('user-select', 'none');

    chart.svg = svg;

    // добавляем заголовок
    if (titleText) {
      const title = svg
        .append('text')
        .attr('class', 'title not-excel')
        .attr('dominant-baseline', 'text-before-edge')
        .style('font-size', titleSize)
        .text(titleText);

      this.setTitlePosition(chart, title, width);
      chart._title = title;
    }

    this.createLegend(chart, eventsHandling, clearAllButton);

    const {
      left: marginLeft = 0,
      right: marginRight = 0,
      bottom: marginBottom = 0,
      top: marginTop = 0,
    } = chart._margin;
    // добавляем к оси отступы слева и справа
    chart._xAxisLength = width - marginLeft - marginRight;
    // длина оси Y = высота контейнера svg - отступ сверху и снизу
    chart._yAxisLength = height - marginBottom - marginTop;

    chart._axesWrapperElement = svg
      .append('g')
      .attr('class', 'chart-axes-wrapper');

    this.setOrientScaleCount(chart);
    this.createAxes({ chart });

    const timeNow = Date.now();

    // Add a clipPath: everything out of this area won't be drawn.
    const clip = svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', `clip-${timeNow}`)
      .append('svg:rect')
      .attr('width', chart._xAxisLength)
      .attr('height', chart._yAxisLength)
      .attr('x', marginLeft)
      .attr('y', marginTop);

    // Create the line variable: where both the line and the brush take place
    const linesGroupWrapper = svg
      .append('g')
      .attr('clip-path', `url(#clip-${timeNow})`)
      .attr('style', 'chart-lines-wrapper');

    const linesGroup = linesGroupWrapper
      .append('g')
      .attr('class', 'chart-lines');

    const textDataGroup = svg.append('g').attr('class', 'charts-text-data');

    chart.linesGroupWrapper = linesGroupWrapper;
    chart.linesGroup = linesGroup;
    chart.textDataGroup = textDataGroup;

    chart.charts.forEach((chartItem, chartIndex) => {
      const { data, isStartHidden } = chartItem;

      const textData = data.reduce((accum, item) => {
        const { text = null } = item;
        if (text !== null) {
          accum.push(item);
        }
        return accum;
      }, []);

      if (textData.length) {
        chartItem._textData = textData;
      }

      if (isStartHidden) {
        if (chartItem.isLine) {
          chartItem.isLine = false;
        }
        if (chartItem.isDot) {
          chartItem.isDot = false;
        }
        if (chartItem.isBar) {
          chartItem.isBar = false;
        }
        if (chartItem.isAreaFull) {
          chartItem.isAreaFull = false;
        }
      }

      if (!isStartHidden) {
        this.drawChart({ svg, chart, chartItem, chartIndex, linesGroup });
      }
    });

    if (eventsHandling) {
      this.crateVerticalLine(chart);

      this.addBrushing({ chart, linesGroupWrapper });

      this.zoom(svg);

      this.addMouseEvents(svg, chart);
    }

    // всплывающее окно
    const { infoTitleSize = '12px', infoTitleBgFill = 'rgba(30,30,30,0.2)' } =
      chart;
    chart._infoTitle = tempDiv
      .append('div')
      .attr('hidden', '1')
      .style('font-size', infoTitleSize)
      .style('font-weight', 'bold')
      .style('color', 'black')
      .style('padding', '5px')
      .style('border-radius', '5px')
      .style('box-shadow', '0.4em 0.4em 5px rgba(122,122,122,0.5)')
      .style('background-color', infoTitleBgFill)
      .style('position', 'absolute');

    chart._chartAreaElement = tempDiv;

    chart.clip = clip;
    this.chart = chart;

    // переносим со временного в основной элемент, освобождаем временный элемент
    const children = Array.from(tempDiv.node().childNodes);
    children.forEach(child => {
      chartAreaElement.node().appendChild(child);
    });
    tempDiv.remove();

    return chart;
  }

  crateVerticalLine(chart) {
    const { linesGroupWrapper } = chart;
    const textPadding = 3;

    chart._verticalLineClickValue = null;
    chart._verticalLineClickX = 0;
    chart._verticalLineClickIndex = 0;

    chart._verticalLineClickWrapper = linesGroupWrapper
      .append('g')
      .attr('class', 'chart-vertical-line-wrapper');

    chart._verticalLineClickElement = chart._verticalLineClickWrapper
      .append('line') // добавляем линию
      .attr('class', 'chart-vertical-line')
      .style('stroke', '#000')
      .style('shape-rendering', 'crispedges')
      .style('stroke-opacity', '1')
      .style('stroke-width', '3')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 0);

    chart._verticalLineTextWrapper =
      chart._verticalLineClickWrapper.append('g');

    chart._verticalLineRectElement = chart._verticalLineTextWrapper
      .append('rect')
      .attr('class', 'chart-vertical-line-text')
      .attr('dominant-baseline', 'text-before-edge')
      .attr('width', 0)
      .attr('height', 0)
      .attr('rx', 2)
      .attr('fill', 'rgba(255,255,255,0.7)')
      .style('stroke-width', '1')
      .style('stroke', 'rgba(0,0,0, 0.7)');

    chart._verticalLineTextElement = chart._verticalLineTextWrapper
      .append('text')
      .attr('class', 'chart-vertical-line-text not-excel')
      .attr('dominant-baseline', 'text-before-edge')
      .attr('x', textPadding)
      .attr('y', textPadding);

    chart._verticalLineTextPadding = textPadding;
    chart._verticalLineTextPos = { x: 0, y: 0 };
  }

  createLegend(chart, eventsHandling, clearAllButton) {
    const {
      isLegend = true,
      charts,
      svg,
      legendTextSize = '12px',
      legendRectWidth = 10,
    } = chart;

    if (!isLegend) {
      chart._legendHeight = 0;
      chart._legendElements = false;
      return;
    }

    this.forLegendState = [];
    const forLegendState = this.forLegendState;

    const innerMargin = 3;
    const itemsMargin = innerMargin * 2;

    chart.legendElements = {};
    // создаем группу
    const lGroup = svg
      .append('g')
      .attr('cursor', 'pointer')
      .attr('class', 'chart-legend')
      .attr('style', 'user-select: none');

    if (clearAllButton) {
      // clear all button
      this.createLegendItem({
        chart,
        lGroup,
        index: 'all',
        textColor: 'blue',
        innerMargin,
        label: this.clearAllButtonText,
        colorStroke: 'gray',
        opacity: 0.5,
        legendTextSize,
        legendRectWidth: 0,
        isStrikeLine: false,
      });
    }

    chart._legendElements = lGroup;
    chart._legendInnerMargin = innerMargin;
    chart._legendItemsMargin = itemsMargin;

    charts.forEach((chartItem, index) => {
      this.addLegendItem({
        chart,
        chartItem,
        chartItemIndex: index,
      });
    });

    this.updateLegend(chart);

    if (eventsHandling) {
      chart._legendElements.on('click', (event) => {
        this.legendClickEvent(event);
      });
    }
  }

  addLegendItem({ chart, chartItem, chartItemIndex }) {
    const {
      _legendElements,
      _legendInnerMargin,
      legendTextSize = '12px',
      legendRectWidth = 10,
    } = chart;

    const {
      colorStroke = '#fff',
      opacity = 1,
      label = '',
      isLine = true,
      isDot = false,
      isBar = false,
      isAreaFull,
    } = chartItem;

    this.forLegendState[chartItemIndex] = {
      isLine,
      isDot,
      isBar,
      isAreaFull,
    };

    if (!isLine && !isBar && !isAreaFull && !isDot) {
      return;
    }

    this.createLegendItem({
      chart,
      chartItem,
      lGroup: _legendElements,
      index: chartItemIndex,
      innerMargin: _legendInnerMargin,
      label,
      colorStroke,
      opacity,
      legendTextSize,
      legendRectWidth,
    });
  }

  createLegendItem({
    chart,
    lGroup,
    chartItem,
    index,
    innerMargin,
    label,
    colorStroke,
    opacity,
    legendTextSize,
    legendRectWidth,
    isStrikeLine = true,
    textColor,
  } = {}) {
    const itemGroup = lGroup
      .append('g')
      .attr('class', 'chart-legend-item')
      .attr('data-index', index);

    const text = itemGroup 
      .append('text')
      .attr('class', 'not-excel')
      .attr('dominant-baseline', 'text-before-edge')
      .attr('x', legendRectWidth + innerMargin)
      .style('font-size', legendTextSize)
      .text(label);

    if (textColor) {
      text.style('font-weight', 'bold');
      text.style('fill', textColor);
    }

    const { height: textH, width: textW } = text.node().getBBox();

    const { legendValueSymbolsCount, isStartHidden } = chartItem || {};

    if (legendValueSymbolsCount) {
      chartItem._legendValueElem = itemGroup
        .append('text')
        .attr('class', 'not-excel')
        .attr('dominant-baseline', 'text-before-edge')
        .attr('x', legendRectWidth + innerMargin + textW)
        .style('font-size', legendTextSize)
        .html(this.getValueVithNbsp('', legendValueSymbolsCount));
    }

    const rectH = textH * 0.6;

    itemGroup
      .append('rect')
      .attr('y', (textH - rectH) / 2)
      .attr('width', legendRectWidth)
      .attr('height', rectH)
      .style('fill', colorStroke)
      .style('opacity', opacity);

    let { width: itemW, height: itemH } = itemGroup.node().getBBox();

    const displayStyle = isStartHidden ? '' : 'none';

    if (isStrikeLine) {
      itemGroup
        .append('line')
        .attr('x1', 0)
        .attr('y1', itemH / 2)
        .attr('x2', itemW)
        .attr('y2', itemH / 2)
        .attr('class', 'chart-legend-item-strike')
        .style('stroke', '#000')
        .style('shape-rendering', 'crispedges')
        .style('stroke-width', '1')
        .style('display', displayStyle);
    }

    chart.legendElements[label] = itemGroup;
  }

  getValueVithNbsp(value, legendValueSymbolsCount) {
    const space = '&nbsp;';
    if (!value) {
      return space.repeat(2 * legendValueSymbolsCount);
    }

    const length = String(value).length;
    if (legendValueSymbolsCount > length) {
      const repeated = space.repeat(2 * (legendValueSymbolsCount - length) - 1);
      return `${space}${value}${repeated}`;
    }

    return `${space}${value}`;
  }

  updateLegend(chart) {
    const {
      _legendElements: lGroup,
      _title,
      width: svgWidth,
      height: svgHeight,
      margin,
      _margin,
      _legendItemsMargin: itemsMargin,
      _titleH = 0,
    } = chart;

    if (!lGroup) {
      return;
    }

    const titleH = _title ? _title.node().getBBox().height : 0;
    lGroup.attr('transform', `translate(0, ${titleH})`); // сдвиг оси вправо и вниз;

    const prevPosition = {
      x: 0,
      width: 0,
    };

    let inRowCnt = 0;
    let translateTop = 0;
    let rowHeight = 0;
    let rowHeighResetSum = 0;
    
    lGroup.selectAll('.chart-legend-item').each((d, i, elements) => {
      const itemGroup = elements[i];

      const marginLeft = inRowCnt ? 2 * itemsMargin : itemsMargin;
      const { x: prevX = 0, width: prevW = 0 } = prevPosition;
      let translateX = prevX + prevW + marginLeft;

      itemGroup.setAttribute(
        'transform',
        `translate(${translateX}, ${translateTop})`,
      ); // сдвиг оси вправо и вниз;

      let curBBox = itemGroup.getBBox();
      const { width: itemW, height: itemH, x: itemX } = curBBox;
      const rightPos = translateX + itemW + itemX;

      if (rowHeight < itemH) {
        rowHeight = itemH;
      }

      if (inRowCnt && rightPos > svgWidth) {
        // перенос на новую строку
        translateTop = rowHeight + rowHeighResetSum;
        translateX = itemsMargin;
        itemGroup.setAttribute(
          'transform',
          `translate(${translateX}, ${translateTop})`,
        ); // сдвиг вправо и вниз;
        rowHeighResetSum += rowHeight;
        rowHeight = itemH;
        inRowCnt = 0;
      }

      prevPosition.width = curBBox.width;
      prevPosition.x = translateX + curBBox.x;

      inRowCnt++;
    });

    const legendHeight = lGroup.node().getBBox().height;
    chart._legendHeight = legendHeight;

    const { top: marginConfTop = 0 } = margin;
    _margin.top = legendHeight + marginConfTop + _titleH;
    chart._yAxisLength = svgHeight - _margin.bottom - _margin.top;
  }

  legendClickEvent(event) {
    const item = event.target.closest('[data-index]');

    if (!item) {
      return;
    }

    const chartIndex = item.dataset.index;
    const chart = this.chart;
    const { svg, linesGroup } = chart;

    if (chartIndex === 'all') {
      this.legendAllManage(item);
      return;
    }

    const [strikeElement = {}] = item.getElementsByClassName(
      'chart-legend-item-strike',
    );
    const chartItem = chart.charts[chartIndex];
    const { isLine = true, isBar, isDot, isAreaFull } = chartItem;

    if (isLine || isBar || isDot || isAreaFull) {
      this.legendHideChart(chartItem);
      strikeElement.style.display = '';
    } else {
      this.legendShowChart({ svg, chart, linesGroup, chartItem, chartIndex });
      strikeElement.style.display = 'none';
    }

    if (!this.isZoomed) {
      const { yAxis, _yAxisLink: axis } = chartItem;
      const lostCharts = chart.charts.filter((ch) => {
        const { isLine: ch_isLine = true, isDot: ch_isDot } = ch;
        return Boolean(ch.yAxis === yAxis && (ch_isLine || ch_isDot));
      });

      if (!lostCharts.length) {
        return;
      }

      this.legendAxisUpdate(axis, lostCharts);
    }
  }

  legendAllManage(item) {
    const chart = this.chart;
    const { svg, linesGroup, charts, axes } = chart;

    const [textEl] = item.getElementsByTagName('text');
    const { textContent: textElContent } = textEl;

    let strikeDisplay = 'none';

    if (textElContent === this.clearAllButtonText) {
      // спрятать всё
      textEl.textContent = 'показать все:';

      charts.forEach((chartItem) => {
        this.legendHideChart(chartItem);
      });

      strikeDisplay = '';
    } else {
      // показать всё
      textEl.textContent = this.clearAllButtonText;
      chart.charts.forEach((chartItem, chartIndex) => {
        const {
          isLine = true,
          isBar,
          isDot,
          isAreaFull,
          hidden = false,
        } = chartItem;

        if (!isLine && !isBar && !isDot && !isAreaFull && !hidden) {
          this.legendShowChart({
            svg,
            chart,
            linesGroup,
            chartItem,
            chartIndex,
          });
        }
      });

      if (!this.isZoomed) {
        axes.forEach((axis) => {
          const { id: axisId, _mode } = axis;

          if (_mode === 'x') {
            return;
          }

          const lostCharts = chart.charts.filter((ch) => {
            const { isLine = true, isBar, isDot, isAreaFull } = ch;
            return Boolean(
              ch.yAxis === axisId && (isLine || isBar || isDot || isAreaFull),
            );
          });

          this.legendAxisUpdate(axis, lostCharts);
        });
      }
    }

    const legendEl = item.closest('.chart-legend');
    [...legendEl.getElementsByClassName('chart-legend-item-strike')].forEach(
      (strikeElement) => {
        strikeElement.style.display = strikeDisplay;
      },
    );
  }

  legendAxisUpdate(axis, lostCharts) {
    const minMaxY = {};

    lostCharts.forEach((ch, index) => {
      if (ch.type === 'areaFull') {
        return;
      }

      const { min: chMin, max: chMax } = ch._minMax;
      const { y: chMinY } = chMin;
      const { y: chMaxY } = chMax;

      if (!('min' in minMaxY)) {
        minMaxY.min = chMinY;
        minMaxY.max = chMaxY;
        return;
      }

      if (minMaxY.min > chMinY) {
        minMaxY.min = chMinY;
      }

      if (minMaxY.max < chMaxY) {
        minMaxY.max = chMaxY;
      }
    });

    if (axis._min !== minMaxY._min || axis._max !== minMaxY._max) {
      const { _scale: scale } = axis;
      const domain = [minMaxY.max, minMaxY.min];
      scale.domain(domain);

      axis._min = minMaxY.min;
      axis._max = minMaxY.max;

      // ??
      const chartsPositionsIndexes = this.thinOutPositions();
      // обновление осей
      const duration = 500;
      this.axisUpdate(axis, duration);
      // ??
      lostCharts.forEach((chartItem) => {
        this.chartItemUpdate(chartItem, duration, chartsPositionsIndexes);
      });
    }
  }

  legendShowChart({ svg, chart, linesGroup, chartItem, chartIndex } = {}) {
    const forLegend = this.forLegendState[chartIndex];
    for (let key in forLegend) {
      chartItem[key] = forLegend[key];
    }

    this.drawChart({ svg, chart, chartItem, chartIndex, linesGroup });
  }

  legendHideChart(chartItem) {
    chartItem.isLine = false;
    chartItem.isBar = false;
    chartItem.isDot = false;
    chartItem.isAreaFull = false;

    const { _lineElement, _areaElement, _textDataElement } = chartItem;

    if (_lineElement) {
      _lineElement.remove();
    }
    if (_areaElement) {
      _areaElement.remove();
    }
    if (_textDataElement) {
      _textDataElement.remove();
    }
  }

  setOrientScaleCount(chart) {
    const { axes } = chart;

    const orientCount = {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    };

    axes.forEach((axis) => {
      const { orientScale, id: axisId } = axis;
      const mode = axisId.indexOf('x') === 0 ? 'x' : 'y';
      let _orientScale;

      if (mode === 'x') {
        _orientScale = orientScale === 'top' ? 'top' : 'bottom';
      }

      if (mode === 'y') {
        _orientScale = orientScale === 'right' ? 'right' : 'left';
      }

      orientCount[_orientScale]++;

      axis._orientScale = _orientScale;
      axis._mode = mode;
      axis._orientCount = orientCount[_orientScale];
    });
  }

  setTitlePosition(chart, title, svgWidth) {
    if (!title) {
      chart._titleH = 0;
      return;
    }

    const { width: titleW, height: titleH } = title.node().getBBox();
    title.attr('x', svgWidth / 2 - titleW / 2);

    chart._titleH = titleH;
  }

  resize({ width, height, isUpdateLegend = false } = {}) {
    const chart = this.chart;
    const {
      svg,
      clip,
      brush,
      brushedElement,
      _title,
      width: prevW,
      height: prevH,
    } = chart;

    width = width ?? prevW;
    height = height ?? prevH;

    const isRangeX = Boolean(prevW !== width);
    const isRangeY = Boolean(prevH !== height);

    const isRangeChanged = Boolean(isRangeX || isRangeY);

    if (isRangeChanged || isUpdateLegend) {
      this.updateLegend(chart);
    }

    const { _margin: margin } = chart;
    const {
      left: marginLeft,
      right: marginRight,
      bottom: marginBottom,
      top: marginTop,
    } = margin;

    if (isRangeX) {
      // добавляем к оси отступы слева и справа
      chart._xAxisLength = width - marginLeft - marginRight;
      this.chart.width = width;
      svg.attr('width', width);

      this.setTitlePosition(chart, _title, width);
    }

    if (isRangeY) {
      // длина оси Y = высота контейнера svg - отступ сверху и снизу
      chart._yAxisLength = height - marginBottom - marginTop;
      this.chart.height = height;
      svg.attr('height', height);
    }

    brush.extent([
      [marginLeft, marginTop],
      [width - marginRight, height - marginBottom],
    ]);
    brushedElement.call(brush);

    if (isRangeChanged || isUpdateLegend) {
      this.updateScalesRangeDomain({}, 0, isRangeChanged || isUpdateLegend);

      clip
        .attr('width', chart._xAxisLength)
        .attr('height', chart._yAxisLength)
        .attr('x', marginLeft)
        .attr('y', marginTop);
    }
  }

  addBrushing({ chart, linesGroupWrapper } = {}) {
    // Add brushing
    const { _margin, width, height } = chart;
    const {
      left: marginLeft,
      top: marginTop,
      right: marginRight,
      bottom: marginBottom,
    } = _margin;

    const brush = this.d3
      .brush() // Add the brush feature using the this.d3.brush function
      .extent([
        [marginLeft, marginTop],
        [width - marginRight, height - marginBottom],
      ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
      .on('start', (event) => {
        this.brushstarted(event);
      })
      .on('brush', ({ sourceEvent }) => {
        if (sourceEvent) {
          this.infoTitleShow(sourceEvent);
        }
      })
      .on('end', (event) => {
        this.brushended(event); // Each time the brush selection changes, trigger the 'updateChart' function
      });

    // Add the brushing
    const brushedElement =
      chart.brushedElement ||
      linesGroupWrapper.append('g').attr('class', 'brush');

    brushedElement.call(brush);
    chart.brush = brush;
    chart.brushedElement = brushedElement;
  }

  addBrushingX({ chart } = {}) {
    // Add brushing
    const { _margin, width, height, brushedElement } = chart;
    const {
      left: marginLeft,
      top: marginTop,
      right: marginRight,
      bottom: marginBottom,
    } = _margin;

    const brush = this.d3
      .brushX() // Add the brush feature using the this.d3.brush function
      .extent([
        [marginLeft, marginTop],
        [width - marginRight, height - marginBottom],
      ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
      .on('start', ({ selection }) => {
        if (!selection) {
          this.exchangeBrushMode();
          return;
        }
        if (!this.chart.brushXLineAfter) {
          this.chart.brushedElement
            .node()
            .after(this.chart._verticalLineClickWrapper.node());
          this.chart.brushXLineAfter = true;
        }

        this.chart.brushXStart = this.chart._verticalLineClickX;
        this.chart.brushXValueStart = this.chart._verticalLineClickValue;
        this.chart.brushIndexStart = this.chart._verticalLineClickIndex;
        this.chart.svg.select('.brush .overlay').attr('cursor', 'pointer');
        this.chart.brushXEnded = false;
      })
      .on('brush', ({ sourceEvent }) => {
        if (sourceEvent) {
          this.infoTitleShow(sourceEvent);
        }
        this.chart.svg.select('.brush .overlay').attr('cursor', 'pointer');

        this.brushX(sourceEvent);
      })
      .on('end', () => {
        this.chart.brushXEnded = true;
        this.chart.brushedElement.on('.brush', null);
        this.chart.svg.select('.brush .overlay').attr('cursor', 'pointer');
      });

    brushedElement.call(brush);
    chart.brush = brush;
    chart.brushedElement = brushedElement;
  }

  brushX(sourceEvent) {
    if (!sourceEvent) {
      return;
    }

    const { layerY } = sourceEvent;
    const {
      brushedElement,
      brushXStart,
      brushIndexStart,
      brushXValueStart,
      brush,
      _verticalLineClickX,
    } = this.chart;
    const {
      valueX,
      value,
      index,
      clickValue,
      _scale,
      titleFormat,
      titleIntervalFormat,
    } = this.getXIndexValueByLayerX(sourceEvent);

    const selection =
      valueX >= brushXStart
        ? [[brushXStart], [valueX]]
        : [[valueX], [brushXStart]];
    const selectionIndexes =
      index > brushIndexStart
        ? [brushIndexStart, index]
        : [index, brushIndexStart];
    const selectionXValues =
      brushXValueStart > value
        ? [brushXValueStart, value]
        : [value, brushXValueStart];

    if (_verticalLineClickX !== valueX) {
      const [xValueBegin, xValueEnd] = selectionXValues;
      const [indexBegin, indexEnd] = selectionIndexes;
      const selectionValues = this.selectionValuesCalculate({
        indexBegin,
        indexEnd,
        xValueBegin,
        xValueEnd,
      });

      this.moveVerticalClickLine({
        value,
        brushXValueStart,
        index,
        clickValue,
        _scale,
        titleFormat,
        titleIntervalFormat,
        layerY,
        duration: 0,
        selectionValues,
        selectionIndexes,
      });
    }
    
    brushedElement.call(brush.move, selection);
  }

  currentValuesCalculate({
    xValue
  } = {}) {
    const chart = this.chart;
    const { charts } = chart;
    const defaultFormat = ({ data, index }) => data[index]['y'];
  
    return charts.reduce((accum, chartItem) => {
      const {
        data,
        label = 'неизвестно:',
        legendValueFormat = defaultFormat,
        isSelectionValues = true,
      } = chartItem;
  
      if (!isSelectionValues) {
        return accum;
      }
  
      // Поиск ближайшей точки к xValue
      const nearestPointIndex = findNearestLeftPointIndex(data, xValue);
      const dataPoint = data[nearestPointIndex];
  
      if (dataPoint) {
        const selectionValue = legendValueFormat({
          data: [{ y: dataPoint.y }],
          index: 0,
        });
        accum.push(`${label} ${selectionValue}`);
      } else {
        accum.push(`${label} -`);
      }
  
      return accum;
    }, []);
  
    function findNearestLeftPointIndex(data, targetX) {
      // поиск ближайшего индекса слева по дате
      var nearestIndex = -1;
  
      for (var i = 0; i < data.length; i++) {
        if (data[i].x <= targetX) {
          nearestIndex = i;
        } else {
          break;
        }
      }
  
      return nearestIndex;
    }
  }
  

  selectionValuesCalculate({
    indexBegin,
    indexEnd,
    xValueBegin,
    xValueEnd,
  } = {}) {
    const chart = this.chart;
    const { charts } = chart;
    const defaultFormat = ({ data, index }) => data[index]['y'];

    return charts.reduce((accum, chartItem) => {
      const {
        data,
        label = 'неизвестно:',
        legendValueFormat = defaultFormat,
        selectionValuesFunction,
        isSelectionValues = true,
      } = chartItem;

      if (!isSelectionValues || selectionValuesFunction === null) {
        return accum;
      }

      if (selectionValuesFunction) {
        const selectionValue = selectionValuesFunction({
          chart,
          data,
          indexBegin,
          indexEnd,
          xValueBegin,
          xValueEnd,
          label,
          legendValueFormat,
        });
        if (selectionValue) {
          accum.push(selectionValue);
          return accum;
        }
      }

      const dataBegin = data[indexBegin] || {};
      const dataEnd = data[indexEnd] || {};
      const begin = dataBegin.y ?? null;
      const end = dataEnd.y ?? null;

      const lvInBegin = dataBegin.lvIn || 0;
      const lvInEnd = dataEnd.lvIn || 0;
      const lvIn = lvInEnd - lvInBegin;
      const lvOutBegin = dataBegin.lvOut || 0;
      const lvOutEnd = dataEnd.lvOut || 0;
      const lvOut = lvOutEnd - lvOutBegin;

      if (begin !== null && end !== null) {
        const selectionValue = legendValueFormat({
          data: [{ y: end - begin + lvIn - lvOut }],
          index: 0,
        });
        accum.push(`${label} ${selectionValue}`);
      } else {
        accum.push(`${label} -`);
      }

      return accum;
    }, []);
  }

  addMouseEvents(svg, chart) {
    this.dragPointer = {};

    chart._mousemoveMode = 'zoom';

    svg.on('mousedown', (event) => {
      const { layerX, layerY, which } = event;
      
      if (which === 3) {
        // ПКМ
        this.dragPointer = { layerX, layerY, isDrag: true };
      }

      if (this.chart.brushXEnded && which === 1) {
        // ЛКМ
        this.exchangeBrushMode();
        this.moveVerticalClickLine();
      }
    });

    svg.on('mouseup', () => {
      // ПКМ
      this.dragPointer = {};
    });

    svg.on('mousemove', (event) => {
      if (this.dragPointer.isDrag) {
        this.dragmove(event);
      }
      this.infoTitleShow(event);

      const { layerX } = event;
      if (
        this.chart._verticalLineClickX >= layerX - 2 &&
        this.chart._verticalLineClickX <= layerX + 2
      ) {
        if (this.chart._mousemoveMode !== 'select') {
          this.chart._mousemoveMode = 'select';
          this.chart.brushedElement.on('.brush', null);
          this.addBrushingX(this);
        }
      } else {
        if (
          this.chart._mousemoveMode !== 'zoom' &&
          !this.chart.brushXLineAfter
        ) {
          this.exchangeBrushMode();
        }
      }

      if (this.chart._mousemoveMode === 'select') {
        this.chart.svg.select('.brush .overlay').attr('cursor', 'pointer');
      }

      if (this.chart._mousemoveMode === 'zoom') {
        this.chart.svg.select('.brush .overlay').attr('cursor', 'crosshair');
      }
    });

    svg.on('mouseleave', () => {
      this.infoTitleShow();
    });

    svg.on('dblclick', (event) => {
      this.showVerticalClickLine(event);
    });
  }

  exchangeBrushMode() {
    this.chart.brushedElement.on('.brush', null);
    if (this.chart.brushXLineAfter) {
      this.chart.brushedElement
        .node()
        .before(this.chart._verticalLineClickWrapper.node());
      this.chart.brushXLineAfter = false;
    }
    this.addBrushing(this);
    this.chart.brushedElement.call(this.chart.brush.move, null); // This remove the grey brush area as soon as the selection has been done
    this.chart.brushXEnded = false;
    this.chart._mousemoveMode = 'zoom';
  }

  showVerticalClickLine(event) {
    if (!event) {
      return;
    }

    const { layerX, layerY } = event;
    const { _margin, _xAxisLength, _yAxisLength, _xAxisLinks, _infoTitle } =
      this.chart;

    if (
      layerX < _margin.left ||
      layerX > _margin.left + _xAxisLength ||
      layerY < _margin.top ||
      layerY > _margin.top + _yAxisLength
    ) {
      return;
    }

    const { value, index, clickValue, _scale, titleFormat } =
      this.getXIndexValueByLayerX(event);

    this.moveVerticalClickLine({
      value,
      index,
      clickValue,
      _scale,
      titleFormat,
      layerY,
    });
  }

  getXIndexValueByLayerX(event) {
    const { layerX } = event;
    const { _margin, _xAxisLinks, charts } = this.chart;
    const posX = layerX - _margin.left;

    const [xAxisLink] = _xAxisLinks;

    if (!xAxisLink) {
      return {};
    }

    const { _scale, titleFormat, titleIntervalFormat } = xAxisLink;

    const clickValue = _scale.invert(posX);
    const { data = [] } = charts[0] || {};
    const { index, value } = this.findIndexForData(data, clickValue);
    const valueX = _margin.left + _scale(value);

    return {
      valueX,
      value,
      index,
      clickValue,
      _scale,
      titleFormat,
      titleIntervalFormat,
    };
  }

  updateTrackOnMap(index, value, selectionIndexes) {
    const { chart } = this;
    const {
      _chartAreaElement,
    } = chart;

    _chartAreaElement.node().dispatchEvent(
      new CustomEvent('graphLineMoved', {
        detail: {
          indexByFirstChart: index,
          value,
          selectionIndexes,
        },
      }),
    );
  }

  moveVerticalClickLine({
    value = this.chart._verticalLineClickValue,
    brushXValueStart = null,
    index = this.chart._verticalLineClickIndex,
    clickValue,
    _scale,
    titleFormat,
    titleIntervalFormat,
    layerY,
    duration = 0,
    selectionValues,
    selectionIndexes,
  } = {}) {
    if (value === null) {
      return;
    }

    const { chart } = this;
    const {
      _margin,
      _yAxisLength,
      _xAxisLength,
      _xAxisLinks,
      charts,
      _verticalLineClickElement,
      _verticalLineTextWrapper,
      _verticalLineRectElement,
      _verticalLineTextElement,
      _verticalLineTextPadding,
      _verticalLineTextPos,
      _chartAreaElement,
    } = chart;

    chart._verticalLineClickValue = value;

    if (!_scale) {
      const [xAxisLink] = _xAxisLinks;

      if (!xAxisLink) {
        return;
      }

      _scale = xAxisLink._scale;
      titleFormat = xAxisLink.titleFormat;
      titleIntervalFormat = xAxisLink.titleIntervalFormat;
    }

    const x = _margin.left + _scale(value);
    const y1 = _margin.top;
    const y2 = _margin.top + _yAxisLength;

    _verticalLineClickElement
      .transition()
      .duration(duration)
      .attr('x1', x)
      .attr('y1', y1)
      .attr('x2', x)
      .attr('y2', y2);

    chart._verticalLineClickX = x;
    chart._verticalLineClickIndex = index;

    let showValue;
    if (brushXValueStart === null) {
      showValue = titleFormat ? titleFormat(value) : value;
    } else {
      const showValues =
        brushXValueStart < value
          ? [brushXValueStart, value]
          : [value, brushXValueStart];
      const [valueXStart, valueXEnd] = showValues;
      const intrval = titleIntervalFormat
        ? titleIntervalFormat(valueXEnd - valueXStart)
        : valueXEnd - valueXStart;
      const showValuesFormatted = titleFormat
        ? showValues.map((val) => titleFormat(val))
        : showValues;
      showValue = `<tspan x="3" dy="0">${showValuesFormatted.join(
        ' - ',
      )}</tspan><tspan x="3" dy="1em">Интервал: ${intrval}</tspan>`;
    }

    const verticalLineTextNode = _verticalLineTextElement.node();

    if (verticalLineTextNode.textContent !== showValue) {
      if (selectionValues) {
        _verticalLineTextElement.html(
          `${showValue}${selectionValues
            .map((t) => `<tspan x="3" dy="1em">${t}</tspan>`)
            .join('')}`,
        );
      } else {
        _verticalLineTextElement.text(showValue);
      }

      const { width: textW, height: textH } = verticalLineTextNode.getBBox();

      _verticalLineRectElement
        .attr('width', textW + 2 * _verticalLineTextPadding)
        .attr('height', textH + 2 * _verticalLineTextPadding);
    }

    const { width: textWrapW, height: textWrapH } = _verticalLineTextWrapper
      .node()
      .getBBox();

    const textWrapY = layerY || _verticalLineTextPos.y;
    const textWrapX = x;

    if (textWrapY + textWrapH > _margin.top + _yAxisLength) {
      _verticalLineTextPos.y = _margin.top + _yAxisLength - textWrapH;
    } else {
      _verticalLineTextPos.y = textWrapY;
    }

    if (textWrapX + textWrapW > _margin.left + _xAxisLength) {
      let newX = _margin.left + _xAxisLength - textWrapW;
      if (newX - textWrapW < x) {
        newX = x - textWrapW;
      }
      _verticalLineTextPos.x = newX;
    } else {
      _verticalLineTextPos.x = x;
    }

    _verticalLineTextWrapper
      .transition()
      .duration(duration)
      .attr(
        'transform',
        `translate(${_verticalLineTextPos.x}, ${_verticalLineTextPos.y})`,
      ); // сдвиг текста вправо и вниз

    this.updateTrackOnMap(index, value, selectionIndexes)

    if (index !== null) {
      charts.forEach((chartItem) => {
        const {
          data = [],
          _legendValueElem,
          legendValueFormat,
          legendValueSymbolsCount,
        } = chartItem;
        if (!_legendValueElem) {
          return;
        }

        let result = ' -';

        if (legendValueFormat) {
          result = legendValueFormat({
            data,
            index,
            valueX: value,
            clickValueX: clickValue,
          });
        } else {
          const valueItem = data[index] ?? {};
          result = valueItem.y ?? ' -';
        }

        _legendValueElem.html(
          this.getValueVithNbsp(result, legendValueSymbolsCount),
        );
      });
    }
  }

  findIndexForData(data, clickValue) {
    const defaultResult = {
      index: null,
      value: null,
    };

    if (!data.length) {
      return defaultResult;
    }

    const N = data.length;
    let m = Math.floor(N / 2);
    let i = 0;
    let j = N - 1;

    while (m > -1 && data[m]['x'] !== clickValue && i <= j) {
      if (clickValue > data[m]['x']) {
        i = m + 1;
      } else {
        j = m - 1;
      }

      m = Math.floor((i + j) / 2);
    }

    const preliminarily = i > j ? j : m + i;
    const index = preliminarily > -1 ? preliminarily : 0;

    return {
      index,
      value: data[index]['x'],
    };
  }

  infoTitleShow(event) {
    if (!event) {
      this.chart._infoTitle.attr('hidden', '1');
      return;
    }

    const { layerX, layerY, clientX, clientY } = event;
    const { _margin, _xAxisLength, _yAxisLength, _xAxisLinks, _infoTitle } =
      this.chart;

    if (
      layerX < _margin.left ||
      layerX > _margin.left + _xAxisLength ||
      layerY < _margin.top ||
      layerY > _margin.top + _yAxisLength
    ) {
      _infoTitle.attr('hidden', '1');
      return;
    }

    const posX = layerX - _margin.left;

    const rows = [];
    _xAxisLinks.forEach((xAxisLink) => {
      const { titleFormat, _scale } = xAxisLink;
      const value = _scale.invert(posX);

      const showValue = titleFormat ? titleFormat(value) : value;

      rows.push(showValue);
    });

    _infoTitle
      .attr('hidden', null)
      .style('top', `${layerY + 5}px`)
      .style('left', `${layerX + 5}px`)
      .html(rows.join('<br>'));
  }

  dragmove(event) {
    const { layerX, layerY } = event;
    const dragPointer = this.dragPointer;
    const { layerX: prevX, layerY: prevY } = dragPointer;
    const { _yAxisLength, _xAxisLength } = this.chart;

    const xMin = prevX - layerX;
    const yMax = prevY - layerY;

    const domainForScales = {
      xMin,
      xMax: xMin + _xAxisLength,
      yMax,
      yMin: yMax + _yAxisLength,
    };

    dragPointer.layerX = layerX;
    dragPointer.layerY = layerY;

    this.updateScalesRangeDomain(domainForScales, 0);
  }

  zoom(svg) {
    svg.on('mousewheel', (event) => this.zoomed(event));
  }

  zoomed(event) {
    const { width, _margin: margin } = this.chart;

    const coefficient = (width - margin.left - margin.right) / 30;

    if (event.wheelDelta > 0) {
      this.brushended(
        {
          selection: [
            [margin.left + coefficient],
            [width - margin.right - coefficient],
          ],
        },
        0,
      );
    } else {
      this.brushended(
        {
          selection: [
            [margin.left - coefficient],
            [width - margin.right + coefficient],
          ],
        },
        0,
      );
    }
  }

  removeEventListeners() {
    const { brushedElement, svg, _legendElements } = this.chart;
    brushedElement.on('.brush', null);
    svg.on('dblclick', () => void 0);
    svg.on('mousedown', () => void 0);
    svg.on('mouseup', () => void 0);
    svg.on('mousemove', () => void 0);
    svg.on('mouseleave', () => void 0);
    _legendElements.on('click', () => void 0);
  }

  idled() {
    this.idleTimeout = null;
  }

  brushstarted({ selection }) {
    if (!selection) {
      return;
    }

    const [[xStart, yStart]] = selection;
    this.brushStart = { xStart, yStart };
  }

  brushended({ selection }, duration = 1000) {
    // What are the selected boundaries?
    const minBrush = 10;
    const { chart } = this;
    const { _margin: margin, brushedElement, brush } = chart;
    const { left: marginLeft, top: marginTop } = margin;
    const { xStart, yStart } = this.brushStart || {};
    this.brushStart = {};

    // If no selection, back to initial coordinate. Otherwise, update X axis domain
    if (!selection) {
      if (!this.idleTimeout) {
        this.idleTimeout = setTimeout(() => this.idled(), 350); // This allows to wait a little bit
      }
      return;
    }

    brushedElement.call(brush.move, null); // This remove the grey brush area as soon as the selection has been done

    const [[x1, y1 = null], [x2, y2 = null]] = selection;
    if (x1 < xStart && y1 < yStart) {
      this.resetZoom();
      return;
    }

    if (
      Math.abs(x1 + x2 - 2 * xStart) < minBrush &&
      Math.abs(y1 + y2 - 2 * yStart < minBrush)
    ) {
      return;
    }

    const domainForScales = {
      xMin: x1 - marginLeft,
      xMax: x2 - marginLeft,
      yMax: y1 !== null ? y1 - marginTop : null,
      yMin: y2 !== null ? y2 - marginTop : null,
    };

    this.updateScalesRangeDomain(domainForScales, duration);
  }

  updateScalesRangeDomain(
    domainForScales,
    duration = 0,
    isRangeChanged = false,
  ) {
    const {
      xMin = null,
      xMax = null,
      yMax = null,
      yMin = null,
    } = domainForScales;
    const { chart } = this;
    const { axes, charts, _xAxisLength, _yAxisLength } = chart;

    if (!isRangeChanged) {
      this.isZoomed = true;
    }

    // новый domain к осям
    axes.forEach((axis) => {
      const { _scale: scale, _mode } = axis;

      if (_mode === 'x' && (xMin !== null || isRangeChanged)) {
        if (xMin !== null) {
          const domain = [scale.invert(xMin), scale.invert(xMax)];
          scale.domain(domain);
        }

        if (isRangeChanged) {
          scale.range([0, _xAxisLength]);
          this.removeAxis(axis);
          this.createAxis({ chart, axis });
        }

        // обновление осей
        // this.removeAxis(axis);
        // this.createAxis({ chart, axis });
        this.axisUpdate(axis, duration);
      }

      if (_mode === 'y' && (yMax !== null || isRangeChanged)) {
        if (yMax !== null) {
          const domain = [scale.invert(yMax), scale.invert(yMin)];
          scale.domain(domain);
        }

        if (isRangeChanged) {
          scale.range([0, _yAxisLength]);
          this.removeAxis(axis);
          this.createAxis({ chart, axis });
        }

        // обновление осей
        // this.removeAxis(axis);
        // this.createAxis({ chart, axis });
        this.axisUpdate(axis, duration);
      }
    });

    const now = Date.now();
    if (now < this.lastChartsUpdateUnix + this.chartsUpdateInterval) {
      clearTimeout(this.thinOutPositionsAndDrawTimer);
    }

    this.thinOutPositionsAndDrawTimer = setTimeout(() => {
      this.updateChartsHandler(duration, domainForScales);
    }, this.chartsUpdateInterval);

    this.lastChartsUpdateUnix = now;
  }

  updateChartsHandler(duration, domainForScales) {
    const { chart } = this;
    const { charts } = chart;

    const chartsPositionsIndexes = this.thinOutPositions(domainForScales);
    // новый domain к графикам
    // this.thinOutPositionsNew(charts[0].data, domainForScales);
    charts.forEach((chartItem) => {
      this.chartItemUpdate(chartItem, duration / 4, chartsPositionsIndexes);
    });

    // переместить вертикальную линию
    this.moveVerticalClickLine({ duration });
  }

  getDrawInterval(data, domainForScales) {
    const { _xAxisLinks, _margin } = this.chart;
    const xAxis = _xAxisLinks[0];
    if (domainForScales && domainForScales.xMin && domainForScales.xMax) {
      const { _scale: xScale } = xAxis;
      const { xMin, xMax } = domainForScales;

      const min = data.findIndex((d) => xScale(d?.x) >= xMin + _margin.left);
      const max = data.findIndex((d) => xScale(d?.x) >= xMax + _margin.left);

      return { min, max };
    } else {
      return { min: xAxis.min, max: xAxis.max };
    }
  }

  thinOutPositionsNew(data, domainForScales) {
    const interval = this.getDrawInterval(data, domainForScales);
  }

  thinOutPositions(domainForScales) {
    if (!this.config.charts.length) return []
    
    const chartData = this.config.charts[0].data;
    
    if (!chartData?.length) return []

    const requiredPositions = this.config.requiredPositions;

    const { charts, width, height, axes, _xAxisLength, _yAxisLength } = this.chart;

    const axesDateTime = axes.find(elem => elem.id === 'xDateTime')

    const domainDateTime = axesDateTime._scale.domain()

    const {
      _xAxisLink: xAxis = {},
      _margin: margin = {},
    } = charts.find((chart) => !!chart._xAxisLink);


    const { _scale: xScale } = xAxis;

    let drawInterval = {};

    let minPosition = 0;
    let maxPosition = chartData.length - 1;

    let prevPositionDrawTime = 0;
    if (domainForScales) {
      const {
        xMin = null,
        xMax = null,
        yMax = null,
        yMin = null,
      } = domainForScales;

      const min = chartData.findIndex(
        (d) => xScale(d?.x) >= xMin + margin.left,
      );
      const max = chartData.findIndex(
        (d) => xScale(d?.x) >= xMax + margin.left,
      );

      if (min > 0) minPosition = min - 1;

      if (max > -1 && max !== chartData.length - 1) maxPosition = max + 2;

      drawInterval = this.getIntervalDrawPoses(
        { min: domainDateTime[0], max: domainDateTime[1] },
        { width: _xAxisLength, height: _yAxisLength },
      );

      prevPositionDrawTime = new Date(chartData[minPosition].x).getTime();
    } else {
      const { min, max } = xAxis;

      drawInterval = this.getIntervalDrawPoses({ min: domainDateTime[0], max: domainDateTime[1] }, { width: _xAxisLength, height: _yAxisLength });

      prevPositionDrawTime = new Date(min).getTime();
    }

    let nextpositionTime = 0;
    const minDomainDate = getUnixByDate(domainDateTime[0]);
    const maxDomainDate = getUnixByDate(domainDateTime[1]);

    let curCluster = {
      firstPosId: -1,
      maxPosVal: -1,
      maxPosId: -1,
      minPosVal: -1,
      minPosPosId: -1,
    };
    let gotFirstElem = false;
    const thinOutPositionsIndexs = [];

    let curClusterStartDate = minDomainDate
    let nextClusterStartDate = minDomainDate + drawInterval

    for (let i = minPosition; i < maxPosition; i++) {

      const d = chartData[i];

      if (d.x < minDomainDate || d.x > maxDomainDate) { // отображение только видимых обьектов
        continue
      } else {
        if (!gotFirstElem) { // добавляем первый обьект в области видимости
          curCluster = {
            firstPosId: i,
            maxPosVal: chartData[i].y,
            maxPosId: i,
            minPosVal: chartData[i].y,
            minPosId: i,
          };
          gotFirstElem = true
        }
      }

      if (drawInterval < 5000) {
        thinOutPositionsIndexs.push(i)
        continue
      }

      const dDate = getUnixByDate(d.x);

      if (curClusterStartDate <= dDate && dDate < nextClusterStartDate) { // значение не первое и не последнее для кластера. Вычисляются макс и мин
        if (d.y > curCluster.maxPosVal) {
          curCluster.maxPosVal = d.y; 
          curCluster.maxPosId = i
        }
        if (d.y < curCluster.minPosVal) {
          curCluster.minPosVal = d.y; 
          curCluster.minPosId = i
        }
      } else { // определяется первое и последнее значение у двух кластеров
        curClusterStartDate = nextClusterStartDate
        nextClusterStartDate = curClusterStartDate + drawInterval
        const tempArr = []
        tempArr.push(curCluster.firstPosId)
        tempArr.push(curCluster.maxPosId)
        tempArr.push(curCluster.minPosId)
        tempArr.push(i-1); // last pos в предыдущем кластере
        const tempArr2 = Array.from(new Set(tempArr)).sort((a, b) => a - b);
        tempArr.length > 0 ? thinOutPositionsIndexs.push(...tempArr2) : '';

        curCluster = {
          firstPosId: i,
          maxPosVal: chartData[i].y,
          maxPosId: i,
          minPosVal: chartData[i].y,
          minPosId: i,
        };
      }
    }

    if (thinOutPositionsIndexs.length > 0) { // добавляем чуть больше точек на границах чтобы небыло пробелов
      const lastElem = Math.min(thinOutPositionsIndexs[thinOutPositionsIndexs.length-1] + 1, maxPosition)
      thinOutPositionsIndexs.push(lastElem)
      const firstElem = Math.max(thinOutPositionsIndexs[0] - 1, minPosition)
      thinOutPositionsIndexs.unshift(firstElem)
    }

    return thinOutPositionsIndexs;
  }

  axisUpdate(axis, duration) {
    // обновление оси

    const { _yAxisLength, _xAxisLength } = this.chart;
    const {
      _scale: scale,
      _mode,
      _orientTextMethod: orientTextMethod,
      _lineElement: axisElement,
      tickFormat,
      _orientScale,
      _orientCount,
    } = axis;

    if (tickFormat) {
      axisElement
        .transition()
        .duration(duration)
        .call(this.d3[orientTextMethod](scale).tickFormat(tickFormat));
    } else {
      axisElement
        .transition()
        .duration(duration)
        .call(this.d3[orientTextMethod](scale));
    }

    if (_mode === 'x') {
      // создаем набор вертикальных линий для сетки
      axis._gridElement = this.addGreedX(
        axis,
        axisElement,
        _yAxisLength,
        _orientScale,
      );
      this.rotateAxisTickText(axisElement);
    }

    if (_mode === 'y') {
      const axesCountOffset = (_orientCount - 1) * 60;

      // создаем набор вертикальных линий для сетки
      axis._gridElement = this.addGreedY(
        axis,
        axisElement,
        _xAxisLength,
        _orientScale,
        axesCountOffset,
      );
    }
  }

  async chartItemUpdate(chartItem, duration = 0, filteredDataIndexs = []) {
    const {
      left: marginLeft = 0,
      top: marginTop = 0,
      right: marginRight = 0,
      bottom: marginBottom = 0,
    } = this.chart?._margin;
    const {
      _lineElement: lineElement,
      _areaElement: areaElement,
      _xAxisLink: xAxis = {},
      _yAxisLink: yAxis = {},
      isLine = true,
      isAreaFull,
      isDot,
      isBar,
      isBezierCurve = false,
      _textDataElement,
      _textData,
    } = chartItem;

    if (!isLine && !isBar && !isDot && !isAreaFull) {
      return;
    }

    const { _scale: xScale } = xAxis;
    const { _scale: yScale } = yAxis;

    const filteredData = [];

    for (let i = 0; i < filteredDataIndexs.length; i++) {
      filteredData.push(chartItem.data[filteredDataIndexs[i]]);
    }

    await new Promise((res) => setTimeout(res, 0));

    if (lineElement && xAxis && yAxis) {
      lineElement.data([filteredData]);

      const line = this.d3.line();
      line.defined(
        (d) => d && d.y !== null && d.y !== undefined && !isNaN(d.y),
      );
      line.x((d) => {
        return !d || d.y === null || d.y === undefined || isNaN(d.y)
          ? ''
          : xScale(d.x) + marginLeft;
      });
      line.y((d) => {
        return !d || d.y === null || d.y === undefined || isNaN(d.y)
          ? ''
          : yScale(d.y) + marginTop;
      });

      if (isBezierCurve) line.curve(this.d3.curveCatmullRom.alpha(0.5));

      lineElement
        // .transition()
        // .duration(duration)
        .attr('d', line);
    }

    if (isBar) {
      const barWidth = 10;
      lineElement
        // .transition()
        // .duration(duration)
        .attr('x', (d) => {
          if (!d || d.y === null || d.y === undefined || isNaN(d.y)) {
            return 0;
          }
          return xScale(d.x) + marginLeft - barWidth / 2;
        })
        .attr('y', (d) =>
          !d || d.y === null || d.y === undefined || isNaN(d.y)
            ? 0
            : yScale(d.y) + marginTop,
        )
        .attr('height', (d) => {
          return yScale(0) - yScale(Number.isNaN(d.y) ? 0 : d.y);
        })
        .attr('width', (d) => barWidth);

      return;
    }

    if (areaElement && xAxis && yAxis) {
      areaElement
        // .transition()
        // .duration(duration)
        .attr('d', this.getScaleArea(this.chart, xScale));
    }
    
    if (_textDataElement) {
      _textDataElement
        .selectAll('.chart-text-data-item')
        // .transition()
        // .duration(duration)
        .attr('transform', (d, i, elements) => {
          const itemGroup = elements[i];
          const index = itemGroup.dataset.index;

          if (!(index > -1)) {
            return '';
          }

          const { x, y, textPosition = 'top' } = _textData[index];
          const shift = this.getTextDataShift(itemGroup, textPosition);
          const translateX = marginLeft + xScale(x) - shift.x;
          const translateY = marginTop + yScale(y) - shift.y;

          const brushNode = this.chart.brushedElement.node();

          const { width, height } = brushNode.getBoundingClientRect();

          const xMin = marginLeft;
          const xMax = marginLeft + width;
          const yMin = marginTop;
          const yMax = marginTop + height;

          // Если элемент выходит за границы, прячем элемент
          if (translateX < xMin || translateX > xMax) {
            return 'translate(-9999, -9999)';
          }

          return `translate(${translateX}, ${translateY})`; // сдвиг оси вправо и вниз;)
        });
    }
  }

  getIntervalDrawPoses(minMaxDates, chartSize) {
    const { min, max } = minMaxDates;
    const { width, height } = chartSize;

    const drawInterval =
      (getUnixByDate(max) - getUnixByDate(min)) / (width);
    return drawInterval;
  }

  resetZoom() {
    const { chart } = this;
    const duration = 200;

    this.isZoomed = false;

    chart.axes.forEach((axis) => {
      const { _mode: mode, _max: max, _min: min, _scale: scale } = axis;
      const domain = mode === 'x' ? [min, max] : [max, min];
      scale.domain(domain);
      this.axisUpdate(axis, duration);
    });

    const chartsPositionsIndexes = this.thinOutPositions();

    chart.charts.forEach((chartItem) => {
      this.chartItemUpdate(chartItem, duration, chartsPositionsIndexes);
    });

    // переместить вертикальную линию
    this.moveVerticalClickLine({ duration });
  }

  addGreedY(axis, axisElement, xAxisLength, orientScale, offset = 0) {
    const { isGrids = true } = axis;
    if (!isGrids) {
      return;
    }

    const x2 = orientScale === 'left' ? xAxisLength : -xAxisLength;

    return axisElement
      .selectAll('g.y-axis g.tick:not(.grid-added)')
      .classed('grid-added', true)
      .append('line')
      .classed('grid-line', true)
      .style('stroke', '#000')
      .style('shape-rendering', 'crispedges')
      .style('stroke-opacity', '0.2')
      .attr('x1', offset)
      .attr('y1', 0)
      .attr('x2', x2 + offset)
      .attr('y2', 0)
      .filter((d) => d === 0)
      .style('stroke-opacity', '0.7')
      .style('stroke-width', '1.5');
  }

  addGreedX(axis, axisElement, yAxisLength, orientScale) {
    const { isGreed = true } = axis;
    if (!isGreed) {
      return;
    }

    const y2 = orientScale === 'bottom' ? -yAxisLength : yAxisLength;

    // return axisElements.selectAll("g.x-axis g.tick:not(.grid-added)")
    return axisElement
      .selectAll('g.tick:not(.grid-added)')
      .classed('grid-added', true)
      .append('line') // добавляем линию
      .classed('grid-line', true) // добавляем класс
      .style('stroke', '#000')
      .style('shape-rendering', 'crispedges')
      .style('stroke-opacity', '0.2')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', y2);
  }

  createChartObject(config) {
    const chart = {
      margin: {},
      charts: [],
      axes: [],
    };

    for (let key in config) {
      switch (key) {
        case 'margin':
          chart[key] = this.copyObjectValues(config[key]);
          break;
        case 'charts':
        case 'axes':
          chart[key] = config[key].map((values) =>
            this.copyObjectValues(values),
          );
          break;
        default:
          chart[key] = config[key];
      }
    }

    const {
      left: marginLeft = 0,
      right: marginRight = 0,
      bottom: marginBottom = 0,
      top: marginTop = 0,
    } = config.margin || {};

    chart._margin = {
      left: marginLeft,
      right: marginRight,
      bottom: marginBottom,
      top: marginTop,
    };

    chart._xAxisLinks = [];

    return chart;
  }

  copyObjectValues(object) {
    const copy = {};
    for (let key in object) {
      copy[key] = object[key];
    }
    return copy;
  }

  setInterpolation({ chart, axis, charts, yAxisLength, xAxisLength } = {}) {
    let { min = null, max = null, id: axisId, isTime, _mode: mode } = axis;

    if (min === null || max === null) {
      const items = charts.filter(
        (chartItem) =>
          chartItem[`${mode}Axis`] === axisId && chartItem.type !== 'areaFull',
      );

      if (min === null) {
        min = this.d3.min(
          items.map((item) =>
            this.d3.min(item.data, (d) => (d ? d[mode] : undefined)),
          ),
        );
      }

      if (max === null) {
        max = this.d3.max(
          items.map((item) =>
            this.d3.max(item.data, (d) => (d ? d[mode] : undefined)),
          ),
        );
      }

      if (mode === 'y') {
        const range = max - min;
        const expansion = (range * this.expansionRangePercent) / 100;
        min -= expansion;
        max += expansion;
      }

      axis.min = min;
      axis.max = max;
    }

    const method = isTime ? 'scaleTime' : 'scaleLinear';
    const domain = mode === 'x' ? [min, max] : [max, min];
    const range = mode === 'x' ? [0, xAxisLength] : [0, yAxisLength];

    axis._scale = this.d3[method]().domain(domain).range(range);

    axis._min = min;
    axis._max = max;
    axis._method = method;

    if (mode === 'x') {
      chart._xAxisLinks.push(axis);
    }
  }

  createAxes({ chart } = {}) {
    const { charts = [], axes = [], _xAxisLength, _yAxisLength } = chart;

    axes.forEach((axis) => {
      if (axis._axis) return;

      this.setInterpolation({
        chart,
        axis,
        charts,
        xAxisLength: _xAxisLength,
        yAxisLength: _yAxisLength,
      });

      this.createAxis({ chart, axis });
    });
  }

  removeAxis(axis) {
    axis._axisElements.remove();
  }

  createAxis({ chart, axis } = {}) {
    const {
      _mode: mode,
      ticks = null,
      tickFormat,
      _orientScale,
      _scale,
      label,
      labelTextSize = '12px',
      orientText,
      orientScale,
      isTicks = true,
      isGrids = true,
      _orientCount,
    } = axis;

    const {
      _margin: margin,
      height,
      _xAxisLength,
      _yAxisLength,
      _axesWrapperElement: axesWrapper,
    } = chart;

    const orientTextMethod = this.getOrientTextMethod({ mode, orientText });
    const _axis = this.d3[orientTextMethod]().scale(_scale);
    if (isTicks && ticks !== null) {
      _axis.ticks(ticks);
    }
    if (!isTicks) {
      _axis.ticks(0);
    }
    if (tickFormat) {
      _axis.tickFormat(tickFormat);
    }

    let axisElement;
    let gridElement;
    let labelElement;
    let axisElements;

    // отрисовка оси
    if (mode === 'x') {
      const translateTop =
        _orientScale === 'bottom'
          ? height - margin.bottom
          : height - margin.bottom - _yAxisLength;

      axisElements = axesWrapper
        .append('g')
        .attr('transform', `translate(${margin.left}, ${translateTop})`); // сдвиг оси вправо и вниз

      // вставка оси с подписями тиков
      axisElement = axisElements
        .append('g')
        .attr('class', 'x-axis')
        .call(_axis);

      this.rotateAxisTickText(axisElement);

      // создаем набор вертикальных линий для сетки
      if (isGrids) {
        gridElement = this.addGreedX(
          _axis,
          axisElement,
          _yAxisLength,
          _orientScale,
        );
      }

      // подпись оси
      labelElement = axisElements
        .append('text')
        .attr('class', 'not-excel')
        .style('font-size', labelTextSize)
        .text(label);

      this.setAxisLabelPosition({
        labelElement,
        axisElement,
        _orientScale,
        _yAxisLength,
        _xAxisLength,
      });
    } else {
      const axesCountOffset = (_orientCount - 1) * 60;

      const translateLeft =
        orientScale === 'right'
          ? margin.left + _xAxisLength + axesCountOffset
          : margin.left - axesCountOffset;

      axisElements = axesWrapper
        .append('g')
        .attr('transform', `translate(${translateLeft}, ${margin.top})`); // сдвиг оси вниз и вправо

      // вставка оси с подписями тиков
      axisElement = axisElements
        .append('g')
        .attr('class', 'y-axis')
        .call(_axis);

      // рисуем горизонтальные линии сетки
      if (isGrids) {
        gridElement = this.addGreedY(
          _axis,
          axisElement,
          _xAxisLength,
          _orientScale,
          axesCountOffset,
        );
      }

      labelElement = axisElements
        .append('text')
        .attr('class', 'not-excel')
        .style('font-size', labelTextSize)
        .text(label);

      // позиционируем надпись названия оси
      this.setAxisLabelPosition({
        labelElement,
        axisElement,
        _orientScale,
        _yAxisLength,
        _xAxisLength,
      });
      this.addTooltip(labelElement, axis.tooltipHtml);
    }

    axis._axis = _axis;
    axis._lineElement = axisElement;
    axis._gridElement = gridElement;
    axis._labelElement = labelElement;
    axis._axisElements = axisElements;
    axis._orientTextMethod = orientTextMethod;
  }

  rotateAxisTickText(axisElement) {
    axisElement
      .selectAll('.tick')
      .select('text')
      .attr('class', 'not-excel')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-25)');
  }

  getOrientTextMethod({ mode, orientText } = {}) {
    switch (orientText) {
      case undefined:
      case null:
        break;
      case 'left':
        if (mode === 'y') {
          return 'axisLeft';
        }
        return 'axisBottom';
      case 'right':
        if (mode === 'y') {
          return 'axisRight';
        }
        return 'axisLeft';
      case 'bottom':
        if (mode === 'x') {
          return 'axisBottom';
        }
        return 'axisLeft';
      case 'top':
        if (mode === 'x') {
          return 'axisTop';
        }
        return 'axisLeft';
    }

    return mode === 'x' ? 'axisBottom' : 'axisLeft';
  }

  setAxisLabelPosition({
    labelElement,
    axisElement,
    _orientScale,
    _yAxisLength,
    _xAxisLength,
  }) {
    if (!labelElement) {
      return;
    }

    const { width: labelW, height: labelH } = labelElement.node().getBBox();
    const tickNode = axisElement.node();
    const {
      width: tickW,
      height: tickH,
      x: tickX,
      y: tickY,
    } = tickNode ? tickNode.getBBox() : {};

    switch (_orientScale) {
      case 'bottom':
        labelElement
          .attr('x', _xAxisLength / 2 - labelW / 2)
          .attr('y', labelH + tickY + tickH);
        break;
      case 'top':
        labelElement.attr('x', _xAxisLength / 2 - labelW / 2).attr('y', tickX);
        break;

      case 'left':
        const labelLeftMargin = 15;
        const yLeft = _yAxisLength / 2;
        const xLeft = tickX - labelW / 2 - labelLeftMargin;
        labelElement
          .attr('x', xLeft)
          .attr('y', yLeft)
          .attr(
            'transform',
            `rotate(-90, ${tickX - labelLeftMargin}, ${yLeft})`,
          )
          .attr('opacity', 0.6);
        break;
      case 'right':
        const labelRightMargin = 15;
        const yRight = _yAxisLength / 2;
        const xRight = tickW + tickX - labelW / 2 + labelRightMargin;
        labelElement
          .attr('x', xRight)
          .attr('y', yRight)
          .attr(
            'transform',
            `rotate(-90, ${tickW + tickX + labelRightMargin}, ${yRight})`,
          )
          .attr('opacity', 0.6);
        break;
    }
  }

  addCharts(charts, axes, margin) {
    const {
      left: marginLeft = 0,
      right: marginRight = 0,
      bottom: marginBottom = 0,
      top: marginTop = 0,
    } = margin || {};

    this.chart._margin = {
      left: marginLeft,
      right: marginRight,
      bottom: marginBottom,
      top: marginTop,
    };

    for (let index = 0; index < this.chart.axes.length; index++) {
      const axis = this.chart.axes[index];
      const currentAxis = axes.find(({ id }) => id === axis.id);
      if (!currentAxis) {
        this.removeAxis(axis);
        this.chart.axes.splice(index, 1);
        index--;
      }
    };
    axes.forEach((axis) => {
      const currentAxis = this.chart.axes.find(({ id }) => id === axis.id);
      if (!currentAxis) {
        this.chart.axes.push(this.copyObjectValues(axis));
      } else {
        currentAxis.tooltipHtml = axis.tooltipHtml;
      }
    });

    this.chart.charts.forEach((chartItem, chartIndex) => {
      const index = charts.findIndex((item) => {
        return item.name === chartItem.name;
      });

      if (index === -1) {
        this.legendHideChart(chartItem);

        this.chart.legendElements[chartItem.label].remove();

        chartItem.hidden = true;
      }
    });

    charts.forEach((chartItem, chartIndex) => {
      const index = this.chart.charts.findIndex((item) => {
        return item.label === chartItem.label;
      });

      const isChartDrawed = index > -1;

      if (isChartDrawed && this.chart.charts[index].hidden) {
        const chartItem = this.chart.charts[index];

        const forLegend = this.forLegendState[chartIndex];
        for (let key in forLegend) {
          chartItem[key] = forLegend[key];
        }

        this.addLegendItem({
          chart: this.chart,
          chartItem,
          chartItemIndex: index,
        });

        this.legendHideChart(chartItem);
        const legendElement = this.chart._legendElements
          .node()
          .querySelector(`[data-index="${index}"]`);
        if (legendElement) {
          const [strikeElement = {}] = legendElement.getElementsByClassName(
            'chart-legend-item-strike',
          );
          strikeElement.style.display = '';
        }

        chartItem.hidden = false;
      } else if (!isChartDrawed) {
        const newChartIndex = this.chart.charts.length;

        this.chart.charts.push(chartItem);

        this.addLegendItem({
          chart: this.chart,
          chartItem,
          chartItemIndex: newChartIndex,
        });

        const textData = chartItem.data.reduce((accum, item) => {
          const { text = null } = item;
          if (text !== null) {
            accum.push(item);
          }
          return accum;
        }, []);

        if (textData.length) {
          chartItem._textData = textData;
        }

        if (chartItem.isStartHidden) {
          if (chartItem.isLine) {
            chartItem.isLine = false;
          }
          if (chartItem.isDot) {
            chartItem.isDot = false;
          }
          if (chartItem.isBar) {
            chartItem.isBar = false;
          }
          if (chartItem.isAreaFull) {
            chartItem.isAreaFull = false;
          }
        }

        if (!chartItem.isStartHidden) {
          this.drawChart({
            chart: this.chart,
            chartItem,
            chartIndex: newChartIndex,
            linesGroup: this.chart.linesGroup,
          });
        }
      }
    });

    this.setOrientScaleCount(this.chart);
    this.createAxes({ chart: this.chart });
    this.resize({ width: this.chart.width - 1, height: this.chart.height - 1 });
  }

  drawChart({ chart, chartItem, chartIndex, linesGroup } = {}) {
    const {
      data = [],
      xAxis: xAxisId,
      yAxis: yAxisId,
      colorStroke = 'black',
      colorFill = 'black',
      opacity = 1,
      opacityFill = 0.3,
      label = '',
      strokeWidth = 2,
      isLine = true,
      isDot = false,
      isBar = false,
      isAreaFull,
      isBezierCurve = false,
      dotR = 3.5,
      _textData,
    } = chartItem;

    const {
      axes,
      _margin,
      dataTextSize = '14px',
      dataTextBgFill = 'white',
      dataTextColor = 'black',
      dataTextFontWeight = null,
      textDataGroup,
    } = chart;
    const axisX = axes.find((axis) => axis.id === xAxisId);
    const axisY = axes.find((axis) => axis.id === yAxisId);

    if (!(axisX && axisY)) {
      return;
    }

    const { _scale: scaleX } = axisX;
    const { _scale: scaleY } = axisY;

    chartItem._xAxisLink = axisX;
    chartItem._yAxisLink = axisY;

    chartItem._minMax = this.getMinMaxOfData(data);

    // функция, создающая по массиву точек линии
    if (isLine) {
      const line = this.d3
        .line()
        .defined((d) => {
          return d && d.y !== null && d.y !== undefined && !isNaN(d.y);
        })
        .x((d) => {
          if (!d || d.y === null || d.y === undefined || isNaN(d.y)) {
            return '';
          }
          return scaleX(d.x) + _margin.left;
        })
        .y((d) =>
          !d || d.y === null || d.y === undefined || isNaN(d.y)
            ? ''
            : scaleY(d.y) + _margin.top,
        );

      if (isBezierCurve) line.curve(this.d3.curveCatmullRom.alpha(0.5));

      chartItem._lineElement = linesGroup
        .append('path')
        .datum(data)
        .attr('data-line-index', chartIndex)
        .attr('d', line)
        .style('stroke', colorStroke)
        .style('opacity', opacity)
        .style('stroke-width', strokeWidth)
        .style('fill', 'none');

      this.addTooltip(chartItem._lineElement);
    }

    if (isDot) {
      // добавляем отметки к точкам
      chartItem._dotElement = linesGroup
        .selectAll('.dot ' + label)
        .data(data)
        .enter()
        .append('circle')
        .style('stroke', colorStroke)
        .style('fill', 'white')
        .style('opacity', opacity)
        .attr('class', 'dot ' + label)
        .attr('r', dotR)
        .attr('cx', (d) =>
          !d || d.y === null || d.y === undefined || isNaN(d.y)
            ? ''
            : scaleX(d.x) + _margin.left,
        )
        .attr('cy', (d) =>
          !d || d.y === null || d.y === undefined || isNaN(d.y)
            ? ''
            : scaleY(d.y) + _margin.top,
        );
    }

    if (isBar) {
      const barWidth = 10;

      chartItem._lineElement = linesGroup
        .append('g')
        .attr('fill', 'steelblue')
        .selectAll()
        .data(data)
        .join('rect')
        .attr('x', (d) => {
          if (!d || d.y === null || d.y === undefined || isNaN(d.y)) {
            return 0;
          }
          return scaleX(d.x) + _margin.left - barWidth / 2;
        })
        .attr('y', (d) =>
          !d || d.y === null || d.y === undefined || isNaN(d.y)
            ? 0
            : scaleY(d.y) + _margin.top,
        )
        .attr('height', (d) => {
          return scaleY(0) - scaleY(Number.isNaN(d.y) ? 0 : d.y);
        })
        .attr('width', (d) => barWidth);

      this.addTooltip(chartItem._lineElement);

      return;
    }

    if (isAreaFull) {
      const scaleArea = this.getScaleArea(chart, scaleX);

      chartItem._areaElement = linesGroup
        .append('path')
        .datum(data)
        .attr('d', scaleArea)
        .attr('class', 'chart-area')
        .style('fill', colorFill)
        .style('opacity', opacityFill);
    }

    if (_textData) {
      chartItem._textDataElement = this.createTextDataElement({
        textDataGroup,
        _textData,
        dataTextSize,
        dataTextColor,
        dataTextFontWeight,
        dataTextBgFill,
        scaleX,
        scaleY,
        _margin,
      });
    }
  }

  createTextDataElement({
    textDataGroup,
    _textData,
    dataTextSize,
    dataTextBgFill,
    dataTextColor,
    dataTextFontWeight,
    scaleX,
    scaleY,
    _margin,
  } = {}) {
    const padding = 2;
    const textDataElement = textDataGroup
      .append('g')
      .attr('class', 'chart-text-data');

    _textData.forEach((textItem, index) => {
      const {
        x,
        y,
        text,
        textPosition = 'top',
        bgColor = dataTextBgFill,
        textColor = dataTextColor,
        fontWeight = dataTextFontWeight,
      } = textItem;
      const wrapper = textDataElement
        .append('g')
        .attr('class', 'chart-text-data-item')
        .attr('data-index', index);

      const rect = wrapper.append('rect');

      const textElem = wrapper
        .append('text')
        .attr('class', 'not-excel')
        .attr('x', padding)
        .attr('y', padding)
        .attr('font-size', dataTextSize)
        .attr('font-weight', fontWeight)
        .attr('fill', textColor)
        // .attr("dominant-baseline", 'text-before-edge')
        .attr('dominant-baseline', 'text-before-edge')
        .text(text);

      const { width: textW, height: textH } = wrapper.node().getBBox();

      rect
        .attr('width', textW + 2 * padding)
        .attr('height', textH + 2 * padding)
        .attr('fill', bgColor);

      const shift = this.getTextDataShift(wrapper, textPosition);

      wrapper.attr(
        'transform',
        `translate(
                ${scaleX(x) + _margin.left - shift.x}, 
                ${scaleY(y) + _margin.top - shift.y}
            )`,
      ); // сдвиг оси вправо и вниз
    });

    return textDataElement;
  }

  getTextDataShift(wrapper, textPosition) {
    const wrapperNode = wrapper.node ? wrapper.node() : wrapper;
    const { width: wrapW, height: wrapH } = wrapperNode.getBBox();

    const shift = {
      x: wrapW / 2,
      y: 0,
    };

    switch (textPosition) {
      case 'top':
        shift.y = wrapH + 3;
        break;
      case 'bottom':
        shift.y = -3;
    }

    return shift;
  }

  getScaleArea(chart, scaleX) {
    const { _margin, height: svgHeight } = chart;
    const { left: marginLeft, top: marginTop, bottom: marginBottom } = _margin;

    return this.d3
      .area()
      .x((d) => scaleX(d.x) + marginLeft)
      .y0(svgHeight - marginBottom)
      .y1((d) => (d.y ? marginTop : svgHeight - marginBottom))
      .curve(this.d3.curveStepAfter);
  }

  getMinMaxOfData(data) {
    const minMax = data.reduce(
      (accum, item, index) => {
        if (!index) {
          accum.min = {
            x: item.x,
            y: item.y,
          };

          accum.max = {
            x: item.x,
            y: item.y,
          };

          return accum;
        }

        if (accum.min.x > item.x) {
          accum.min.x = item.x;
        }

        if (accum.min.y > item.y) {
          accum.min.y = item.y;
        }

        if (accum.max.x < item.x) {
          accum.max.x = item.x;
        }

        if (accum.max.y < item.y) {
          accum.max.y = item.y;
        }

        return accum;
      },
      {
        min: { x: 0, y: 0 },
        max: { x: 0, y: 0 },
      },
    );

    const rangeY = minMax.max.y - minMax.min.y;
    const expansion = (rangeY * this.expansionRangePercent) / 100;
    minMax.max.y += expansion;
    minMax.min.y -= expansion;

    return minMax;
  }

  destroy() {
    // this.chart.svg.clear();
    this.removeEventListeners();
    this.chart.svg.remove();
    this.d3 = null;
    this.element = null;
    this.config = null;
    this.defaultLocale = null;
    this.chart = null;
    // nodeCircles = {};
    // node.remove();
    // link.remove();
    // svg.clear();
    // nodes = [];
    // links = [];
  }
}
