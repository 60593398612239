import { getUnixByDate } from '@/helpers/main_helper';
import { D3LinearChart } from '../d3/D3LinearChart';
import fetchJson from '../src/dataRequest/fetch-json';
import {
  ajaxPositionsDoneAll,
  concatOtherPositions,
} from '../App/use/getElementPosition';
import { useChart } from './chart';

// стандартные повторяющиеся во всех осях настройки
const defaultAxesLineSettings = {
  lineWidth: 1,
  lineColor: '#d0d0d0',
  tickWidth: 1,
  tickColor: '#737373',

  min: 0, // минимальное значение оси
  tickAmount: 5, // фиксированное количество делений оси
}

// Статус уровнемера:
// 0 - 4 - первые два байта - важность уровнемера
// 8 - если установлено то это суммарный уровнемер
// 16 - событие заправка
// 32 - идет заправка
// 64 - событие слив
// 128 - идет слив

export class TrackChartsMotion {
  // track-chart-${id}
  constructor({
    id,
    mainWrapper,
    trackChartsSettingIframe,
    panel,
    points,
    leafletMain,
    objName,
    chartAreaId,
    stateNumber,
    getBegin,
    getEnd,
    chartSubelements,
    panelSubelements,
    otherQueries,
    chartNames = [
      'engineWorking',
      'speed',
      'rpm',
      'gps',
      'gsm',
      'kren',
      'tang',
      'pwr',
      'bat',
      'distance',
      'consumptionAndAnalyzedCharts',
    ],
    displayedCharts = ['distance'],
  } = {}) {
    this.id = id;
    this.chartAreaId = chartAreaId;
    this.chartSubelements = chartSubelements;
    this.panelSubelements = panelSubelements;
    const { trackChartWrapperInner } = chartSubelements;
    this.trackChartWrapperInner = trackChartWrapperInner;
    this.trackChartsSettingIframe = trackChartsSettingIframe;

    const [drawArea] = panel.getElementsByClassName('track-charts-panel');
    this.drawArea = drawArea;
    this.mainWrapper = mainWrapper;

    this.panel = panel;
    this.getBegin = getBegin;
    this.getEnd = getEnd;

    this.points = points;
    this.leafletMain = leafletMain;
    this.stateNumber = stateNumber;
    this.objName = objName;
    this.isTypeNewChart = false;

    this.otherQueries = otherQueries;
    this.highChart = null;
    this.highChartSettings = {
      axes: {
        ySpeed: {
          index: 0,
          labels: []
        },
        yDistance: {
          index: 1,
          labels: []
        },
        yHours: {
          index: 2,
          labels: []
        },
        yGpsGsmPwr: {
          index: 3,
          labels: []
        },
        yLevelConsumption: {
          index: 4,
          labels: []
        },
        yRpm: {
          index: 5,
          labels: []
        },
      },
      loadedLabels: [], // уже загруженные и имеющиеся в графике
      textLabels: [],
      isDragging: false,
      dragStartX: null,
      currentX: null,
      indexStartX: null,
      indexEndX: null,
      currentDate: null,

      lastX: null,
      lastY: null,
      mouseDown: null,
      lastButton: null
    }

    this.markerIcon = leafletMain.createIcon({
      iconUrl: '/images/markers/marker-icon-green.png',
      shadowUrl: '/images/markers/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    this.chartNames = chartNames;
    this.displayedCharts = displayedCharts;

    setTimeout(() => {
      try {
        this.chartCreate({ panel, points, getBegin, getEnd });
        this.addEventListeners();
      } catch (err) {
        console.error(err);
      }
    }, 0);

    return this;
  }

  addCharts({ columns, chartNames, levels, fuelSettings, positions }) {
    this.concatThisPoints(columns, fuelSettings, positions.points);

    const dataValues = useChart.getDatasetsFromOurPositions(
      positions.points,
      fuelSettings,
    );

    const charts = this.getCharts(dataValues, chartNames, levels);

    const axes = this.getAxes(this.chart.minMax, charts);
    this.checkAddFillerChart(axes);

    const margin = this.getChartsMargin(axes);

    this.chart.addCharts(charts, axes, margin);

    this.updateHighChartAxes(charts);
  }

  checkAddFillerChart(axes) {
    const leftFillerAxe = {
      id: "yLeftFiller",
      label: "",
      tooltipHtml: "",
      isTicks: true
    }
    let toAddLeftFiller = true;

    for(const axe of axes) {
      if (['yHours', 'yGpsGsmPwr', 'ySpeed', 'yLeftFiller'].includes(axe.id)) {
        toAddLeftFiller = false;
        break
      }
    }
    if (toAddLeftFiller) {
      axes.push(leftFillerAxe)
    }
  }

  concatThisPoints(columns, fuelSettings, points) {
    if (!columns.length && !Object.keys(fuelSettings).length) return;

    const newPositions = points.allValues.reduce((accum, pos) => {
      accum[pos.time] = pos;
      // delete pos.time;
      return accum;
    }, {});

    this.points.allValues.forEach((curPosition, index) => {
      const { time } = curPosition;
      const newPos = newPositions[time] || {};

      for (const key of columns) {
        const value = newPos[key];

        if (!index || value !== null) {
          curPosition[key] = value;
        }
      }

      for (const settingKey in fuelSettings) {
        for (const setting of fuelSettings[settingKey]) {
          const { tDetailNum } = setting;
          for (const key in newPos) {
            if (key.indexOf(tDetailNum) > -1) {
              curPosition[key] = newPos[key];
            }
          }
        }
      }
    });
  }

  chartCreate({ panel, points, getBegin, getEnd, fuelSettings } = {}) {
    const dataValues = useChart.getDatasetsFromOurPositions(
      points,
      fuelSettings,
    );

    const { required } = dataValues;
    const minMax = {
      min: new Date(getBegin * 1000),
      max: new Date(getEnd * 1000),
    };

    const minWidth = 250;
    const minHeight = 250;
    let width = panel.getBoundingClientRect().width;
    if (width < minWidth) width = minWidth;

    let height = 0.4 * document.documentElement.clientHeight;
    if (height < minHeight) height = minHeight;

    const charts = this.getCharts(
      dataValues,
      this.chartNames,
      [],
      this.displayedCharts,
    );

    this.createHighChart(this.id, charts)
    
    const axes = this.getAxes(minMax, charts);

    this.checkAddFillerChart(axes);

    const margin = this.getChartsMargin(axes);

    const config = {
      requiredPositions: required,
      width,
      height,
      isLegend: true,
      legendTextSize: '14px',
      legendRectWidth: 15,
      infoTitleSize: '14px',
      infoTitleBgFill: 'rgba(211, 211, 211,0.8)',
      dataTextSize: '14px',
      dataTextFontWeight: 'bold',
      margin,
      charts,
      axes,
    };

    this.chart = new D3LinearChart({ d3, elementId: this.chartAreaId, config });

    this.chart.minMax = minMax;

    const initHeight = 40;
    this.percentHeightValueChange(initHeight);
  }


  updateHighChartAxes(charts) {
    const newLabels = charts.map(obj => obj.label)

    const toAdd = newLabels.filter(obj => !this.highChartSettings.loadedLabels.includes(obj));

    for (let i = 0; i< toAdd.length; i++) { // add new
      const item = charts.filter(obj => obj.label === toAdd[i])[0]

      let axisIndex = this.highChartSettings.axes[item.yAxis].index
      
      const addedSeries = this.highChart.addSeries({
        data: this.formatToHighChartData(item.data),
        lineWidth: 0.5,
        name: item.label,
        color: item.colorStroke,
        yAxis: axisIndex,
        animation: false,
        visible: false,
        boostThreshold: 1,
        turboThreshold: 0,
        dataGrouping: {
          enabled: true,
          // approximation: 'high',
          groupPixelWidth: 1
        },
        marker: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        }
      }, false)

      if (item._textData) {
        this.addLevelTitles(this.highChart, addedSeries, item, this.highChartSettings)
      }

      this.highChartSettings.loadedLabels.push(item.label)
      this.highChartSettings.axes[item.yAxis].labels.push(item.label)

      let maxValue = this.highChartGetMaxValueForAxis(item.yAxis) * 1.05
      if (maxValue === 0) {
        for (let j = 0; j < item.data.length; j++) {
          maxValue = Math.max(item.data[j].y)
        }
      } 
      let minValue = this.highChartGetMinValueForAxis(item.yAxis)
      if (minValue === 9999) {
        for (let j = 0; j < item.data.length; j++) {
          minValue = Math.min(item.data[j].y)
        }
      } 
      
      this.highChart.yAxis[axisIndex].update({
        max: maxValue,
        min: minValue,
        visible: true
      });
    }

    const toDel = this.highChartSettings.loadedLabels.filter(obj => !newLabels.includes(obj));

    for (let i = 0; i< toDel.length; i++) {
      const axisName = this.highChartFindAxis(toDel[i])
      let axisIndex = this.highChartSettings.axes[axisName].index
      this.removeHighChartSeries(toDel[i])

      const maxValue = this.highChartGetMaxValueForAxis(axisName)
      const minValue = this.highChartGetMinValueForAxis(axisName)

      if (this.highChartSettings.axes[axisName].labels.length <= 0) {
        // hide unused axis
        this.highChart.yAxis[axisIndex].update({
          max: 1,
          visible: false
        });
      } else {
        this.highChart.yAxis[axisIndex].update({
          max: maxValue,
          min: minValue,
          visible: true
        });
      }
    }

    this.highChart.redraw();
  }

  addLevelTitles(chart, addedSeries, item, highChartSettings) {
    item._textData.forEach(textItem => {
      const textGroup = chart.renderer.g().attr({
        zIndex: 7,
      }).add();

      const textLabel = chart.renderer
        .text(textItem.text,0,0)
        .attr({
          zIndex: 6,
        })
        .css({
          color: textItem.textColor || 'black',
          fontSize: '12px',
          textAlign: 'center',
        })
        .add(textGroup);

        const bbox = textLabel.getBBox();

        const textRect = chart.renderer
        .rect(bbox.x - 5, bbox.y - 5, bbox.width + 10, bbox.height + 10, 5)
        .attr({
            fill: textItem.bgColor || 'black',
            stroke: 'black',
            'stroke-width': 1,
            zIndex: 5,
        })
        .add(textGroup);   

        highChartSettings.textLabels.push({
          text: textLabel,
          rect: textRect,
          item: textItem,
          textGroup: textGroup,
          series: addedSeries,
          isHidden: true,
        });

        textGroup.hide();
    });

    Highcharts.addEvent(addedSeries, 'hide', function (e) {
      this.recalculateLevelTitles()
      const filteredTextLabels = this.highChartSettings.textLabels.filter(elem => elem.series.name === e.target.name)
      filteredTextLabels.forEach(label => {
        label.textGroup.hide()
        label.isHidden = true;
      });
    }.bind(this));

    Highcharts.addEvent(addedSeries, 'show', function (e) {
      this.recalculateLevelTitles()
      const filteredTextLabels = this.highChartSettings.textLabels.filter(elem => elem.series.name === e.target.name)
      filteredTextLabels.forEach(label => {
        label.textGroup.show()
        label.isHidden = false;
      });
    }.bind(this));
  }

  recalculateLevelTitles() {
    this.highChartSettings.textLabels.forEach(el => {
      if (!el.series.xAxis) {
        return
      }
      let newX = el.series.xAxis.toPixels(el.item.x, true) + this.highChart.plotLeft;
      let newY = el.series.yAxis.toPixels(el.item.y, true);
      if (Number.parseFloat(el.item.text) < 0) {
        newY += 30
      } else {
        newY -= 2
      }

      const bbox = el.rect.getBBox();
      newX -= bbox.width / 2; // текст по середине

      const chartArea = this.highChart.plotBox;
      const isInsidePlot = (newX >= chartArea.x &&
        newX <= chartArea.x + chartArea.width)

      if (!el.isHidden) {
        if (isInsidePlot) {
          el.textGroup.show();
          el.textGroup.attr({
            translateX: newX,
            translateY: newY,
          });
          el.text.css({
            color: el.item.textColor || 'black',
            fontSize: '12px',
            textAlign: 'center',
          })
          const bbox = el.text.getBBox();
          el.rect.attr({
            x: bbox.x - 5,
            y: bbox.y - 5,
            width: bbox.width + 10,
            height: bbox.height + 10,
            fill: el.item.bgColor || 'white',
            stroke: el.item.borderColor || 'black',
            'stroke-width': 1,
          });
        } else {
          el.textGroup.hide();
        }
      }
    });
  }

  highChartFindAxis(name) {
    if (this.highChartSettings.axes.yDistance.labels.indexOf(name) != -1) {
      return 'yDistance'
    }
    if (this.highChartSettings.axes.yGpsGsmPwr.labels.indexOf(name) != -1) {
      return 'yGpsGsmPwr'
    }
    if (this.highChartSettings.axes.yHours.labels.indexOf(name) != -1) {
      return 'yHours'
    }
    if (this.highChartSettings.axes.yLevelConsumption.labels.indexOf(name) != -1) {
      return 'yLevelConsumption'
    }
    if (this.highChartSettings.axes.yRpm.labels.indexOf(name) != -1) {
      return 'yRpm'
    }
    if (this.highChartSettings.axes.ySpeed.labels.indexOf(name) != -1) {
      return 'ySpeed'
    }
    return false
  }

  highChartGetMaxValueForAxis(yAxisName) {
    let maxValue = 0

    const axisLabels = this.highChartSettings.axes[yAxisName].labels
    
    for(let label of axisLabels) {
      const labelIndex = this.highChartSettings.loadedLabels.indexOf(label)

      const maxSeries = Math.max(...this.highChart.series[labelIndex].yData)
      maxValue = Math.max(maxValue, maxSeries)
    }

    return maxValue
  }

  highChartGetMinValueForAxis(yAxisName) {
    let minValue = 9999

    const axisLabels = this.highChartSettings.axes[yAxisName].labels
    
    for(let label of axisLabels) {
      const labelIndex = this.highChartSettings.loadedLabels.indexOf(label)

      const minSeries = Math.min(...this.highChart.series[labelIndex].yData)
      minValue = Math.min(minValue, minSeries)
    }

    return minValue
  }

  removeHighChartSeries(label) {
    const seriesIndex = this.highChartSettings.loadedLabels.indexOf(label)

    this.highChartSettings.loadedLabels = this.highChartSettings.loadedLabels.filter(item => item !== label)

    this.highChart.series[seriesIndex].remove()
    this.highChartSettings.axes.yDistance.labels = this.highChartSettings.axes.yDistance.labels.filter(item => item !== label);
    this.highChartSettings.axes.yGpsGsmPwr.labels = this.highChartSettings.axes.yGpsGsmPwr.labels.filter(item => item !== label);
    this.highChartSettings.axes.yHours.labels = this.highChartSettings.axes.yHours.labels.filter(item => item !== label);
    this.highChartSettings.axes.yLevelConsumption.labels = this.highChartSettings.axes.yLevelConsumption.labels.filter(item => item !== label);
    this.highChartSettings.axes.yRpm.labels = this.highChartSettings.axes.yRpm.labels.filter(item => item !== label);
    this.highChartSettings.axes.ySpeed.labels = this.highChartSettings.axes.ySpeed.labels.filter(item => item !== label);
  }

  formatToHighChartData(data) { 
    return data.map(point => [
      new Date(point.x).getTime(),
      point.y                      
    ]);  
  }

  createHighChart(id, charts) {
    const that = this;

    const seriesData = []
    let maxSpeed = 0
    let maxDistance = 0

    for (let i = 0; i < charts.length; i++) {
      const item = charts[i]
      seriesData.push({
        data: this.formatToHighChartData(item.data),
        lineWidth: 0.5,
        name: item.label,
        color: item.colorStroke,
        yAxis: i,
        animation: false,
        visible: item.label === "Пробег:",
        boostThreshold: 1, 
        turboThreshold: 0, 
        dataGrouping: {
          enabled: true,
          // approximation: 'high', 
          groupPixelWidth: 1 
        },
        marker: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        }
      })
      this.highChartSettings.loadedLabels.push(item.label)
      this.highChartSettings.axes[item.yAxis].labels.push(item.label)

      if (item.name === "speed") {
        for (let i = 0; i < item.data.length; i++) {
          maxSpeed = Math.max(maxSpeed, item.data[i].y)
        }
      }
      if (item.name === "distance") {
        for (let i = 0; i < item.data.length; i++) {
          maxDistance = Math.max(maxDistance, item.data[i].y)
        }
      }
    }

    this.highChart = Highcharts.chart(`highchart-${id}`, {
      // boost: {
      //   useGPUTranslations: true,
      //   seriesThreshold: 1 
      // },
      chart: {
        type: 'line',
        zooming: {
          mouseWheel: {
            enabled: true
          },
          type: 'x'
        },
        panning: true,
        panKey: 'shift',
  
        events: {
          redraw: function() {
            that.recalculateLevelTitles()
            // console.timeEnd('rerenderTime'); // Завершаем замер перерисовки после изменения диапазона
          },
          selection: function (event) {
            // Отключаем зуминг при правой кнопке
            // if (lastButton === 1) { // todo кпм panning
            //   event.preventDefault();
            // }
            return false;
          },
          load: function () {
            var thisHighchart = this;
  
            Highcharts.addEvent(thisHighchart.container, 'mousedown', function (e) {
              // click index
              var chartX = e.chartX;
              var chartY = e.chartY;

              // chart bounds
              var plotLeft = thisHighchart.plotLeft;
              var plotTop = thisHighchart.plotTop;
              var plotWidth = thisHighchart.plotWidth;
              var plotHeight = thisHighchart.plotHeight;

              var isInsidePlot = chartX >= plotLeft &&
                chartX <= plotLeft + plotWidth &&
                chartY >= plotTop &&
                chartY <= plotTop + plotHeight;
              
              if (!e.shiftKey && isInsidePlot) {
                thisHighchart.xAxis[0].removePlotBand('selected-plotBand');

                this.highChartSettings.isDragging = true;
                this.highChartSettings.indexStartX = chartX;
                this.highChartSettings.indexEndX = chartX;
                const dragStartX = new Date(thisHighchart.xAxis[0].toValue(chartX));
                this.highChartSettings.dragStartX = dragStartX
                this.highChartSettings.currentX = new Date(dragStartX);
                const xStartIndex = this.findNearestLeftPointIndex(this.highChart.series[0].xData, dragStartX);
                this.chart.updateTrackOnMap(xStartIndex, dragStartX, undefined)
              }
            }.bind(that));

            Highcharts.addEvent(thisHighchart.container, 'mousemove', function (e) {
              // update cursor pos
              this.highChartSettings.currentX = new Date(thisHighchart.xAxis[0].toValue(e.chartX));

              var xValue = thisHighchart.xAxis[0].toValue(e.chartX);
              var plotLineId = 'cursorLine';
              const selectedPlotBandId = 'selected-plotBand';

              // Удаляем предыдущую линию
              thisHighchart.xAxis[0].removePlotLine(plotLineId);

              // click index
              var chartX = e.chartX;
              var chartY = e.chartY;

              var plotLeft = thisHighchart.plotLeft;
              var plotTop = thisHighchart.plotTop;
              var plotWidth = thisHighchart.plotWidth;
              var plotHeight = thisHighchart.plotHeight;

              var isInsidePlot = chartX >= plotLeft &&
                chartX <= plotLeft + plotWidth &&
                chartY >= plotTop &&
                chartY <= plotTop + plotHeight;

              if (isInsidePlot) {
                // Добавляем новую линию на позицию курсора
                thisHighchart.xAxis[0].addPlotLine({
                  value: xValue,
                  color: 'rgba(0, 0, 0, 0.2)',
                  width: 1,
                  id: plotLineId,
                  zIndex: 5
                });
              }

              if (this.highChartSettings.isDragging) {
                thisHighchart.xAxis[0].removePlotBand(selectedPlotBandId);
                this.highChartSettings.indexEndX = e.chartX;

                thisHighchart.xAxis[0].addPlotBand({
                  from: this.highChartSettings.dragStartX,
                  to: this.highChartSettings.currentX,
                  color: 'rgba(68, 170, 213, 0.2)',
                  id: selectedPlotBandId
                });
              } else {
                // when not holding click
                this.highChartSettings.currentDate = new Date(thisHighchart.xAxis[0].toValue(e.chartX));
              }
            }.bind(that));

            Highcharts.addEvent(thisHighchart.container, 'contextmenu', function (e) {
              e.preventDefault();
            });

            Highcharts.addEvent(thisHighchart.container, 'mouseleave', function () {
              thisHighchart.xAxis[0].removePlotLine('cursorLine');
            });
        
            Highcharts.addEvent(thisHighchart.container, 'mouseup', function () {
              // end selecting area
              if (this.highChartSettings.isDragging) {
                this.highChartSettings.isDragging = false;
              }
            }.bind(that));

            // Всё для панорамирования по ПКМ // todo panning пкм
            // Функция для включения панорамирования
            // thisHighchart.container.addEventListener('mousedown', function (e) {
            //   switch (e.which.toString()) {
            //     case '3': // ПКМ
            //       this.highChartSettings.mouseDown = 1;
            //       this.highChartSettings.lastButton = 1;
            //       break;
            //     default:
            //       this.highChartSettings.lastButton = 0;
            //   }
            // }.bind(that));
            // // Передвижение графика
            // thisHighchart.container.addEventListener('mousemove', function(e) {
            //     if (this.highChartSettings.mouseDown === 1) {
            //       var xExtremes = thisHighchart.xAxis[0].getExtremes();
            //       var yExtremes = thisHighchart.yAxis[0].getExtremes();

            //       const plotWidth = thisHighchart.plotWidth;
            //       const plotHeight = thisHighchart.plotHeight;
            //       const timeWidth = xExtremes.max - xExtremes.min;
            //       const timeInPixel = timeWidth/plotWidth;

            //       // Обновляем ось X на основе перемещения мыши
            //       if (e.pageX > this.highChartSettings.lastX) {
            //         var diff = (e.pageX - this.highChartSettings.lastX) * timeInPixel;
            //         thisHighchart.xAxis[0].setExtremes(xExtremes.min - diff, xExtremes.max - diff, true);
            //       } else if (e.pageX < this.highChartSettings.lastX) {
            //         var diff = (this.highChartSettings.lastX - e.pageX) * timeInPixel;
            //         thisHighchart.xAxis[0].setExtremes(xExtremes.min + diff, xExtremes.max + diff, true);
            //       }

            //       // // Обновляем ось Y на основе перемещения мыши
            //       // if (e.pageY > this.highChartSettings.lastY) {
            //       //     var ydiff = 1 * (e.pageY - this.highChartSettings.lastY);
            //       //     thisHighchart.yAxis[0].setExtremes(yExtremes.min + ydiff, yExtremes.max + ydiff);
            //       // } else if (e.pageY < this.highChartSettings.lastY) {
            //       //     var ydiff = 1 * (this.highChartSettings.lastY - e.pageY);
            //       //     thisHighchart.yAxis[0].setExtremes(yExtremes.min - ydiff, yExtremes.max - ydiff);
            //       // }
            //   }
            //   this.highChartSettings.lastX = e.pageX;
            //   this.highChartSettings.lastY = e.pageY;
            // }.bind(that));
            // // Функция для отключения панорамирования
            // thisHighchart.container.addEventListener('mouseup', function (e) {
            //   if (e.which === 3) { // ПКМ
            //     this.highChartSettings.mouseDown = 0;
            //   }
            // }.bind(that));
          },
          
          // afterRedraw: function () {
          //   return
          // }
        },
      },
      tooltip: {
        useHTML: true, // html in tooltip
        style: {
          pointerEvents: 'none' // line from tooltip to cursor
        },
        shape: 'square', // arrow from tooltip to cursor
        positioner: function (labelWidth, labelHeight, point) {
          const padding = 10; 
          const chartWidth = this.chart.chartWidth;
          const chartHeight = this.chart.chartHeight;

          let x = point.plotX + this.chart.plotLeft + padding;
          let y = point.plotY + this.chart.plotTop + padding;

          // Если курсор рядом с tooltip, передвигаем вправо
          if (x < 50 + labelWidth) {
            return { x: chartWidth - labelWidth - padding, y: padding };
          }

          return { x: padding, y: padding };
        },
        formatter: function () {
          let res = [];
          let indexBegin,indexEnd, xValueLast, xIndexLast;

          const optionsDate = { year: '2-digit', month: '2-digit', day: '2-digit' };
          const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

          let xValueBegin = this.highChartSettings.dragStartX;
          let xValueEnd = this.highChartSettings.currentX;
          const curDate = this.highChartSettings.currentDate;
          
          const index1 = this.findNearestLeftPointIndex(this.highChart.series[0].xData, xValueBegin);
          const index2 = this.findNearestLeftPointIndex(this.highChart.series[0].xData, xValueEnd);
          if (index1 > index2) {
            indexBegin = index2
            indexEnd = index1
            xValueLast = xValueBegin
            xIndexLast = index1
          } else {
            indexBegin = index1
            indexEnd = index2
            xValueLast = xValueEnd
            xIndexLast = index2
          }

          if (xValueEnd < 0) {
            return
          }
          if (xValueBegin < 0) {
            xValueBegin = xValueEnd;
          }

          res = this.chart.selectionValuesCalculate({
            indexBegin,
            indexEnd,
            xValueBegin,
            xValueEnd,
          })
          const resHtml = res.map(item => `<li>${item}</li>`).join(''); // <li> Пробег: 28км </li>

          const dateBegin = new Date(xValueBegin);
          const dateEnd = new Date(xValueEnd);

          let formattedDateBegin, formattedDateEnd
          if (dateBegin > dateEnd) {
            formattedDateBegin = `${dateEnd.toLocaleTimeString('ru-RU', optionsTime)} ${dateEnd.toLocaleDateString('ru-RU', optionsDate)}`;
            formattedDateEnd = `${dateBegin.toLocaleTimeString('ru-RU', optionsTime)} ${dateBegin.toLocaleDateString('ru-RU', optionsDate)}`;
          } else {
            formattedDateBegin = `${dateBegin.toLocaleTimeString('ru-RU', optionsTime)} ${dateBegin.toLocaleDateString('ru-RU', optionsDate)}`;
            formattedDateEnd = `${dateEnd.toLocaleTimeString('ru-RU', optionsTime)} ${dateEnd.toLocaleDateString('ru-RU', optionsDate)}`;
          }

          if (this.highChartSettings.isDragging) { // select in progress
            this.chart.updateTrackOnMap(index2, xValueEnd, [indexBegin, indexEnd])
            return `<div style="font-size: 14px; padding: 5px; width: 320px; word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">
                      <strong>${formattedDateBegin} - ${formattedDateEnd}</strong><br>
                      <text style="list-style-type: none; padding-left: 0;">
                        ${resHtml}
                      </text>
                    </div>`;
          } else {
            const plotBand = this.highChart.series[0].xAxis.plotLinesAndBands.find(band => band.options.id === 'selected-plotBand');
            if (plotBand) { // select finished
              return `<div style="font-size: 14px; padding: 5px; width: 320px; word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">
                        <strong>${formattedDateBegin} - ${formattedDateEnd}</strong><br>
                        <text style="list-style-type: none; padding-left: 0;">
                          ${resHtml}
                        </text>
                      </div>`;
            } else { // not selected 
              const tooltipData = this.chart.currentValuesCalculate({
                xValue: curDate
              })
              const resHtml2 = tooltipData.map(item => `<li>${item}</li>`).join('');
              const formattedDateEnd = `${curDate?.toLocaleTimeString('ru-RU', optionsTime)} ${curDate?.toLocaleDateString('ru-RU', optionsDate)}`;
        
              return `<div style="font-size: 14px; padding: 5px; width: 320px; word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">
                        <strong>${formattedDateEnd}</strong><br>
                        <text style="list-style-type: none; padding-left: 0;">
                          ${resHtml2}
                        </text>
                      </div>`;
            }
          }

        }.bind(that),
      },
      title: false,
      // title: { 
      //   text: '', //`${this.objName} | ${this.stateNumber}: Найдено ${this.points.allValues.length} точек`,
      //   align: 'left'
      // },
      subtitle: false,
      // subtitle: {
      //   text: `${formatDateHelper(new Date(this.getBegin * 1000),'dd.mm.yy hh:nn:ss',)} - ${formatDateHelper(new Date(this.getEnd * 1000),'dd.mm.yy hh:nn:ss',)}`,
      //   align: 'left'
      // },
      plotOptions: {
        series: {
          // enableMouseTracking: false,
          boostThreshold: 1,
          turboThreshold: 0,
          boostCanvas: true,
          animation: false,
          shadow: false,
          threshold: 5000,
          marker: {
            enabled: false,
            radius: 0
          },
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              enabled: false
            }
          },
          dataGrouping: {
            enabled: true,
            approximation: 'high',
            groupPixelWidth: 1
          }
        }
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: "Дата/Время"
        },
        events: {
          afterSetExtremes: () => {
            // console.time('rerenderTime'); // Начинаем замер времени перерисовки
          }
        },
        labels: {
          formatter: function() {
            let localDate = new Date(this.value);
            let offset = new Date().getTimezoneOffset()/-60;
            localDate.setHours(localDate.getHours() + offset);
            return Highcharts.dateFormat('%H:%M', localDate);
          }
        }
      },
      yAxis: [{
        title: {
          text: "Км/ч",
        },
        max: maxSpeed,
        ...defaultAxesLineSettings
      },
      {
        title: {
          text: "Км"
        },
        max: maxDistance,
        opposite: true,
        ...defaultAxesLineSettings
      },
      {
        title: {
          text: "Час",
        },
        max: 1,
        visible: false,
        ...defaultAxesLineSettings
      },
      {
        title: {
          text: "Градус",
        },
        max: 1,
        visible: false,
        ...defaultAxesLineSettings
      },
      {
        title: {
          text: "Литры",
        },
        max: 1,
        visible: false,
        opposite: true,
        ...defaultAxesLineSettings
      },
      {
        title: {
          text: "Об/мин",
        },
        max: 1,
        visible: false,
        opposite: true,
        ...defaultAxesLineSettings
      },
    ],
      series: seriesData
    });
  }

  findNearestLeftPointIndex(data, targetX) {
    // поиск ближайшего индекса слева по дате
    var nearestIndex = -1;

    for (var i = 0; i < data.length; i++) {
      if (data[i] <= targetX) {
        nearestIndex = i;
      } else {
        break;
      }
    }

    return nearestIndex;
  }

  getChartsMargin(axes = []) {
    const margin = {
      top: 5,
      left: 0,
      bottom: 55,
      right: 0,
    };

    const ids = [];
    axes.forEach(({ id }) => {
      if (ids.includes(id)) return;

      if (['yHours', 'yGpsGsmPwr', 'ySpeed', 'yLeftFiller'].includes(id)) {
        margin.left += 60;
      }
      if (['yDistance', 'yLevelConsumption', 'yRpm'].includes(id)) {
        margin.right += 60;
      }

      ids.push(id);
    });

    return margin;
  }

  getCharts(dataValues, chartNames, levels = [], displayedCharts = []) {
    const charts = [];

    if (!!levels.length) {
      // нам нужно только те levels, которые запросили
      const newDataValuesLevels = levels.reduce((acc, key) => {
        if (dataValues['levels'].hasOwnProperty(key)) {
          acc[key] = dataValues['levels'][key];
        }
        return acc;
      }, {});

      charts.push(
        ...useChart.charts.consumptionAndAnalyzedCharts(
          dataValues['labels'],
          newDataValuesLevels,
          true,
        ),
      );
    }

    chartNames.forEach((name) => {
      if (name === 'engineWorking') {
        charts.push(
          useChart.charts.engineWorking(
            dataValues['labels'],
            dataValues['moto1Fill'],
            !displayedCharts.includes(name),
          ),
        );
        return;
      }

      if (name === 'distance' || name === 'distSumm') {
        charts.push(
          useChart.charts.distance(
            dataValues['labels'],
            dataValues['distSumm'],
            !displayedCharts.includes(name),
          ),
        );
        return;
      }

      charts.push(
        useChart.charts[name](
          dataValues['labels'],
          dataValues[name],
          !displayedCharts.includes(name),
        ),
      );
    });

    return charts;
  }
  getAxes(minMax, charts) {
    const chartLabelsByAxesName = {};

    charts.forEach((chart) => {
      if (!(chart.xAxis in chartLabelsByAxesName)) {
        chartLabelsByAxesName[chart.xAxis] = [];
      }
      if (!(chart.yAxis in chartLabelsByAxesName)) {
        chartLabelsByAxesName[chart.yAxis] = [];
      }

      if (!chartLabelsByAxesName[chart.xAxis].includes(chart.label)) {
        chartLabelsByAxesName[chart.xAxis].push(chart.label);
      }

      if (!chartLabelsByAxesName[chart.yAxis].includes(chart.label)) {
        chartLabelsByAxesName[chart.yAxis].push(chart.label);
      }
    });

    const axes = [];

    for (const axesName in chartLabelsByAxesName) {
      const chartLabels = chartLabelsByAxesName[axesName];

      if (axesName === 'xDateTime') {
        axes.push(useChart.axes[axesName](minMax, chartLabels));
        continue;
      }

      axes.push(useChart.axes[axesName](chartLabels));
    }

    return axes;
  }

  selectionValuesByAngle({ data = [], indexBegin, indexEnd, label } = {}) {
    const { min = null, max = null } = this.minMaxOfDataCalculate({
      data,
      indexBegin,
      indexEnd,
      key: 'y',
    });
    if (min === null || max === null) {
      return `${label} -`;
    }

    const dispersion = max - min;
    return `${label} min: ${Math.round(min * 100) / 100}, max: ${
      Math.round(max * 100) / 100
    }, разброс: ${Math.round(dispersion * 100) / 100} (град)`;
  }

  minMaxOfDataCalculate({ data, indexBegin, indexEnd, key = 'y' } = {}) {
    const minMax = {};
    for (let i = indexBegin; i < indexEnd + 1 && i < data.length - 1; i++) {
      const val = data[i][key];
      if (i === indexBegin) {
        minMax.min = val;
        minMax.max = val;
        continue;
      }
      if (val > minMax.max) {
        minMax.max = val;
      }
      if (val < minMax.min) {
        minMax.min = val;
      }
    }

    return minMax;
  }

  getDatasets(points) {
    const { allValues } = points;

    const result = {
      labels: [],
      speed: [],
      distSumm: [],
      gps: [],
      gsm: [],
      kren: [],
      tang: [],
      rpm: [],
      pwr: [],
      bat: [],
      moto1Fill: [],
      levels: {},
      required: [],
    };

    const levels = result.levels;
    const leveCount = 10;
    const levelsDesc = [
      { purpose: 'consumption', purposeText: 'расходный' },
      { purpose: 'cistern', purposeText: 'цистерна' },
    ];

    const intervalMoto1 = {
      value: 0,
      prev: false,
      prevTime: 0,
    };

    let curSpeed = 0;

    let prevGps = 0;

    let f = 0;
    allValues.forEach((values, index) => {
      const {
        time,
        speed,
        distSumm: distSummOrigin,
        gsm = null,
        gps = null,
        rpm,
        kren,
        tang,
        pwr,
        bat,
        moto_mask,
      } = values;
      result.required[index] = { val: false, because: '' };

      if (speed < 2500) {
        // 250 км/ч
        curSpeed = speed;
      }

      if (intervalMoto1.prev) {
        intervalMoto1.value += time - intervalMoto1.prevTime;
      }

      const moto1isWork = Boolean(moto_mask & 1);

      if (intervalMoto1.prev !== moto1isWork) {
        result.required[index - 1] = { val: true, because: 'moto1Fill - 1' };
        result.required[index] = { val: true, because: 'moto1Fill' };
      }

      result.labels[index] = new Date(time);

      // result.labels[index] = time / 1000;
      result.speed[index] = curSpeed / 10;
      result.distSumm[index] = distSummOrigin / 1000;
      if (gps !== null) {
        result.gps[index] = gps;
        if (gps !== prevGps) {
          result.required[index - 1] = { val: true, because: 'gps - 1' };
          result.required[index] = { val: true, because: 'gps' };
          prevGps = gps;
        }
      }
      if (gsm !== null) {
        result.gsm[index] = gsm;
      }
      result.rpm[index] = rpm > -1 ? rpm : 0;
      result.kren[index] = kren / 100;
      result.tang[index] = tang / 100;
      result.pwr[index] = pwr / 1000;
      result.bat[index] = bat / 1000;
      result.moto1Fill[index] = {
        y: moto1isWork,
        interval: intervalMoto1.value,
      };

      intervalMoto1.prev = moto1isWork;
      intervalMoto1.prevTime = time;

      levelsDesc.forEach((lvDesc) => {
        const { purpose, purposeText } = lvDesc;

        for (let ii = 0; ii < leveCount; ii++) {
          const lvNum = ii + 1;
          const lvName = `${purpose}_${lvNum}`;
          const lvVal = values[`original_${lvName}`] ?? false;

          if (lvVal === false || (lvVal === null && !(lvName in levels))) {
            continue;
          }

          const lvStatus = values[`status_${lvName}`];
          const lvAnalyzed = values[`analyzed_${lvName}`];

          if (!(lvName in levels)) {
            const summText = lvStatus & 8 ? ' (сумма)' : '';

            levels[lvName] = {
              oiginal: [],
              analyzed: [],
              status: [],
              text: `ДУТ ${purposeText} ${lvNum}${summText}`,
              purpose,
            };
          }

          const level = levels[lvName];
          level.oiginal[index] = lvVal !== null ? lvVal / 1000 : lvVal;
          level.analyzed[index] =
            lvAnalyzed !== null ? lvAnalyzed / 1000 : lvAnalyzed;
          level.status[index] = lvStatus;

          if (lvStatus & 16 || lvStatus & 64) {
            result.required[index - 1] = { val: true, because: 'status - 1' };
            result.required[index] = { val: true, because: 'status' };
          }
        }
      });
    });

    return result;
  }

  trackChartWrapperInnerResizeEvent() {
    this.that.trackChartWrapperInnerResize();
  }

  trackChartWrapperInnerResize() {}

  trackChartsSettingIframeResize() {
    if (!this.that.wrapperSize) {
      this.that.wrapperSize = {};
    }

    const mainWrapper = this.that.mainWrapper;    
    let { width: wrapperW, height: wrapperH } =
      mainWrapper.getBoundingClientRect();
    let scrollerWidth = 20;

    if (!this.that.isOpened || !this.that.wrapperSize.height) {

      this.that.isOpened = true;
      this.that.chart?.resize({width: wrapperW - scrollerWidth, height: this.that.chart.height})
      return;
    } else {
      this.that.drawArea.style.height = '';
    }

    const { width: lastW = 0, height: lastH = 0 } = this.that.wrapperSize;
    // const {width:iframeW,height:iframeH} = this.that.trackChartsSettingIframe.getBoundingClientRect();

    const {
      borderTopWidth: mainWrapperBorderTop,
      borderBottomWidth: mainWrapperBorderBottom,
    } = mainWrapper.style;

    wrapperH +=
      parseInt(mainWrapperBorderTop || 0) +
      parseInt(mainWrapperBorderBottom || 0);

    if (
      !(
        wrapperW < lastW ||
        wrapperW > lastW + 2 ||
        wrapperH < lastH ||
        wrapperH > lastH + 2
      )
    ) {
      return;
    }

    this.that.wrapperSize = {
      width: wrapperW,
      height: wrapperH,
    };

    if (!this.that.chart) {
      return;
    }

    // const allHeight = this.that.trackChartsSettingIframe.getBoundingClientRect().height;
    const allHeight = wrapperH - 3;
    const { trackChartsSettingHeader, trackChartsSettingContainer } =
      this.that.panelSubelements;
    const { trackChartValues, trackChartHeader, trackChartWrapper } =
      this.that.chartSubelements;

    const topElementsHeight =
      trackChartsSettingHeader.getBoundingClientRect().height +
      trackChartsSettingContainer.getBoundingClientRect().height +
      trackChartValues.getBoundingClientRect().height +
      trackChartHeader.getBoundingClientRect().height;

    const chartHeight = allHeight - topElementsHeight;
    // this.that.drawArea.style.height = `${chartHeight}px`;
    // const chartWidth = this.that.drawArea.getBoundingClientRect().width;
    const chartWidth = wrapperW;

    if (
      this.that.panelSubelements.trackChartsContainer.getElementsByClassName(
        'track-charts-item',
      ).length > 1
    ) {
      this.that.chart.resize({ width: chartWidth });
      return;
    }

    this.that.chart.resize({ width: chartWidth, height: chartHeight });

    const percentHeight = Math.floor(
      (100 * chartHeight) /
        document.documentElement.getBoundingClientRect().height,
    );
    this.that.percentHeightValueChange(percentHeight);
  }

  percentHeightValueChange(percentHeight) {
    this.chartSubelements.trackChartSettingHeight.value = percentHeight;
    this.chartSubelements.trackChartSettingHeightValue.innerText = `${percentHeight} %`;
  }

  trackChartSelectNewValueChange(value) {
    this.isTypeNewChart = value.target.checked;
    this.that.chartSubelements.trackChartSelectNewValue.innerText = value.target.checked ? `Новый`: `Старый`;
    const trackChartWrapper = this.that.chartSubelements.trackChartWrapper;
    const highchartsFigure = this.that.chartSubelements.trackHighchartWrapper;

    if (value.target.checked) {
      // прячем старый
      trackChartWrapper.classList.add('chart-hidden');
      highchartsFigure.classList.remove('d-none');
    } else {
      // прячем новый
      trackChartWrapper.classList.remove('chart-hidden');
      highchartsFigure.classList.add('d-none');
      const {width, height} = this.that.chart.chart;
      this.that.chart.resize({ width, height, isUpdateLegend: true })
    }
  }

  inputHeightEvent(event) {
    const value = event.target.value;
    this.that.chartSubelements.trackChartSettingHeightValue.innerText = `${value} %`;
  }

  changeHeightEvent(event) {
    const value = event.target.value;

    // посчитать высоту в пикселях
    const height = (value * document.documentElement.clientHeight) / 100;

    this.that.chart.resize({ height });

    this.that.highChart.update({
      chart: {
        height
      }
    });
  }

  graphLineMovedEvent(event) {
    const { indexByFirstChart, value, selectionIndexes } = event.detail;
    this.that.graphLineMoved({ indexByFirstChart, value, selectionIndexes });
  }

  graphLineMoved({ indexByFirstChart, value, selectionIndexes } = {}) {
    const latLonDelimeter = 1000000000000000;
    const { leafletMain, points, marker } = this;

    const { allValues = [] } = points;
    // const latLon = (indexByFirstChart === null) ? null : latlngs[indexByFirstChart]
    const data = indexByFirstChart === null ? {} : allValues[indexByFirstChart];
    const { time = new Date(), lat: latOrigin, lon: lonOrigin } = data;
    const latLon = [latOrigin / latLonDelimeter, lonOrigin / latLonDelimeter];

    const text = `${this.stateNumber}<br> ${formatDateHelper(
      new Date(time),
      'hh:nn:ss dd.mm.yy',
    )}`;

    if (latLon && marker) {
      leafletMain.moveMarker({ marker: marker, latLon, text });
    }

    if (!latLon && marker) {
      leafletMain.dropMarker(marker);
      this.marker = null;
    }

    if (latLon && !marker) {
      const options = {
        icon: this.markerIcon,
      };
      this.marker = leafletMain.addMaker({ latLon, text, options });
    }

    if (selectionIndexes) {
      const [iBegin, iEnd] = selectionIndexes;
      const latlngs = this.getLatLngsInterval({
        allValues,
        latLonDelimeter,
        iBegin,
        iEnd,
      });

      const { time: timeBeginU } = allValues[iBegin] || {};
      const { time: timeEndU } = allValues[iBegin] || {};

      const timeBegin = timeBeginU
        ? formatDateHelper(new Date(timeBeginU), 'hh:nn:ss dd.mm.yy')
        : 'н.д.';
      const timeEnd = timeEndU
        ? formatDateHelper(new Date(timeEndU), 'hh:nn:ss dd.mm.yy')
        : '?';

      const lineText = `Выделено на графике по объекту ${this.stateNumber}<br>с ${timeBegin} по ${timeEnd}`;

      if (this.polyline) {
        leafletMain.setLatLngsPolylyne({
          polyline: this.polyline,
          latlngs,
          text: lineText,
        });
      } else {
        const lineOptions = {
          color: 'rgba(255, 0, 0, 0.4)',
          weight: 7,
        };

        this.polyline = leafletMain.addPolyline({
          latlngs,
          options: lineOptions,
          text: lineText,
        });
      }
    }

    if (this.polyline && !selectionIndexes) {
      leafletMain.dropPolline(this.polyline);
      this.polyline = null;
    }
  }

  getLatLngsInterval({ allValues, latLonDelimeter, iBegin, iEnd } = {}) {
    const latlngs = [];

    for (let ii = iBegin; ii < iEnd + 1; ii++) {
      const { lat, lon } = allValues[ii] || {};
      if (lat && lon) {
        latlngs.push([lat / latLonDelimeter, lon / latLonDelimeter]);
      }
    }

    return latlngs;
  }

  addEventListeners() {
    this.chartSubelements.trackChartSettingHeight.addEventListener('input', {
      handleEvent: this.inputHeightEvent,
      that: this,
    });

    this.chartSubelements.trackChartSettingHeight.addEventListener('change', {
      handleEvent: this.changeHeightEvent,
      that: this,
    });

    this.chartSubelements.trackChartSelectNew.addEventListener('change', {
      handleEvent: this.trackChartSelectNewValueChange,
      that: this,
    });

    this.trackChartsSettingIframe.contentWindow.addEventListener('resize', {
      handleEvent: this.trackChartsSettingIframeResize,
      that: this,
    });

    this.chart.chart._chartAreaElement
      .node()
      .addEventListener('graphLineMoved', {
        handleEvent: this.graphLineMovedEvent,
        that: this,
      });
  }

  removeEventListeners() {
    this.chartSubelements.trackChartSettingHeight.removeEventListener('input', {
      handleEvent: this.inputHeightEvent,
      that: this,
    });

    this.chartSubelements.trackChartSettingHeight.removeEventListener(
      'change',
      {
        handleEvent: this.changeHeightEvent,
        that: this,
      },
    );

    this.chartSubelements.trackChartSelectNew.removeEventListener('change', {
      handleEvent: this.trackChartSelectNewValueChange,
      that: this,
    });

    this.trackChartsSettingIframe.contentWindow.removeEventListener('resize', {
      handleEvent: this.trackChartsSettingIframeResize,
      that: this,
    });

    this.chart.chart._chartAreaElement
      .node()
      .removeEventListener('graphLineMoved', {
        handleEvent: this.graphLineMovedEvent,
        that: this,
      });
  }

  destroy() {
    if (this.marker) {
      this.leafletMain.dropMarker(this.marker);
    }
    if (this.polyline) {
      this.leafletMain.dropPolline(this.polyline);
    }
    this.removeEventListeners();
    // if (this.enhancer) {
    //     this.enhancer.destroy();
    // }
    this.chart.destroy();
    // this.enhancer = null;
    this.chart = null;
  }
}
