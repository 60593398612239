<template>
  <loading-content v-if="!isReady" />

  <div v-if="!userData.isAuth && shownModule !== 'trackMotion'">
    <authorization-page @success-auth="setAuth" />
  </div>

  <template v-if="userData.isAuth && shownModule === 'monitoring'">
    <div
      v-show="userData.isAuth"
      class="monitoring-content"
    >
      <app-header
        :show-window="openWindow"
        @show-window-handler="showWindowHandler"
      ></app-header>

      <monitoring-layout
        v-show="openWindow.indexOf('monitoring') > -1"
        v-model:open-window="openWindow"
        :clientWidth="clientWidth"
        @ready="isReady = true"
      />

      <diagnostics-layout
        v-show="openWindow.indexOf('diagnostics') > -1"
        v-model:open-window="openWindow"
        :clientWidth="clientWidth"
        @ready="isReady = true"
      />

      <div
        id="background-wrap"
        class="background-wrap"
        @click="showWindowHandler(prevOpenWindow)"
      ></div>

      <logout-page
        :show="showLogoutWindow"
        @close="showWindowHandler(prevOpenWindow)"
        @logout="logoutHandler"
        @to-manual="showManual"
      ></logout-page>

      <div
        id="track-delete-confirm"
        style="display: none"
      >
        <!-- Картинка крестика-->
        <img
          class="close"
          src="/images/close.png"
        />
        <div class="full-height text-center">
          <p data-element="text">Удалить трек по объекту?</p>
          <button
            data-element="confirm"
            class="btn btn-primary config-btn-primary"
          >
            Удалить
          </button>
        </div>
      </div>

      <div
        v-show="openWindow === 'reports-detail'"
        id="section-reports-detail"
        class="row"
      >
        Это секция выгрузки детализации
      </div>

      <div
        v-show="openWindow === 'graphics'"
        id="section-graphics"
        class="row"
      >
        <graphics-layout />
      </div>

      <div
        v-if="$options.SERVICE !== 'Doosan'"
        v-show="openWindow === 'violations'"
        id="section-violations"
        style="width: 100%; height 100%; display: none"
      >
        <violations-graphics-layout></violations-graphics-layout>
      </div>

      <report-template :user-role="projectData.userRole"></report-template>

      <div
        v-show="openWindow === 'reports'"
        id="section-reports"
        class="row"
      >
        Это секция выгрузки суммарных отчетов
      </div>
    </div>

    <Transition name="fade">
      <map-modal
        v-if="mapModalInit"
        :show="mapModalShow"
        :id="mapModalData.data.objId"
        :is-roughly="mapModalData.data.isRoughly"
        :obj-name="mapModalData.data.objName"
        :violations="mapModalData.data.violations"
        :interval="mapModalData.data.interval"
        @close="сloseMapModalHandler('close')"
        @not-show="сloseMapModalHandler('not-show')"
        @reinit="сloseMapModalHandler('reinit')"
      />
    </Transition>
  </template>

  <manual-app v-if="userData.isAuth && shownModule === 'manual'" />

  <track-motion
    v-if="shownModule === 'trackMotion'"
    :params="trackMotionParams"
  />

  <Transition name="fade">
    <authorization-repeat-modal
      v-if="isAuthRepeat && !!projectData.login"
      :error="prevError"
      :login="projectData.login"
      :closeIcon="false"
      @success-auth="setAuth"
      @logout="logoutHandler"
    />
  </Transition>

  <the-toaster ref="toaster" />
</template>

<script>
import { computed, nextTick, reactive, ref, watch } from 'vue';
import AuthorizationPage from './App/Authorization/AuthorizationPage.vue';
import AppHeader from './App/Header/AppHeader.vue';
import MonitoringLayout from './App/Monitoring/MonitoringLayout.vue';
import DiagnosticsLayout from './App/Diagnostics/DiagnosticsLayout.vue';
import VueGeofences from './App/GeofencesComponents/VueGeofences.vue';
// import RegularMovingByGeoTemplate from "./App/TemplateComponents/RegularMoving/RegularMovingByGeoSetting.vue"
import ReportTemplate from './App/TemplateComponents/ReportTemplate.vue';
import GeoInOutTemplate from './App/TemplateComponents/GeoInOut/GeoInOutSetting.vue';
// import EvacuatorMovingTemplate from "./App/TemplateComponents/EvacuatorMoving/EvacuatorMovingTemplate.vue"
import ViolationsGraphicsLayout from './App/ViolationsGraphics/ViolationsGraphicsLayout.vue';
import GraphicsLayout from './App/Graphics/GraphicsLayout.vue';
import LogoutPage from './App/Logout/LogoutPage.vue';
import Modal from './App/ViolationsGraphics/components/Modal.vue';
import AuthorizationRepeatModal from './App/Authorization/AuthorizationRepeatModal.vue';
import LoadingContent from './App/Components/LoadingContent.vue';
import MapModal from './App/Components/MapModal.vue';
import ManualApp from './ManualApp/ManualApp.vue';
import TrackMotion from './TrackMotion/TrackMotion.vue';

import TheToaster from '@/App/Components/toaster/TheToaster.vue';
import { useRefetch } from '@/ManualApp/use/refetch';

import { STORE } from '@/ManualApp/STORAGE/STORAGE';

import { accessToken } from './src/jwtSender';
import { decodeMessage } from '@/App/use/encrypter';
import { flatArrayToPairs } from '@/helpers/main_helper'

export default {
  name: 'App',
  components: {
    AuthorizationPage,
    AppHeader,
    MonitoringLayout,
    DiagnosticsLayout,
    VueGeofences,
    // RegularMovingByGeoTemplate,
    ReportTemplate,
    GeoInOutTemplate,
    ViolationsGraphicsLayout,
    // EvacuatorMovingTemplate
    GraphicsLayout,
    LogoutPage,
    AuthorizationRepeatModal,
    Modal,
    LoadingContent,
    MapModal,
    ManualApp,
    TrackMotion,
    TheToaster,
  },

  SERVICE: process.env.VUE_APP_SERVICE_MODE === 'doosan' ? 'Doosan' : 'MAN',

  data() {
    return {
      showToasterManualMessage: false,
      isReady: false,
      shownModule: 'monitoring',
      isAuthRepeat: false,
      prevError: 'Требуется повторная авторизация',
      prevOpenWindow: '',
      openWindow: 'monitoring',
      showLogoutWindow: false,
      projectData: {
        title: 'Monitoring',
        description: 'ГЛОНАСС мониторинг',
        isAdmin: false,
        login: '',
        redirectUrl: '',
        senderRights: {},
        userRole: '',
        token1c: '',
        token1c_time: '',
        templateTimeIntervals: [],
      },
      userData: {
        error: '',
        isAuth: false,
        senderRights: {},
        user: {},
      },
      mapModalInit: false,
      mapModalShow: false,
      mapModalData: {
        trigger: 0,
        data: {
          objId: '',
          isRoughly: false,
          objName: '',
          violations: [],
          interval: [],
        },
      },
      trackMotionParams: {},
      oldMapModalData: {},

      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      globalObjectsList: {}, // модифицируются внутри vueApp/Monitoring/Sidebar/components/ObjectsList.vue
      monitoringCheckedObjectsList: [], // модифицируются внутри src/App/Monitoring/Sidebar/MonitoringSidebar.vue
      objectsListUpdateTrigger: 0,
      authorizatedUserRights: {},
      geofencesUpdating: {
        flag: false,
      },
      allGeofences: [], // модифицируются внутри VueGeofences
      geofenceTypes: [
        // создано как на сервере
        { value: 0, text: 'нет' },
        { value: 1, text: 'База' },
        { value: 2, text: 'Пункт отправления' },
        { value: 3, text: 'Пункт назначения' },
        { value: 4, text: 'Пункт контроля' },
        { value: 5, text: 'Место отдыха' },
        { value: 6, text: 'Зона тарификации' },
        { value: 7, text: 'С/х поле' },
        { value: 8, text: 'Выполнение работ' },
      ],
    };
  },
  async mounted() {
    watch(() => STORE.additionals, (newVal, oldVal) => {
      if (!this.showToasterManualMessage) {
        this.showToasterManualMessage = true
      }
    }, { deep: true });
    window.service_mode = process.env.VUE_APP_SERVICE_MODE;
    if (
      process.env.VUE_APP_SERVICE_MODE == 'doosan' &&
      document.documentElement.clientWidth > 450
    ) {
      this.openWindow = 'monitoring';
    } else if (document.documentElement.clientWidth > 450) {
      this.openWindow = 'violations';
    } else {
      this.openWindow = 'monitoring-sidebar';
    }

    window.addEventListener('resize', (event) => {
      this.clientWidth = document.documentElement.clientWidth;
      this.clientHeight = document.documentElement.clientHeight;
    });

    const isTrackMotion = this.checkUrlForTrackMotion();

    if (isTrackMotion) {
      this.shownModule = 'trackMotion';
      this.isReady = true;
      return;
    }

    if (document.location.pathname.indexOf('/manual') === 0) {
      this.shownModule = 'manual';
    }

    try {
      if (!localStorage.hasOwnProperty('access_token')) {
        this.userData.isAuth = false;
        return;
      }

      const token = decodeMessage(localStorage.getItem('access_token'));
      const res = await fetch(
        `${
          process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
        }Api/auth/check`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ).catch(e => {
        console.error(e);
        return false;
      });

      if (!res) {
        return;
      }

      const data = await res.json();

      if (!data['success']) {
        console.error(data['prevError']);
        return;
      }
      const obj = {
        success: data['success'],
        login: data['login'],
        role: data['role'],
        token: data['accessToken'],
        rights: data['senderRights'],
        token1c: data['token1c'],
        token1c_time: Number(data['token1c_time']) * 1000,
        templateTimeIntervals: data['templateTimeIntervals'],
      };

      this.setAuth(obj);
    } catch (e) {
      console.error(e);
    } finally {
      accessToken.value.openLogoutWindowHandler = this.openLogoutWindowHandler;
      this.isReady = true;
      // this.addBeforeUnloadListener();
    }

  },
  provide() {
    return {
      SERVICE: this.$options.SERVICE,
      mapModalData: reactive(this.mapModalData),
      prevError: this.prevError,
      projectData: reactive(this.projectData),
      userData: reactive(this.userData),
      globalObjectsList: reactive(this.globalObjectsList), // модифицируются внутри vueApp/Monitoring/Sidebar/components/ObjectsList.vue
      monitoringCheckedObjectsList: ref(this.monitoringCheckedObjectsList), // модифицируются внутри src/App/Monitoring/Sidebar/MonitoringSidebar.vue
      objectsListUpdateTrigger: ref(this.objectsListUpdateTrigger),
      authorizatedUserRights: computed(() => this.authorizatedUserRights),
      allGeofences: this.allGeofences, // модифицируются внутри VueGeofences
      geofenceTypes: this.geofenceTypes,
      geofencesUpdating: this.geofencesUpdating,
      setGeofencesUpdating: this.setGeofencesUpdating,
      isGeofencesUpdating: this.isGeofencesUpdating,
      isEvacuatorMovingTemplateShow: this.isEvacuatorMovingTemplateShow,
    };
  },
  watch: {
    showToasterManualMessage: {
      handler() {
        this.$refs.toaster.error({
          message: 'У вас имеются оконченные соглашения в мануале.',
          delay: 20000
        });
      },
    },
    clientWidth(width) {
      const isNotMonitoring = ![
        'monitoring',
        'monitoring-sidebar',
        'monitoring-map',
      ].includes(this.openWindow);

      if (isNotMonitoring) {
        if (width < 450 && this.openWindow === 'violations') {
          this.openWindow = 'monitoring-sidebar';
        }

        return;
      }

      if (width > 450) {
        this.openWindow = 'monitoring';
      } else {
        this.openWindow = 'monitoring-sidebar';
      }
    },
    mapModalData: {
      handler(obj, oldObj) {
        const interval = obj.data.interval;

        if (
          !obj.data.objId ||
          !obj.data.objName ||
          !this.validateDateHandler(interval[0]) ||
          !this.validateDateHandler(interval[1])
        )
          throw new Error('mapModalData is not valid');

        this.mapModalInit = true;
        this.mapModalShow = true;
      },
      deep: true,
    },
    projectData: {
      handler(obj) {
        document.title = obj.title;
        document.description = obj.description;

        this.isAuthRepeat = obj.redirectUrl === 'Api/auth';
      },
      deep: true,
      immediate: true,
    },
    openWindow: {
      async handler(openWindow) {
        try {
          const monitoringNode = document.getElementById('section-monitoring');
          const sidebarNode = document.getElementById(
            'section-monitoring-sidebar',
          );
          const mapNode = document.getElementById('section-monitoring-map');

          if (openWindow === 'monitoring') {
            monitoringNode.style.display = 'flex';
            sidebarNode.style.display = 'block';
            mapNode.style.display = 'block';
            return;
          }
          if (openWindow === 'monitoring-sidebar') {
            monitoringNode.style.display = 'block';
            sidebarNode.style.display = 'block';
            mapNode.style.display = 'none';
            return;
          }
          if (openWindow === 'monitoring-map') {
            monitoringNode.style.display = 'block';
            sidebarNode.style.display = 'none';
            mapNode.style.display = 'block';
            return;
          }

          monitoringNode.style.display = 'none';
          sidebarNode.style.display = 'none';
          mapNode.style.display = 'none';
        } catch (error) {}
      },
    },
  },
  methods: {    
    addBeforeUnloadListener() {
      window.onbeforeunload = function () {
        return 'Данные не сохранены. Вы уверены, что хотите уйти?';
      };
    },
    removeBeforeUnloadListener() {
      window.onbeforeunload = () => {};
    },
    checkUrlForTrackMotion() {
      const { pathname, search } = window.location;

      if (pathname === '/track-motion') {
        const urlSearchParams = new URLSearchParams(window.location.search);
        this.trackMotionParams = Object.fromEntries(urlSearchParams.entries());

        return true;
      }

      return false;
    },
    showManual() {
      this.showWindowHandler('');

      const { rule_contract_watch, rule_contractor_watch, rule_vehicle_watch } =
        this.userData.senderRights;

      if (rule_contractor_watch) return window.open(`/manual/contractors`);
      if (rule_vehicle_watch) return window.open(`/manual/vehicles`);
      if (rule_contract_watch) return window.open(`/manual/contracts`);

      this.$refs.toaster.error({
        message: 'Нет прав для просмотра справочника',
      });
    },
    async сloseMapModalHandler(str) {
      if (str === 'close') {
        this.mapModalInit = false;
        this.mapModalShow = false;
        return;
      }
      if (str === 'reinit') {
        this.mapModalInit = false;
        await nextTick();
        this.mapModalInit = true;
        return;
      }
      if (str === 'not-show') this.mapModalShow = false;
    },
    validateDateHandler(date) {
      return isNaN(Date.parse(date));
    },
    setGeofencesUpdating(flag) {
      this.geofencesUpdating.flag = flag;
    },
    setAuth(obj) {
      const { success, login, role, token, rights, token1c, token1c_time, templateTimeIntervals } = obj;
      this.projectData.login = login;
      this.projectData.isAdmin = this.isAdmin(role);
      this.projectData.userRole = role;
      this.projectData.senderRights = rights;
      this.projectData.token1c = token1c;
      this.projectData.token1c_time = token1c_time;
      this.projectData.templateTimeIntervals = flatArrayToPairs(templateTimeIntervals);
      this.userData.isAuth = success;
      this.userData.senderRights = rights;

      this.authorizatedUserRights = rights;

      accessToken.value.token = token;
      accessToken.value.isLogout = false;

      if (!this.showToasterManualMessage) {
        useRefetch.allAdditionals();
      }

      if (this.isAuthRepeat === false) return;

      this.projectData.redirectUrl = '';
      this.isAuthRepeat = false;
    },
    isAdmin(role) {
      return ['main_admin', 'admin', 'customer_admin'].includes(role);
    },
    showWindowHandler(str) {
      const bgWrap = document.getElementById('background-wrap');
      if (['logout'].includes(str)) {
        this.showLogoutWindow = true;
        bgWrap.style.display = 'block';
        this.prevOpenWindow = this.openWindow;
      } else {
        this.showLogoutWindow = false;
        bgWrap.style.display = 'none';
        this.prevOpenWindow = this.openWindow;
        this.openWindow = str || this.prevOpenWindow;
      }
    },
    logoutHandler() {
      localStorage.clear();
      // this.removeBeforeUnloadListener();

      this.$nextTick().then(() => {
        location.href = '/';
      });
    },
    openLogoutWindowHandler() {
      this.isAuthRepeat = true;
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/config.scss';
.monitoring-content {
  overflow: hidden;
  max-height: 100vh;
  max-width: 100vw;
}
</style>
