<template>
  <div
    v-if="isReady"
    ref="mainRef"
  >
    <!-- <button
      class="not-print float-left"
      onclick="html_to_excel_helper.export( this, 'xml-rude-violations-template-content', 'Список грубых нарушений по обьектам МАН', 'portrait' )"
    >
      Экспорт&nbsp;в&nbsp;EXCEL
    </button> -->
    <button class="not-print not-excel float-left" 
      @click="exportXml()">
      
      Экспорт&nbsp;в&nbsp;XML 
    </button>

    <div
      class="temlate-report"
      id="xml-rude-violations-template-content"
    >
      <div data-excelsheet="Критические показатели"></div>
      <h6 class="footer-desc text-center font-weight-bold">
        Список грубых нарушений по обьектам за период с {{ data?.TimeBegin }} по {{ data?.TimeEnd }}
      </h6>
      <p class="text-center excel-bottom-indent">
        Отчет сформирован {{ formatTimeHelper2(new Date()) }}
      </p>
      <table-violations-list
        :title="`Список грубых нарушений за период с ${data?.TimeBegin} по ${data?.TimeEnd}`"
        :objects="data.objectsSummStat"
      />
    </div>
  </div>
</template>

<script setup>
import { inject, nextTick, ref, watch } from 'vue';
import axios from 'axios';
import TableViolationsList from './tables/TableViolationsList.vue';
import { XMLParser } from 'fast-xml-parser';

import { getBaseHttpUrl_handler } from '../../../helpers/getBaseHttpUrl_handler'
import { getTzh,formatTimeHelper2 } from '@/helpers/main_helper';

const projectData = inject('projectData');

const props = defineProps({
  trigger: { type: Boolean, required: true },
  form_desc: { type: Object }
});

const emit = defineEmits(['close']);

const mainRef = ref(null);
const isReady = ref(false);

const areaId = 'section-reports';

const data = ref({
  objectsSummStat: [],
  TimeBegin: '',
  TimeEnd: '',
});

const xmlExport = ref('')

const interval = ref({});

const execute = () => {
  // запрос позиций и подсчет значений по отчету
  interval.value = objectsListFormSerializeArray_helper('objectsListForm');
  const url = getBaseHttpUrl_handler();
  const { targetSpan: targetElement = {}, button: buttonElement = {} } = objectsListFormColorAndTarget_helper(props.form_desc);

  infoShowText_helper(targetElement, `Запрос отправлен...`, areaId);
  setElementColor_helper(buttonElement, 'blue');

  axios
    .post(url + process.env.VUE_APP_TEMPLATE_SERVICE_HTTP_PATH + 'CriticalViolations/XML', {
      ids: props.form_desc.objects,
      time_begin: props.form_desc.beginTime / 1000,
      time_end: props.form_desc.endTime / 1000,
      token: projectData.token1c, 
      option: 0,
      tzh: getTzh(),
    })
    .then(async (res) => {
      const parser = new XMLParser();
      const jObj = parser.parse(res.data);
      jObj.mon.responseInterval = {};
      jObj.mon.responseInterval.from = new Date(jObj.mon.TimeBegin);
      jObj.mon.responseInterval.to = new Date(jObj.mon.TimeEnd);
      jObj.mon.reportName = 'Список грубых нарушений по обьектам МАН';
      jObj.mon.xmlString = res.data;

      if (!Array.isArray(jObj.mon.Objlist.Obj)) {
        const item = { ...jObj.mon.Objlist.Obj };
        jObj.mon.Objlist.Obj = [item]
      }

      const params = {
        targetElement,
        buttonElement
      };

      calculated(jObj.mon, params)
    }).catch(e => {
      let text = 'Произошла ошибка при отправке данных, попробуйте перезагрузить страницу...'
      if (e.response) {
        const parser = new XMLParser();
        const jObj = parser.parse(e.response.data);
        if (jObj.error === 'token not found' || jObj.error === 'Токен не найден') {
          text = 'Для доступа к отчету сгенерируйте токен или обратитесь к Вашему поставщику услуг'
        } 
        if (jObj.error === 'Время использования токена истекло') {
          text = 'Время использования токена истекло. Cгенерируйте токен или обратитесь к Вашему поставщику услуг'
        }
      }
      
      infoShowText_helper(targetElement, text, areaId);
      setElementColor_helper(buttonElement, 'red');
    }) 
};

watch(() => props.trigger, execute);

const calculated = (
  objectsCalculated,
  { targetElement, buttonElement } = {},
) => {
  let { xmlString } =
    objectsCalculated;
    xmlExport.value = xmlString;

  calculate(objectsCalculated, { targetElement, buttonElement });
};
const calculate = async (tableSumm, { targetElement, buttonElement } = {}) => {
  infoShowText_helper(targetElement, 'Ответ получен, подготовка значений...', areaId);

  const templateContentElement = document.getElementById('section-reports');

  // включаем отображене - вью наполнит данными
  templateContentElement.innerText = '';

  data.value = tableSumm;
  data.value.objectsSummStat = tableSumm.Objlist.Obj
  isReady.value = true;

  await nextTick();

  // переносим в ID где отчеты
  templateContentElement.append(mainRef.value);

  infoShowText_helper(
    targetElement,
    'Суммарный отчет по критическим нарушениям построен. Выполнить новый запрос?',
  );

  setElementColor_helper(buttonElement, 'green');
};

const exportXml = () => {
  html_to_xml_helper.exportFile( this, xmlExport.value, 'Список грубых нарушений по обьектам МАН')
};

</script>

<style lang="scss" scoped></style>
