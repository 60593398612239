<template>
  <div
    class="float-right copy-link"
    style="position: sticky; top: 0; right: 0"
  >
    <span
      v-if="tableTextSwitcher.show === 'text'"
      @click="copyTable"
    >
      Скопировать таблицу
    </span>

    <button
      class="btn btn-primary config-btn-primary btn-sm ml-2 mt-1"
      style="min-width: 210px"
      @click="toggleTableText"
    >
      {{ tableTextSwitcher.text }}
    </button>

    <div
      v-if="tableTextSwitcher.show === 'text'"
      class="pt-2"
    >
      <ui-pagination
        :page-number="pageNumber"
        :pages-length="pagesLength"
        @changeValue="setPage"
      />
    </div>
  </div>
  <table
    v-if="tableTextSwitcher.show === 'table'"
    class="table-simple excel-bottom-indent"
  >
    <caption class="caption-top">
      Таблица по критическим нарушениям
    </caption>
    <tbody>
      <tr
        v-for="obj in props.objects"
        style="border: 1px solid #000"
        :key="obj.id"
      >
        <th
          data-excelalignh="center"
          v-html="obj.avtoNo"
        ></th>
        <td data-excelalignh="center">
          <div 
            v-if="Number(obj.roughlyViolationsCount) > 0"
            v-for="(value, key) in objectViolations[obj.id]" :key="key"
            class="flex violations-table__item"
          >
            <div>{{ value.name }}</div>
            <div style="width: 100px; text-align: center">
              {{ value.value }}
            </div>
          </div>
          
        </td>
      </tr>
    </tbody>
  </table>

  <div id="for-copy"></div>

  <the-toaster ref="toaster" />
</template>

<script setup>
import { computed, onBeforeUnmount, ref } from 'vue';
import TheToaster from '@/App/Components/toaster/TheToaster.vue';
import UiPagination from '@/App/Components/UiPagination.vue';

const toaster = ref(null);

const props = defineProps({
  title: { type: String, required: true },
  objects: { type: Object, required: true },
});

const VIOLATIONS = {
  tempOilAndAntifreeze: 'Уровень масла и ОЖ',
  rightGearForStart: 'Правильный выбор передач для трогания',
  timothyInManualMode: 'Типматик в ручном режиме',
  clutchSlipStart: 'Трогание с излишней пробуксовкой сцепления',
  timothyChangingD_Dx: 'Смена диапазона типматика D/Dx',
  slipping: 'Буксование (пережег сцепления, разогрев до 100 градусов)',
  excessRpm: 'Превышение допустимых оборотов ДВС',
  moveLockCrossWheel: 'Движение с включенной межколесной блокировкой',
  swithClutchInsufficientCntG: 'Для механической КПП: неполное включение сцепления',
  swithGearBoxRewersSpdCnt: 'Переключение КПП с переднего на задний ход при скорости > 0',
  switchLock: 'Включение/выключение блокировок (на скорости или с нажатой педалью газа)',
  switchLower: 'Вкл/выкл пониженной передачи РКП (на скорости или с нажатой педалью газа)',
  ptoSpdDistanceG: 'Движение с включенным КОМ',
  back_raised: 'Движение с поднятым кузовом',
}

const objectViolations = computed(() => {
  const res = {}
  for (let i in props.objects) {
    const obj = props.objects[i]
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (VIOLATIONS[key] !== undefined && value > 0) {
          if (!res[obj.id]) {
            res[obj.id] = {}
          }
          res[obj.id][key] = {value, avtoNo: obj.avtoNo, name: VIOLATIONS[key] }
        }
        key = props.objects[key];
      }
    }
  }
  return res
});

const emit = defineEmits([]);

const tableTextSwitcher = ref({
  show: 'table',
  text: 'Открыть текст для копирования',
});

const pageNumber = ref(-1);
const pagesLength = ref(0);

const pagesTextArray = ref([]);

onBeforeUnmount(() => {
  document.getElementById('for-copy').innerHTML = '';
});

const toggleTableText = () => {
  if (tableTextSwitcher.value.show === 'table') {
    tableTextSwitcher.value.show = 'text';
    tableTextSwitcher.value.text = 'Открыть таблицу';
    setPagesTextArray();
    setPage(0);
  } else {
    tableTextSwitcher.value.show = 'table';
    tableTextSwitcher.value.text = 'Открыть текст для копирования';
    document.getElementById('for-copy').innerHTML = '';
  }
};

const setPagesTextArray = () => {
  let pageIndex = 0;
  let avtoNo = ''
  let commitedViolations = [`${props.title}</br></br>`]
  for (const key in objectViolations.value) {
    if (!objectViolations.value.hasOwnProperty(key)) {
      continue
    }
    const obj = objectViolations.value[key]

    let violationsHtml = ''
    let objKeys = Object.keys(obj)
    let obj2Index = 0
    for (const key2 in obj) {
      const violation = obj[key2]
      violationsHtml += `${violation.name} - ${violation.value}`;
      avtoNo = violation.avtoNo
      if (obj2Index < objKeys.length - 1) {
        violationsHtml += ', </br>';
      } else {
        violationsHtml += '; </br>';
      }
      obj2Index++
    }

    const newStringWithObjectViolations = `${avtoNo}: </br>${violationsHtml} </br>`;

    if (
      commitedViolations[pageIndex].length + newStringWithObjectViolations.length >=
      4096
    ) {
      pageIndex++;
      commitedViolations[pageIndex] = '';
    }

    commitedViolations[pageIndex] += newStringWithObjectViolations;
  }
  pagesTextArray.value = commitedViolations
  pagesLength.value = pagesTextArray.value.length;
};

const setPage = (index) => {
  document.getElementById('for-copy').innerHTML = pagesTextArray.value[index];
  pageNumber.value = index;
};

const copyTable = async () => {
  const htmlForCopy = document.getElementById('for-copy');

  copyWithStyle(htmlForCopy);
  toaster.value.success({ message: 'Текст успешно скопирован', delay: 2000 });
};

const copyWithStyle = (element) => {
  const doc = document;
  let range;
  let selection;

  if (doc.body.createTextRange) {
    range = doc.body.createTextRange();
    range.moveToElement(element);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();

    range = doc.createRange();
    range.selectNodeContents(element);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  document.execCommand('copy');
  window.getSelection().removeAllRanges();
};
</script>

<style lang="scss" scoped>
.copy-link {
  cursor: pointer;

  &:hover {
    color: #4a5156;
  }
}
.violations-table__item {
  justify-content: space-between;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
}
</style>
