<template>
  <div
    v-if="isReady"
    ref="mainRef"
  >
    <button
      class="not-print float-left"
      onclick="html_to_excel_helper.export( this, 'critical-violations-template-content', 'Критические показатели МАН', 'portrait' )"
    >
      Экспорт&nbsp;в&nbsp;EXCEL
    </button>
    <div
      class="temlate-report"
      id="critical-violations-template-content"
    >
      <div data-excelsheet="Критические показатели"></div>
      <h6 class="footer-desc text-center font-weight-bold">
        Отчет по критическим показателям навыков вождения
      </h6>
      <h6 class="footer-desc text-center font-weight-bold">
        {{ data?.t_interval }}
      </h6>
      <p class="text-center excel-bottom-indent">
        Отчет сформирован {{ formatTimeHelper2(new Date()) }}
      </p>

      <table-template
        :title="'Таблица по критическим нарушениям'"
        :body-clicked-rows="summClickedRows"
        :header-setting="headerSummSetting"
        :rows="data.rows"
        @show-popup="showPopup"
      />

      <table-template
        :title="'Пробег и критические нарушения по объектам'"
        :header-clicked-rows="objectsClickedRows.header"
        :body-clicked-rows="objectsClickedRows.body"
        :header-setting="headerObjectsSetting"
        :rows="data.objectsSummStat"
        @show-popup="showPopup"
      />

      <table class="excel-not-border">
        <tbody>
          <tr v-for="row in getRoughlyObjectsValue(data.roughlyObjects)">
            <td
              data-excelcolspan="3"
              v-html="row"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Список грубых нарушений по объектам -->
  <transition name="fade">
    <my-modal
      v-if="popups.objectsViolationsList"
      @close="closePopup('objectsViolationsList')"
    >
      <template #header>
        <div></div>
        <div>Список грубых нарушений по объектам {{ data?.t_interval }}</div>
        <ui-close-button @click="closePopup('objectsViolationsList')" />
      </template>
      <template #body>
        <table-violations-list
          :title="`Список грубых нарушений ${data?.t_interval}`"
          :objects="data.objectsSummStat"
        />
      </template>
    </my-modal>
  </transition>

  <!-- Таблица грубых нарушений по объекту -->
  <transition name="fade">
    <my-modal
      v-if="popups.objectViolationsTable"
      @close="closePopup('objectViolationsTable')"
    >
      <template #header>
        <button
          class="not-print float-left"
          onclick="html_to_excel_helper.export( this, 'violations-table-of-object', 'Критические показатели МАН', 'portrait' )"
        >
          Экспорт&nbsp;в&nbsp;EXCEL
        </button>

        <h6 class="footer-desc text-center font-weight-bold">
          {{ objectViolationsTableTitle }}
        </h6>

        <ui-close-button @click="closePopup('objectViolationsTable')" />
      </template>
      <template #body>
        <div
          class="temlate-report"
          id="violations-table-of-object"
        >
          <h6 class="footer-desc text-center font-weight-bold d-none">
            {{ objectViolationsTableTitle }}
          </h6>
          <p class="text-center excel-bottom-indent">
            Отчет сформирован {{ formatTimeHelper2(new Date()) }}
          </p>
          <table-violations-template
            :clicked-rows="objectClickedRows"
            :object="popupsData.objectViolationsTable"
            @show-popup="showPopup"
          />
        </div>
      </template>
    </my-modal>
  </transition>

  <!-- Таблица объектов по грубому нарушению -->
  <transition name="fade">
    <my-modal
      v-if="popups.violationObjectsTable"
      @close="closePopup('violationObjectsTable')"
    >
      <template #header>
        <button
          class="not-print float-left"
          onclick="html_to_excel_helper.export( this, 'objects-table-of-violation', 'Критические показатели МАН', 'portrait' )"
        >
          Экспорт&nbsp;в&nbsp;EXCEL
        </button>

        <h6 class="footer-desc text-center font-weight-bold">
          {{ violationObjectsTitle }}
        </h6>
        <ui-close-button @click="closePopup('violationObjectsTable')" />
      </template>
      <template #body>
        <div
          class="temlate-report"
          id="objects-table-of-violation"
        >
          <h6 class="footer-desc text-center font-weight-bold d-none">
            {{ violationObjectsTitle }}
          </h6>
          <p class="text-center excel-bottom-indent">
            Отчет сформирован {{ formatTimeHelper2(new Date()) }}
          </p>
          <table-template
            :header-clicked-rows="violationObjectsClickedRows.header"
            :body-clicked-rows="violationObjectsClickedRows.body"
            :header-setting="headerViolationObjectsSetting"
            :rows="popupsData.violationObjects.rows"
            @show-popup="showPopup"
          />
        </div>
      </template>
    </my-modal>
  </transition>

  <!-- Детализация -->
  <transition name="fade">
    <violation-details-modal
      v-if="popups.violationsDetail.ready"
      :show="popups.violationsDetail.show"
      :is-critical-violations="true"
      :obj-id="popupsData.violationsDetail.id"
      :interval="popupsData.violationsDetail.interval"
      :violations="popupsData.violationsDetail.violations"
      :skill="popupsData.violationsDetail.skill"
      @close="closePopup('violationsDetail')"
    />
  </transition>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';

import TableTemplate from './tables/TableTemplate.vue';
import MyModal from '@/App/Components/MyModal.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';
import TableViolationsList from './tables/TableViolationsList.vue';
import TableViolationsTemplate from './tables/TableViolationsTemplate.vue';
import ViolationDetailsModal from '@/App/TemplateComponents/CriticalViolations/components/ViolationDetailsModal.vue';

import { OurAggregated } from '../../../src/dataRequest/ourAggregated';
import { skillsManTemplate } from '../../../Template/skills_man_template/skills_man_template';
import { violationsDescriptions } from '../../../src/dataRequest/mainScript';
import CriticalViolationsEntriesViolations from '@/App/TemplateComponents/CriticalViolations/CriticalViolationsEntriesViolations';
import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService';
import {formatTimeHelper2} from '@/helpers/main_helper'

const areaId = 'section-reports';

const roundTo = 10; // округление до 10-ти минут

const headerSummSetting = {
  DNum: {
    name: '№ п/п',
    width: 7,
  },

  DSkill: {
    name: 'Навык',
    width: 47,
  },

  DCount: {
    name: 'Количество',
    width: 24,
    format: 12,
  },

  headers: ['DNum', 'DSkill', 'DCount'],
};
const headerObjectsSetting = {
  avtoNo: {
    name: 'Объект',
    width: 20,
  },

  distance: {
    name: 'Пробег за период, км',
    width: 47,
    format: 13,
  },

  roughlyViolationsCount: {
    name: 'Число грубых нарушений',
    width: 24,
    format: 12,
  },

  roughlyViolationsCountRelative: {
    name: 'Число грубых нарушений на 100 км',
    width: 24,
    format: 13,
  },

  headers: [
    'avtoNo',
    'distance',
    'roughlyViolationsCount',
    'roughlyViolationsCountRelative',
  ],
};
const headerViolationObjectsSetting = {
  avtoNo: {
    name: 'Объект',
    width: 20,
  },

  distance: {
    name: 'Пробег за период, км',
    width: 47,
    format: 13,
  },

  DForfeits: {
    name: 'Число грубых нарушений',
    width: 24,
    format: 12,
  },

  DForfeitsRelative: {
    name: 'Число грубых нарушений на 100 км',
    width: 24,
    format: 13,
  },

  headers: ['avtoNo', 'distance', 'DForfeits', 'DForfeitsRelative'],
};
const objectsClickedRows = {
  header: ['roughlyViolationsCount'],
  body: ['distance', 'roughlyViolationsCount'],
};
const violationObjectsClickedRows = {
  header: [],
  body: ['distance', 'DForfeits'],
};
const objectClickedRows = ['DForfeits'];
const summClickedRows = ['DCount'];

const props = defineProps({
  trigger: { type: Boolean, required: true },
  form_desc: { type: Object }
});

const emit = defineEmits(['close']);

const mapModalData = inject('mapModalData');

const mainRef = ref(null);
const isReady = ref(false);

const popups = ref({
  objectsViolationsList: false,
  objectViolationsTable: false,
  violationObjectsTable: false,
  violationsDetail: { ready: false, show: false },
});

const data = ref({
  objectsSummStat: [],
  rows: [],
  roughlyObjects: [],
  t_interval: '',
});

const popupsData = ref({
  objectViolationsTable: {},
  objectTrack: {},
  violationsDetail: {},
  violationObjects: {},
});

const interval = ref({});

const execute = () => {
  // запрос позиций и подсчет значений по отчету
  interval.value = objectsListFormSerializeArray_helper('objectsListForm');
  const ourAggregated = new OurAggregated(skillsManTemplate);

  const templateName = 'criticalViolations';

  fetchToTemplateService({
    templateName,
    form_desc: props.form_desc,
    options: {
      option: 0,
      violationsDescriptionsDesc: violationsDescriptions.desc,
    },
    successCallback: calculated,
    errorCallback: () =>
      ourAggregated.callOurAggregated({
        smenasSeconds: [],
        option: 0,
        templateName,
        form_desc: props.form_desc,
        callback: calculated,
        roundTo,
        areaId: 'section-reports',
      }),
  });
};

const objectViolationsTableTitle = computed(
  () =>
    `Грубые нарушения ${popupsData.value.objectViolationsTable.name} ${data.value?.t_interval}`,
);

const violationObjectsTitle = computed(
  () =>
    `Объекты по грубому нарушению "${popupsData.value.violationObjects.title}" ${data.value?.t_interval}`,
);

watch(() => props.trigger, execute);

const calculated = (
  objectsCalculated,
  { targetElement, buttonElement } = {},
) => {
  let { tableSumm = false, roughlyObjects: roughlyObjectsValue = false } =
    objectsCalculated;

  if (!tableSumm || !roughlyObjectsValue)
    tableSumm = getTableSumm(objectsCalculated);

  calculate(tableSumm, { targetElement, buttonElement });
};
const calculate = async (tableSumm, { targetElement, buttonElement } = {}) => {
  infoShowText_helper(targetElement, 'Ответ получен, подготовка значений...', areaId);

  const templateContentElement = document.getElementById('section-reports');

  // включаем отображене - вью наполнит данными
  templateContentElement.innerText = '';

  data.value = tableSumm;
  isReady.value = true;

  await nextTick();

  // переносим в ID где отчеты
  templateContentElement.append(mainRef.value);

  infoShowText_helper(
    targetElement,
    'Суммарный отчет по критическим нарушениям построен. Выполнить новый запрос?'
  );

  setElementColor_helper(buttonElement, 'green');
};

const getTableSumm = (objectsCalculated) => {
  const { objects = [], rows_summ } = objectsCalculated;

  const [firstObj] = objects;

  const { t_interval } = firstObj.view;

  const rows = [];
  const violationIndex = {};
  const roughlyObjects = [];
  const objectsSummStat = [];

  for (let [key, value] of Object.entries(firstObj.rows_person.person)) {
    if (!value.IsRoughly) {
      continue;
    }

    const { DNum, DSkill } = value;
    const DCount = 0;

    violationIndex[key] = rows.length;

    const detailsViolations = CriticalViolationsEntriesViolations[
      DSkill
    ].reduce((acc, id) => {
      const violation = violationsDescriptions.desc.find(
        (obj) => +obj.id === id,
      );

      acc.push(violation);

      return acc;
    }, []);

    rows.push({
      key,
      DNum,
      DSkill,
      DCount,
      detailsViolations,
      objects: [],
    });
  }

  objects.forEach((object) => {
    const { avtoNo = '?', id, name, notData, rows_experiment = {} } = object;

    let isAdded = false;

    const objectSummStat = {
      name,
      avtoNo,
      id,
      notData,
      distance: rows_experiment.distance.val.val,
      roughlyViolationsCount: 0,
      roughlyViolationsCountRelative: 0,
      roughlyViolations: [],
    };

    rows.forEach((row) => {
      const { key } = row;
      const { DCount, DForfeits, DSkill } = object.rows_person.person[key];
      const index = violationIndex[key];

      objectSummStat.roughlyViolations.push(object.rows_person.person[key]);
      if (DCount > 0) {
        rows[index]['objects'].push({
          DSkill,
          id: objectSummStat.id,
          avtoNo,
          distance: objectSummStat.distance,
          DForfeits,
          DForfeitsRelative: (DForfeits / objectSummStat.distance) * 100,
        });
        rows[index]['DCount'] += DCount;
        objectSummStat.roughlyViolationsCount += DCount;

        if (!isAdded) {
          isAdded = true;

          roughlyObjects.push(object.avtoNo);
        }
      }
    });

    objectSummStat.roughlyViolationsCountRelative =
      objectSummStat.distance > 10
        ? (objectSummStat.roughlyViolationsCount / objectSummStat.distance) *
          100
        : 0;

    objectsSummStat.push(objectSummStat);
  });

  rows.sort(sort);
  objectsSummStat.sort(
    (a, b) =>
      b.roughlyViolationsCountRelative - a.roughlyViolationsCountRelative,
  );

  return {
    t_interval,
    rows,
    roughlyObjects,
    objectsSummStat,
  };
};

const sort = (a, b) => {
  const { DNum: DNumA } = a;
  const { DNum: DNumB } = b;

  const [firstA, secondA] = DNumA.split('.');
  const [firstB, secondB] = DNumB.split('.');

  if (Number(firstA) !== Number(firstB)) {
    return firstA - firstB;
  }

  return secondA - secondB;
};

const getRoughlyObjectsValue = (roughlyObjects) => {
  const rows = [];
  rows.push('Объекты с хотябы одним из критических нарушений за период:');
  rows.push('');
  const maxStrLength = 80;

  // data-excelcolspan="2";

  roughlyObjects.forEach((roughlyObject) => {
    const index = rows.length - 1;
    const indexLength = replaceNbsp_helper(rows[index]).length;

    if (indexLength + replaceNbsp_helper(roughlyObject).length < maxStrLength) {
      if (indexLength) {
        rows[index] += ', ';
      }

      rows[index] += roughlyObject;
    } else {
      rows.push(roughlyObject);
    }
  });
  return rows;
};

const setPopupsData = (id, name) => {
  popupsData.value[name] = data.value.objectsSummStat.find(
    (obj) => obj.id === id,
  );
};

const setTrackData = (id, skill = '') => {
  const { beginTime, endTime } = interval.value;

  const obj = data.value.objectsSummStat.find((obj) => obj.id === id);

  const violations = data.value.rows.reduce((acc, row) => {
    let violationsArray = row.detailsViolations;
    if (skill) {
      violationsArray = row.detailsViolations.filter((obj) =>
        CriticalViolationsEntriesViolations[skill].includes(+obj.id),
      );
    }
    acc = acc.concat(violationsArray);
    return acc;
  }, []);

  mapModalData.data = {
    objId: id,
    isRoughly: true,
    objName: obj.name,
    violations,
    interval: [beginTime, endTime],
  };
};

const setVilationsDetail = (skill, id) => {
  if (!id) {
    id = popupsData.value.objectViolationsTable.id;
  }
  const { beginTime, endTime } = interval.value;

  let violations;
  if (skill) {
    const { detailsViolations } = data.value.rows.find(
      (obj) => obj.DSkill === skill,
    );

    violations = detailsViolations;
  } else {
    violations = data.value.rows.reduce((acc, row) => {
      acc = acc.concat(row.detailsViolations);
      return acc;
    }, []);
  }
  popupsData.value.violationsDetail = {
    id,
    interval: [beginTime, endTime],
    violations,
    skill: skill || '',
  };
};

const showPopup = async ({ name = '', id = 0, skill = '' }) => {
  // таблицы
  if (name === 'roughlyViolationsCount') {
    if (id) {
      setPopupsData(id, 'objectViolationsTable');
      popups.value.objectViolationsTable = true;
      return;
    }

    popups.value.objectsViolationsList = true;
    return;
  }

  // треки
  if (name === 'distance') {
    if (popups.value.violationObjectsTable) {
      skill = popupsData.value.violationObjects.title;
    }
    setTrackData(id, skill);
    return;
  }

  // детализация
  if (name === 'DForfeits') {
    if (
      popupsData.value.violationsDetail.skill !== skill ||
      popupsData.value.violationsDetail.id !== id ||
      popupsData.value.violationsDetail.interval[0] !==
        interval.value.beginTime ||
      popupsData.value.violationsDetail.interval[1] !== interval.value.endTime
    ) {
      popups.value.violationsDetail.ready = false;
      await nextTick();
    }

    setVilationsDetail(skill, id);

    popups.value.violationsDetail.ready = true;
    popups.value.violationsDetail.show = true;
  }

  if (name === 'DCount') {
    const violation = data.value.rows.find((obj) => obj.DSkill === skill);

    popupsData.value.violationObjects.rows = violation.objects;
    popupsData.value.violationObjects.title = skill;

    popups.value.violationObjectsTable = true;
  }
};

const closePopup = (name) => {
  if (name === 'violationsDetail') {
    popups.value.violationsDetail.show = false;
    return;
  }
  popups.value[name] = false;
};
</script>

<style lang="scss" scoped></style>
