<template>
  <div>
    <div>
      <critical-violations
        v-if="showComponents.criticalViolations"
        :trigger="triggers.criticalViolationsTrigger"
        :form_desc="form_desc"
      />
      <x-m-l-rude-Violations
        v-if="showComponents.rudeViolationsXML"
        :trigger="triggers.rudeViolationsXMLTrigger"
        :form_desc="form_desc"
      />
      <skills-man-detail-settings
        v-show="showComponents.skillsManDetailSettings"
        :violationsDescriptions="violationsDescriptions"
        :userRole="userRole"
        :form_desc="form_desc"
        @close="close"
      />
      <shift-work-settings
        v-if="showComponents.shiftWorkTemplateSettings"
        :form_desc="form_desc"
        @close="close"
      />
      <excavator-operation-settings
        v-show="showComponents.specMachineTemplateSettings"
        :objects="form_desc.objects"
        :interval="{ from: form_desc.beginTime, to: form_desc.endTime }"
        :areaId="areaId"
        @display-report="(report) => displayReport('specMachine', report)"
        @close="() => close('specMachineTemplateSettings')"
      />
      <diagnostic-settings
        v-if="showComponents.diagnosticSettings"
        :objects="form_desc.objects"
        :interval="{ from: form_desc.beginTime, to: form_desc.endTime }"
        :reportName="form_desc.templateName"
        @display-report="
          (report) => displayReport(form_desc.templateName, report)
        "
        :areaId="areaId"
        :buttonId="buttonId"
        @close="() => close('diagnosticSettings')"
      />
      <oil-pressure-settings
        v-model="showComponents.oilPressureSettings"
        :reportName="form_desc.templateName"
        :objects="form_desc.objects"
        :interval="{ from: form_desc.beginTime, to: form_desc.endTime }"
        @display-report="
          (report) => displayReport(form_desc.templateName, report)
        "
        @close="() => close('oilPressureSettings')"
      />
    </div>
    <div>
      <evacuator-moving-template
        v-if="showComponents.evacuatorMovingTemplate"
        :trigger="triggers.evacuatorMovingTrigger"
        :buttonId="buttonId"
        :form_desc="form_desc"
      />
    </div>
    <div>
      <clients-monthly
        v-if="showComponents.clientsMonthlyTemplate"
        :trigger="triggers.clientsMonthlyTrigger"
        :buttonId="buttonId"
        :form_desc="form_desc"
      />
    </div>
    <div>
      <regular-moving-by-geo-template-setting
        v-show="showComponents.regularMovingByGeoTemplateSetting"
        @close="close"
        :is-showing="showComponents.regularMovingByGeoTemplateSetting"
        :form_desc="form_desc"
      />
    </div>
    <div>
      <overspeed-settings
        v-show="showComponents.overspeedSettings"
        :form_desc="form_desc"
        :is-showing="showComponents.overspeedSettings"
        :buttonId="buttonId"
        :areaId="areaId"
        @close="close"
      />
    </div>
    <div>
      <excavator-operation
        v-if="showComponents.specMachine"
        :data="displayedReportData['specMachine']"
      />
    </div>
    <div>
      <diagnostic-template
        v-if="showComponents.diagnostic"
        name="diagnostic"
        :data="displayedReportData['diagnostic']"
        areaId="#section-reports"
      />
      <diagnostic-template
        v-if="showComponents.diagnosticDetail"
        name="diagnosticDetail"
        :data="displayedReportData['diagnosticDetail']"
        areaId="#section-reports-detail"
      />
      <diagnostic-template
        v-if="showComponents.descriptionDiagnostic"
        name="descriptionDiagnostic"
        :data="displayedReportData['descriptionDiagnostic']"
        areaId="#section-reports"
      />

      <diagnostic-template
        v-if="showComponents.diagnostic2"
        name="diagnostic"
        :data="displayedReportData['diagnostic2']"
        areaId="#diagnostic-section-reports"
      />
      <diagnostic-template
        v-if="showComponents.diagnosticDetail2"
        name="diagnosticDetail"
        :data="displayedReportData['diagnosticDetail2']"
        areaId="#diagnostic-section-reports-detail"
      />
      <diagnostic-template
        v-if="showComponents.descriptionDiagnostic2"
        name="descriptionDiagnostic"
        :data="displayedReportData['descriptionDiagnostic2']"
        areaId="#diagnostic-section-reports"
      />
    </div>
    <div>
      <oil-pressure-template
        v-if="showComponents.oilPressure || showComponents.engineOilTemp"
        :name="form_desc.templateName"
        :data="displayedReportData[form_desc.templateName]"
      />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { OurAggregated } from '../../src/dataRequest/ourAggregated';
import { skillsManTemplate } from '../../Template/skills_man_template/skills_man_template';

import { skillsManTemplateHtml } from './SkillsMan/SkillsManTemplateHtml';

import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService';

const ourAggregated = new OurAggregated(skillsManTemplate);

import ShiftWorkSettings from './ShiftWork/ShiftWorkSettings.vue';

import SkillsManDetailSettings from './SkillsManDetail/SkillsManDetailSettings.vue';

import RegularMovingByGeoTemplateSetting from './RegularMoving/RegularMovingByGeoSetting.vue';

import OverspeedSettings from './Overspeed/OverspeedSettings.vue';

import CriticalViolations from './CriticalViolations/CriticalViolations.vue';

import XMLRudeViolations from './XMLRudeViolations/XMLRudeViolations.vue';

import EvacuatorMovingTemplate from './EvacuatorMoving/EvacuatorMovingTemplate.vue';

import clientsMonthly from './ClientsMonthly/ClientsMonthly.vue';

import ExcavatorOperationSettings from './ExcavatorOperation/ExcavatorOperationSettings.vue';
import ExcavatorOperation from './ExcavatorOperation/ExcavatorOperation.vue';

import OilPressureSettings from './OilPressure/OilPressureSettings.vue';
import OilPressureTemplate from './OilPressure/OilPressureTemplate.vue';

import DiagnosticSettings from './Diagnostic/DiagnosticSettings.vue';
import DiagnosticTemplate from './Diagnostic/DiagnosticTemplate.vue';

import { CanIndicatorsTemplate } from '../../Template/skills_man_template/can_indicators_template';
import { ClutchWorkTemplate } from '../../Template/skills_man_template/clutch_work_template';
import { CriticalViolationsTemplate } from '../../Template/skills_man_template/critical_violations_template';
import { FuelInOutTemplate } from '../../Template/fuel_in_out_template/fuel_in_out_template';

export default {
  props: {
    userRole: {
      type: String,
      default: '',
    },
  },
  setup() {
    const globalObjectsList = inject('globalObjectsList');

    return {
      globalObjectsList,
    };
  },
  components: {
    ShiftWorkSettings,
    // RegularMovingByGeoTemplate,
    RegularMovingByGeoTemplateSetting,
    OverspeedSettings,
    EvacuatorMovingTemplate,
    clientsMonthly,
    SkillsManDetailSettings,
    CriticalViolations,
    XMLRudeViolations,
    ExcavatorOperationSettings,
    ExcavatorOperation,
    DiagnosticSettings,
    DiagnosticTemplate,
    OilPressureSettings,
    OilPressureTemplate,
  },
  data() {
    return {
      violationsDescriptions: [],
      form_desc: {},
      displayedReportData: {},
      showComponents: {
        criticalViolations: false,
        rudeViolationsXML: false,
        skillsManDetailSettings: false,
        shiftWorkTemplateSettings: false,
        // regularMovingByGeoTemplate: false,
        regularMovingByGeoTemplateSetting: false,
        specMachineTemplateSettings: false,
        evacuatorMovingTemplate: false,
        clientsMonthlyTemplate: false,
        overspeedSettings: false,
        specMachine: false,
        diagnosticSettings: false,
        diagnostic: false,
        diagnosticDetail: false,
        descriptionDiagnostic: false,
        diagnostic2: false,
        diagnosticDetail2: false,
        descriptionDiagnostic2: false,
        oilPressureSettings: false,
      },
      triggers: {
        evacuatorMovingTrigger: false,
        clientsMonthlyTrigger: false,
        criticalViolationsTrigger: false,
        rudeViolationsXMLTrigger: false,
      },
      areaId: '#section-reports',
      buttonId: '',
    };
  },
  methods: {
    templateStart(event) {
      this.areaId = event.detail.data.areaId;
      this.buttonId = event.detail.data.buttonId;
      const formId = event.detail.data.formId;
      this.form_desc = event.detail.data
        ? {
            objects: event.detail.data.objects,
            beginTime: event.detail.data.beginTime * 1000,
            endTime: event.detail.data.endTime * 1000,
            timeOffset: event.detail.data.timeOffset,
          }
        : null;
      const area = document.getElementById(this.areaId);
      const button = document.getElementById(this.buttonId);
      const targetSpan = getNeighborByClass_helper(button, 'target-show-span');
      if (
        event.detail.name !== 'diagnostic' &&
        event.detail.name !== 'diagnosticDetail'
      ) {
        this.close('diagnostic');
      }
      if (area) area.innerHTML = '';
      if (!this.form_desc.objects.length && event.detail.name !== 'clientsMonthlyTemplate') {
        infoShowText_helper(targetSpan, 'Не выбран ни один объект.', this.areaId);
        setElementColor_helper(button, 'orange');
        return
      }

      // template start proxy
      switch (event.detail.name) {
        case 'skillsManTemplate':
          this.skillsManReport_render();
          break;
        case 'canIndicators':
          this.canIndicatorsReport_render();
          break;
        case 'clutchWork':
          this.clutchWorkReport_render();
          break;
        case 'criticalViolations':
          this.criticalViolationsReport_render();
          break;
        case 'rudeViolationsXML':
          this.rudeViolationsXMLReport_render();
          break;
        case 'skillsManDetail':
          this.violationsDescriptions = event.detail.violationsDescriptions;
          this.show('skillsManDetailSettings');
          document.getElementById('background-wrap').style.display = 'flex';
          break;
        case 'shiftWork':
          this.show('shiftWorkTemplateSettings');
          document.getElementById('background-wrap').style.display = 'flex';
          break;
        case 'clientsMonthlyTemplate':
          this.showComponents.clientsMonthlyTemplate = false;
          this.showComponents.clientsMonthlyTemplate = true;
          this.$nextTick(() => {
            this.triggers.clientsMonthlyTrigger =
              !this.triggers.clientsMonthlyTrigger;
          });
          break;
        case 'fuelInOut_consumption':
          this.fuelInOut_consumptionReport_render();
          break;
        case 'fuelInOut_cistern':
          this.fuelInOut_cisternReport_render();
          break;
        case 'evacuatorMoving':
          this.showComponents.evacuatorMovingTemplate = false;
          this.showComponents.evacuatorMovingTemplate = true;
          this.$nextTick(() => {
            this.triggers.evacuatorMovingTrigger =
              !this.triggers.evacuatorMovingTrigger;
          });
          break;
        case 'regularMovingByGeo':
          this.show('regularMovingByGeoTemplateSetting');
          break;
        case 'overspeed':
          objectsListFormColorAndTarget_helper(this.form_desc, this.buttonId);
          this.show('overspeedSettings');

          break;
        case 'specMachine':
          objectsListFormColorAndTarget_helper(this.form_desc, this.buttonId);
          this.show('specMachineTemplateSettings');

          this.close('specMachine');

          if (area) area.innerHTML = '';

          break;
        case 'diagnostic':
        case 'diagnosticDetail':
        case 'descriptionDiagnostic':
          objectsListFormColorAndTarget_helper(this.form_desc, this.buttonId);

          this.form_desc.templateName = event.detail.name;

          this.show('diagnosticSettings');
          break;
        case 'oilPressure':
          this.close('oilPressure');
          this.close('engineOilTemp');
          this.$nextTick().then(() => {
            this.show('oilPressureSettings');
            this.form_desc.templateName = event.detail.name;
          });
          break;
        case 'engineOilTemp':
          this.close('oilPressure');
          this.close('engineOilTemp');
          this.$nextTick().then(() => {
            this.show('oilPressureSettings');
            this.form_desc.templateName = event.detail.name;
          });
          break;
      }
    },
    close(name) {
      this.showComponents[name] = false;
    },
    show(name) {
      this.showComponents[name] = true;
    },
    displayReport(reportName, reportData) {
      let isDiagnostics = reportData?.areaId?.startsWith?.('diagnostic');
      if (isDiagnostics) {
        reportName += '2';
      }
      this.close(reportName);
      this.$nextTick().then(() => {
        this.show(reportName);
        this.displayedReportData[reportName] = reportData;
      });
    },

    skillsManReport_render() {
      fetchToTemplateService({
        templateName: 'skillsMan',
        templateHtml: skillsManTemplateHtml,
        options: { option: 0 },
        areaId: this.areaId,
        form_desc: this.form_desc,
        errorCallback: this.skillsManReport_action,
      });
    },
    skillsManReport_action() {
      ourAggregated.callOurAggregated({
        smenasSeconds: [],
        option: 0,
        templateName: 'skillsMan',
        templateHtml: skillsManTemplateHtml,
        form_desc: this.form_desc,
        callback: false,
        roundTo: 10,
        areaId: this.areaId,
      });
    },
    canIndicatorsReport_render() {
      const canIndicatorsTemplate = new CanIndicatorsTemplate(
        OurAggregated,
        skillsManTemplate,
        this.form_desc,
      );
      // canIndicatorsTemplate.callOurAggregated();
      canIndicatorsTemplate.showSettings();
    },
    clutchWorkReport_render() {
      const clutchWorkTemplate = new ClutchWorkTemplate(
        OurAggregated,
        skillsManTemplate,
        this.form_desc,
      );
      // clutchWorkTemplate.callOurAggregated();
      clutchWorkTemplate.showSettings();
    },
    rudeViolationsXMLReport_render() {
      this.showComponents.rudeViolationsXML = false;
      this.showComponents.rudeViolationsXML = true;
      this.$nextTick(() => {
        this.triggers.rudeViolationsXMLTrigger =
          !this.triggers.rudeViolationsXMLTrigger;
      });
    },
    criticalViolationsReport_render() {
      this.showComponents.criticalViolations = false;
      this.showComponents.criticalViolations = true;
      this.$nextTick(() => {
        this.triggers.criticalViolationsTrigger =
          !this.triggers.criticalViolationsTrigger;
      });
      // const criticalViolations = new CriticalViolationsTemplate(
      //   OurAggregated,
      //   skillsManTemplate,
      // );
      // criticalViolations.execute();
    },
    fuelInOut_consumptionReport_render() {
      // запуск отчета реализован в fuel_in_out_template.js
      const fuelInOutConsumptionTemplate = new FuelInOutTemplate(
        'fuelInOut_consumption',
        ourAggregated,
        {
          buttonId: this.buttonId,
          areaId: this.areaId,
          form_desc: this.form_desc,
        },
        this.globalObjectsList,
      );
      fuelInOutConsumptionTemplate.submit();
    },
    fuelInOut_cisternReport_render() {
      // запуск отчета реализован в fuel_in_out_template.js
      const fuelInOutCisternTemplate = new FuelInOutTemplate(
        'fuelInOut_cistern',
        ourAggregated,
        {
          buttonId: this.buttonId,
          areaId: this.areaId,
          form_desc: this.form_desc,
        },
        this.globalObjectsList,
      );
      fuelInOutCisternTemplate.submit();
    },
  },
  mounted() {
    const showTemplateButton = document.getElementById('app');
    showTemplateButton.addEventListener(
      'get-template-button-event',
      this.templateStart,
    );
  },

  //   showTemplateButton.addEventListener(
  //   "regular-moving-template-show",
  //   this.showTemplateButtonEvent
  // );
};
</script>
